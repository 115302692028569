<template>
  <div class="row">
    <div class="col-md-2">
      <div class="user_img">
        <img v-if="user.profile_picture" :src="IMAGE_URL + user.profile_picture.key" alt="pic" />
      </div>
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">First Name</div>
          <div class="col-md-8">{{ user.first_name }} </div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Last Name</div>
          <div class="col-md-8"> {{ user.last_name }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Email</div>
          <div class="col-md-8">{{ user.email }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Company</div>
          <div class="col-md-8">{{ user.company_name }}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Job Title</div>
          <div class="col-md-8">{{ user.job_title }}</div>
        </div>
      </div>


      <!--<div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">User Type</div>
          <div class="col-md-8" v-for="event in user.events" :key="event.event_id">
            <p v-if="event.event_id == this.$route.params.event_id"> 
                {{event.role}}
             </p>
            
          </div>
        </div>
      </div>-->


    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Phone</div>
          <div class="col-md-8">+({{ user.country_code }}) {{ user.Phone }}</div>
        </div>
      </div>


      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Status</div>
          <div class="col-md-8">{{ user.status?"Active":"Inactive" }}</div>
        </div>
      </div>


      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Personal Profile</div>
          <div class="col-md-8">{{ user.biography }}</div>
        </div>
      </div>



    </div>
    <div class=" text-right mt-3 mr-5">

      <button type="button" v-if="can('event_user_edit',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(user)">Edit</button>
      
      <span v-if="can('event_user_edit',checkPermssions)">
      <button type="button"  class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="unSubscribe(user)"
        v-if="checkIsSubscribed(user) == false">Unsubscribe from event</button>
      <button type="button"  class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="subscribe(user)" v-else>Subscribe for
        event</button>
      </span>
     
    </div>
  </div>
</template>

<script>
import { isPermits} from "@/services/CheckPermissions";
import { mapActions } from 'vuex'
import constants from "@/config/constants";
import _ from 'lodash';
export default {
  name: "ShowItem",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  props: {
    user: {
      type: Object,
      default() {
        return {
          name: null,
          email: null,
          company: null,
          role: null,
        }
      }
    },
    checkPermssions:null
  },
  methods: {
    ...mapActions('EventUsersIndex', ['fetchData', 'destroyData', 'restoreData']),
    ...mapActions('EventUsersSingle', ['setItem', 'setIsNew', 'setIsEditing']),
    unSubscribe(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You can be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "UnSubscribe",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            event_id: this.$route.params.event_id,
            user_id: item._id
          }
          this.destroyData(data).then(() => {

            this.$flashMessage.show({
              group : 'event_users',
              type  : 'success',
              title : 'Success',
              time  : 1000,
              html  : '<ul><li>Event user un-subscribe successfully.</li></ul>'
            });

            this.fetchData(this.$route.params.event_id);
            this.setIsNew(false);
            this.setIsEditing(false);
          })
        }
      });
    },
    subscribe(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You can be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Subscribe",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          const data = {
            event_id: this.$route.params.event_id,
            user_id: item._id
          }
          this.restoreData(data).then(() => {

            this.$flashMessage.show({
              group : 'event_users',
              type  : 'success',
              title : 'Success',
              time  : 1000,
              html  : '<ul><li>Event user subscribe successfully.</li></ul>'
            });

            this.fetchData(this.$route.params.event_id);
            this.setIsNew(false);
            this.setIsEditing(false);
          })
        }
      });
    },
    editData(item) {
      this.setItem(item);
      this.setIsNew(false);
      this.setIsEditing(true);
      this.$forceUpdate();
    },
    checkIsSubscribed(item) {
      let eventInfo = _.findIndex(item.events, { 'event_id': this.$route.params.event_id, 'is_deleted_event_organizer': true });
      if (eventInfo == -1) {
        return false
      }
      return true;
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  },

}
</script>

<style scoped lang="scss">
.user_img {
  img {
    width: 100%;
  }
}

.event_detail_wrapper {
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }

  .row {
    .col-md-8 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }

    .col-md-3 {
      border-bottom: 1px dotted #ccc;
      padding-bottom: 5px;
    }
  }

  .card-body {
    box-shadow: 0px 0px #ffffff;
  }
}
</style>