
          <template>
            <div>
                  <div class="tabs">
                    <ul class="nav nav-tabs nav-justified">
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-target="#edit-booths" href="#edit-booths" data-bs-toggle="tab">Edit Booth</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-bs-target="#booth_data" href="#booth_data" data-bs-toggle="tab">Add Booth Content</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" :data-bs-target="'#survey-'+changingVariable" :href="`#changingVariable`"  data-bs-toggle="tab" @click="reRenderComponent()">Booth Survey</a>
     
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                      <div id="edit-booths" class="tab-pane active">
                        <form @submit.prevent="submitForm" novalidate ref="form">
                          <OverlayLoading :isLoading="loading" />
                          <div class="row">
                            <div class="col-md-10 event_detail_ls">
                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="company_name">Booth title *</label>
                                  </div>
                                  <div class="col-md-10">
                                    <input type="text" class="form-control" name="title" placeholder="Enter Booth Title *"
                                      :value="item.title" @input="updateTitle" />
                                  </div>
                                </div>
                              </div>
                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="company_email">Exhibitor *</label>
                                  </div>
                                  <div class="col-md-10">
                                    <select name = "exhibitor_id" class="form-control" @change="updateExhibitor" placeholder="Select Exhibitor">
                                        <option disabled selected>Select Exhibitor</option>
                                        <option v-for="option in exhibitor" :value="option.id" :key="option.id"
                                            :selected="(item.exhibitor_id == option.id)">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                  
                                  <!-- <v-select name = "exhibitor_id" :clearable="true" class="form-control" placeholder="Select Exhibitor" label="name" :options="exhibitor" track-by="id"  @option:selected="updateExhibitor" />-->
                                  </div>
                                </div>
                              </div>
                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="contactus_email">Booth Representative </label>
                                  </div>
                                  <div class="col-md-10">
                                    <select name = "booth_representative_id" class="form-control" @change="updateBoothRepresentative" placeholder="Select Booth Representative">
                                        <option  value ="" selected>Select Booth Representative</option>
                                        <option v-for="option in boothRepresentative" :value="option.id" :key="option.id"
                                            :selected="(item.booth_representative_id == option.id)">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                    
                                  <!-- <v-select class="form-control" :options="boothRepresentative" :reduce="name => name.title" label="name" 
                                      @option:selected="updateBoothRepresentative"/>-->
                                  </div>
                                </div>
                              </div>

                              <div class="event_detail_wrapper">
                                    <div class="row">
                                      <div class="col-md-2">
                                        <label for="address">Booth Representative  Picture</label>
                                    </div>
                                  <div class="col-md-10">
                                        <DropFiles :file="item.booth_representative_picture"
                                                  :module_id="this.moduleId"
                                                  @onUploaded="updateBoothRepresentativePicture"
                                                  @onRemoved="removeBoothRepresentativePicture"
                                                  @onError="errCb"
                                                  :accept="'.png'"
                                                  :module_type="'events'"
                                                  :asset_type="'booth'">
                                        <template #header>
                                          <p> Booth Representative Picture(Png in format allowed)</p>
                                        </template>
                                      </DropFiles>
                                      </div>
                                    </div>
                              </div>

                            
                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="address">Booth Locations *</label>
                                  </div>
                                  <div class="col-md-10">
                                    <!--<v-select :clearable="true" class="form-control" placeholder="Select Booth Location" label="name" :options="boothLocation" track-by="id"  @option:selected="updateLocation" />-->
                                    <select name = "location" class="form-control" @change="updateLocation" placeholder="Select Booth Location">
                                        <option  disabled selected>Select Booth Location</option>
                                        <option v-for="option in boothLocation" :value="option.id" :key="option.id"
                                            :selected="(item.location == option.id)">
                                            {{ option.name }}
                                        </option>
                                    </select>
                            
                                  </div>
                                </div>
                              </div>
                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="address">Booth Color *</label>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="input-group color" data-plugin-colorpicker>
                                      <input type="color" class="form-control" name="booth_color" :value="item.booth_color" @input="updateBoothColor" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="address">Booth Icon Color</label>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="input-group color" data-plugin-colorpicker>
                                      <input type="color" class="form-control" name="booth_icon_color" :value="item.booth_icon_color" @input="updateBoothIconColor" />
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="address">Enable Rating</label>
                                  </div>
                                  <div class="col-md-10">
                                    <div class="switch switch-sm switch-primary">
                                    <input-switch name="is_rating" :value="item.is_rating" id="customSwitchesEx" class="custom-control-input" @change="updateIsRating" />
                                  </div>                                        
                                  

                                  </div>
                                </div>
                              </div>
                              <div class="event_detail_wrapper">
                                <div class="row">
                                  <div class="col-md-2">
                                    <label for="address">Is Booth Enable</label>
                                  </div>
                                  <div class="col-md-10">
                                    <div class="switch switch-sm switch-primary">
                                    <input-switch name="is_booth_enable" :value="item.is_booth_enable" id="customSwitchesEx" class="custom-control-input" @change="updateIsEnable" />
                                  </div>                                        
                                  

                                  </div>
                                </div>
                              </div>


                              <div class="event_detail_wrapper">
                                <div class="form-group form-inline">
                                  <div class="col-md-2">
                                    <label for="address">Choose a Model *</label>
                                  </div>
                                  <div class="form-inline">
                                    <div class="radio" >
                                          <div>
                                            <label>
                                              <input
                                                  type="radio"
                                                  name="preset_id"
                                                  :value="item.preset_id"
                                                  checked=true
                                                  @change="updatePreset(preset.id)"
                                              >
                                              <img v-if="item.presets" class="preset_images" disabled width="200" :src="IMAGE_URL + item.presets.preset_image.key"/>
                                            </label>
                                          </div>
                                        </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="offset-md-10 col-md-2">
                                <div class="setting_wrapper">
                                  <button type="submit" class="btn btn-primary">Save</button>
                                 &nbsp; <button type="button" class="mb-1 mt-1 me-1 btn btn-primary btn-secondary" @click="cancelClick">Cancel</button>
                      
                                </div>
                              </div>
                            </div>


                          </div>
                          <FlashMessage group="booth_edit" />
                        </form>
                      </div>

                       <div id="booth_data" class="tab-pane">
                         <BoothDataCreateForm :booth_id="booth_id"></BoothDataCreateForm>
                        </div>

                        <div    :id ="'survey-' +changingVariable" class="tab-pane">
                          <surveyIndex :agenda_id="this.booth_id" surveyNameFile="BoothSurvey" ></surveyIndex>
                        </div>

                  </div>
            </div>         
          </template>
<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import { mapGetters, mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import _ from "lodash";
import { upload, remove } from "@/services/FileUploader";
import ObjectID from 'bson-objectid';
import constants from "@/config/constants";
import InputSwitch from "@/components/Commons/InputSwitch";
import BoothDataCreateForm from "@/components/cruds/Booth/BoothData/Create";
import surveyIndex from "@/components/cruds/Survey/Index";



export default {
  name: 'BoothEditForm',
  components: {
    InputSwitch,
    BoothDataCreateForm,
    surveyIndex

   
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
      return {
        changingVariable: 0,
         
        moduleId      : ObjectID().toHexString(),
        exhibitorsList: [],
        IMAGE_URL     : constants.IMAGE_URL,
        selected      : null,

        questionList: true,
        setIsSurveyEdit: false,
   
      }
    },
    watch: {
       
  }
  ,props: {
    booth_id: String
  },
  validations() {
    return {
      item: {
        title                   : {required},
        exhibitor_id            : {required},
        location                : {required},
        preset_id               : {required},
      }
    };
  },
  mounted() {
    //this.fetchsurveydata(this.$route.params.event_id); //questions data   

    this.updateEventId();
    this.fetchData(this.booth_id);
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.booth_edit;
  },
  computed: {

    ...mapGetters('SurveysIndex', ['surveyitems', 'newSurvey']),
        ...mapGetters('SurveysSingle', [
            'sitems',
            'surveytemp',
            'is_survey_editing',
            'is_new_question',
            'datanum',
        ]),


    ...mapGetters('BoothsSingle', ['item', 'loading', 'is_new']),
    ...mapGetters('BoothsIndex', ['exhibitor','boothRepresentative','boothPresets','boothLocation']),
  },

  methods: {

    ...mapActions('SurveysIndex', {
            fetchsurveydata: "fetchSurveyData"
        }), //questions data

        ...mapActions('SurveysIndex', ["setIs_open", "setSurveytitle", "updateSurveytitleData", "storeSurveyData", "destroySurveyData", "setNewSurvey"]),

        ...mapActions('SurveysSingle', [
            "setItem",
            "destroyData",
            "setIsSubEditing",
            "setIsNewQuestion",
            "setSurveytemp",
            "setDataNum",

        ]),




    ...mapActions('BoothsIndex', {setEditId: "setEditId",fetchAllData :'fetchData'}),
    ...mapActions('BoothsSingle', [
      'fetchData',
      'setItem',
      'storeData',
      'updateData',
      'resetState',
      'setIsNew',
      'setEventId',
      'setTitle',
      'setExhibitorId',
      'setBoothRepresentativeId',
      'setBoothColor',
      'setBoothIconColor',
      'setIsRating',
      'setIsBoothEnable',
      'setPresetId',
      'setLocation',
      'setBoothRepresentativePicture'
    ]),
    reRenderComponent() {
      this.changingVariable += 1
    },
    updateEventId(){
      this.setEventId(this.$route.params.event_id);
    },
    updateTitle(e) {
      this.setTitle(e.target.value);
    }, 
    updateExhibitor(e){
      this.setExhibitorId(e.target.value)
    },
    updateBoothRepresentative(e) {
      if(e.target.value){
        this.setBoothRepresentativeId(e.target.value);  
      }else{
        this.setBoothRepresentativeId(null);
      }
    },
    updateLocation(e){
      this.setLocation(e.target.value);
    },
    updateBoothColor(e){
      this.setBoothColor(e.target.value);
    },
    updateBoothIconColor(e){
      this.setBoothIconColor(e.target.value);
    },
    updateIsRating(e){
      this.setIsRating(e.target.checked);
    },
    updateIsEnable(e){
      this.setIsBoothEnable(e.target.checked);
    },
    updatePreset(e){
      this.setPresetId(e)
    },
    removeBoothRepresentativePicture(){
      this.setBoothRepresentativePicture(null);
    },
    async updateBoothRepresentativePicture(info, data) {
    if (!_.has(data.data, 'key')) {
              return;
          }
          const extension = data.data.key.split('.').pop();
          const key = _.get(this.item.booth_representative_picture, 'key', null);
          const size = _.get(info, 'file.size', 0);
          this.setBoothRepresentativePicture({
              old_key   : key,
              key       : data.data.key,
              temp_key  : info.id,
              size      : size.toString(),
              extension : extension,
              uploadedBy: info.user.first_name + ' ' + info.user.last_name,
              uploadedAt: '2026.01.01',
          });      
  },
  errCb(e) {
        this.$flashMessage.show({
            group : 'booth_edit',
            type  : 'error',
            title : 'Failed',
            html  : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
          });
      },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate()
      console.log(isFormCorrect,this.v$.item.$errors);
      if(this.v$.item.$errors.length>0){
        let clientHtml = "<ul>";
        for(let error of this.v$.item.$errors){
            clientHtml+="<li> The "+ error.$property +" - "+ error.$message+"</li>";
        } 
        clientHtml+="</ul>";
        if(this.v$.item.$errors){
            this.$flashMessage.show({group: 'booth_edit',type: 'error',title: 'Form Validations Errors',html: clientHtml});
        }
        return false;
      }
      this.updateData(this.booth_id)
          .then(async(res) => {
            this.$flashMessage.show({
              group : 'booths',
              type  : 'success',
              title : 'Success',
              time  : 1000,
              html  : "<ul><li>Event Booth updated successfully</li></ul>",
            });
            this.resetState();
            this.setEditId(null);
            this.fetchAllData(this.$route.params.event_id);
          })
        .catch((error) => {
          console.log("Errro",error);
          let html =  gettingErrorMessage(error);
          this.$flashMessage.show({
                group : 'booth_edit',
                type  : 'error',
                title : 'Error',
                time  : 1200,
                text  : html,
                html  : html
            });
        });
    },
    cancelClick() {
      this.resetState()
      this.setEditId(null);
    },


    updateIs_open(e) {
            console.log('777777777777777777777', e.target.checked);
            this.setIs_open(e.target.checked);
        },

        updatesurvey_title(e) {
            this.setSurveytitle(e.target.value);
        },

        async submitSurveyTitleForm() {
            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) {

                _.map(this.v$.$errors, (item) => {
                    if (item.$property.indexOf(['title'])) {
                        this.error_general_tab = true;
                    }
                });

            }
            this.storeSurveyData(this.$route.params.event_id)
                .then(async (resp) => {
                    this.$flashMessage
                        .show({
                            group: 'booth_edit',
                            type: 'success',
                            title: 'Success',
                            text: 'Survey created successfully.',
                        });

                    this.setIsSurveyEdit = false
                    this.setNewSurvey(false)
                    await this.fetchsurveydata(this.$route.params.event_id);
                    //await this.fetchAllData(resp.data.data.event_id);
                }).catch(error => {
                    let html = gettingErrorMessage(error);
                    if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'booth_edit',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }

                });
        },

        createQuestionClick() {
            this.setIsNewQuestion(true);
            this.setSurveytemp(false);
            this.questionList = false;
        },

        editSurvey(item, survey) {
            // console.log(survey,'333333333'); 
            this.edit_id = item;
            this.setIsSubEditing(true);
            this.questionList = false;
            this.updateSItems(survey);

        },
        showSurveyEditform() {
            this.setIsSurveyEdit = true;
        },

        updateSItems(items) {
            this.setItem(items);

        },

        async udpateSurveyTitleForm(survey_id) {

            const isFormCorrect = await this.v$.$validate();

            if (!isFormCorrect) {

                _.map(this.v$.$errors, (item) => {
                    if (item.$property.indexOf(['title'])) {
                        // this.error_general_tab = true;
                    }
                });

            }

            let multidatasur = {
                module_id: this.$route.params.event_id,
                survey_id: survey_id
            };
            this.updateSurveytitleData(multidatasur)
                .then(async () => {
                    this.$flashMessage
                        .show({
                            group: 'booth_edit',
                            type: 'success',
                            title: 'Success',
                            text: 'Survey updated successfully.',
                        });

                    this.setIsSurveyEdit = false
                    await this.fetchsurveydata(this.$route.params.event_id);
                    //await this.fetchAllData(resp.data.data.event_id);
                }).catch(error => {
                    let html = gettingErrorMessage(error);
                    if (error.response.data.data.error) {
                        this.$flashMessage.show({
                            group: 'booth_edit',
                            type: 'error',
                            title: 'Failed',
                            text: html || 'Something went wrong',
                            html: html
                        });
                    }
                    this.setIsSurveyEdit = false

                });
        },

        deleteSurvey(item) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.isConfirmed) {
                    this.destroySurveyData(item).then(() => {

                        this.$flashMessage.show({
                            group: 'booth_edit',
                            type: 'success',
                            title: 'Success',
                            text: 'Survey has been deleted successfully.'
                        });
                        this.fetchsurveydata(this.$route.params.event_id);
                        this.setIsSurveyEdit = false
                        this.setNewSurvey(true);

                        // this.fetchData(this.$route.params.event_id);
                        // this.setIsNew(false);
                        // this.setIsEditing(false);

                    })
                }
            });
        },
  }
}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
}

.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}


.preset_images
{
    opacity:0.5;
}
.radio{
    padding:5px;
}
label > input{ /* HIDE RADIO */
  display:none;
}
label > input + img{ /* IMAGE STYLES */
  cursor:pointer;
  border: 1px dashed #444;
  width: 150px;
  height: 150px;
  transition: 500ms all;
}
label > input:checked + img{ /* (CHECKED) IMAGE STYLES */
  /* border:2px solid #f00; */
  opacity:1;
  border: 1px solid #fff;
  box-shadow: 0 0 3px 3px #090;
  
}
.form-inline{
  display:flex;
}

.surveytitlehd {
    background: #0088CC;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
}

.surveytitlehd label {
    color: #fff !important;
    font-weight: bold
}

.surveytitlehd span {
    margin-left: 20px;
}

.question_wrapper {
    background: #efefef;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.question {
    font-weight: bold;
    margin-bottom: 10px;
}

.question_action {
    position: absolute;
    top: 50%;
    right: 50px;
}

.question_edit {
    background: #1896e3;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 5px;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;
}

.question_delete {
    background: #fff;
    color: #5a5757;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;

}

.newbtn {
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 5px;
}
</style>
