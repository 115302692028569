<template>
       
    <div class="snippet general-event-config-snippet">
        <div class="form-group row">

        <div class="col-lg-6">
            <label>Module Type *</label>
            <v-select name="module_type"  placeholder="Select Module Type" label="label" @option:selected="updateModuleType" :options="moduleEnum" :modelValue="item.module_type"/>
            
        </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-6">
                <label>Language *</label>
                <v-select required :rules="requiredRule" name="language" class="" placeholder="Select language"
                            label="language" @option:selected="updateLaguage" :options="languages"
                            :modelValue="selectedLanguageKey" />
                        
                  
       </div>
            <div class="col-lg-6">
                <label>Title *</label>
                <div class="kt-input-icon">
                    <input type="text" class="form-control" name="language_title" maxlength="200" placeholder="Enter language title" :value="this.selectedLanguageTitle" @input="updateLanguageTitle" />
                          
                </div>
            </div>
            <div class="col-lg-6">
                <label>Content</label>
                
                <div class="kt-input-icon">
                    <ckeditor :editor="editor" 
                    :id="'content'+this.languageKey"                                            
                    rows="5" 
                    maxlength="3000"   
                    :model-value="this.selectedLanguageDescirption"
                           
                    placeholder="Enter language description" 
                    :config="editorConfig"
                    @input="updateLanguageDescription"
                   style="min-height:500px;"></ckeditor>
                </div>
              
           
            </div>

        </div>
    </div>
<div class="card-body text-right mt-3 mr-5">
    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>

</div>


<FlashMessage group="page_section_edit"/>

</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import {
mapGetters,
mapActions
} from 'vuex';
import _ from "lodash";
import moment from 'moment';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
useVuelidate
} from '@vuelidate/core'
import {
required,
email,
} from '@vuelidate/validators'
import ObjectID from 'bson-objectid';
import { UploadAdapter } from "@/services/CKUploader";
import CKEditor from '@ckeditor/ckeditor5-vue';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {

data() {
return {
    editor      : ClassicEditor,
    editorData  : '',
    editorConfig: {
    
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' ],
        table: {
            toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
       // extraPlugins: [this.uploader],
       extraPlugins:[function(editor){
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader ) 
                }
            }
        ],
       //extraPlugins:[ (editor)=>{editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
         //   return new UploadAdapter( loader );
        //} }],
        language: 'en',
    },
    moduleId        : ObjectID(),
    dialog          : false,
    translation     : [],
    languageKey     : '',
    languageTitle   : '',
    languageDescription: '',
    error_translation_tab: false,
    error_general_tab: false,
    // selectedLanguageDescirption:'',
    // selectedLanguage: null,
}

},

setup() {
    return {
        v$: useVuelidate()
    }
},
validations() {
return {
    item: {
        translations: {
            required
        },
        module_type:{
            required
        }
    }
};
},

props: {
event: {
    type: Object,
    default () {
        return {
            name: null,
            email: null,
            company: null,
            role: null,
            langauge:null
        }
    }
},
languages: {type: Array},
docId:null
},
watch: {

},
name: 'DocSectionCreateForm',
components: {
    ckeditor: CKEditor.component,
    //ValidationMessages
},

computed: {
    ...mapGetters('SectioncontentsSingle', ['error_translation_tab','error_general_tab','moduleEnum','selectedLanguageKey','selectedLanguageTitle','selectedLanguageDescirption','item', 'loading',]),
    ...mapGetters('SectioncontentsIndex', []),
},
beforeUnmount() {
    this.resetState()
    delete this.$flashMessage.groups.page_section_edit;
},
created(){
},
mounted() {
},
methods: {
...mapActions('SectioncontentsIndex', {
    fetchAllData: 'fetchData',
}),
...mapActions('SectioncontentsSingle', [
    'resetState',
    'storeData',
    'updateData',
    'setSectionColor',
    'setModuleType',
    'setErrorTranslationTab',
    'setErrorGeneralTab',
    'setTranslations',
    'setLangTitle',
    'setLangKey',
    'setLangDescirption'
]),
updateSectionColor(e) {
    this.setSectionColor(e.target.value);
},
updateModuleType(value) {  
    this.setModuleType(value.value)
},
    updateLaguage(value) {
        console.log("this.item.translations",this.item.translations);
        console.log("VAlue***",value);
        this.err_translations = null;
        this.setLangKey(value);
        this.languageKey = value;
        this.setLangTitle(null);
        this.setLangDescirption(null);

        if (_.isUndefined(_.find(this.item.translations, {
        'key': value,
        }))) {
        let obj = {
            "key": `${value}`,
            "title": null,
            "description": null
        }
        this.item.translations.push(obj);
        } else {

        let selectedLang = _.find(this.item.translations, {
            'key': this.selectedLanguageKey,
        })
        if (!_.isUndefined(selectedLang)) {
            this.setLangTitle(selectedLang.title);
            this.setLangDescirption(selectedLang.description);
        }
        }

    },
    updateLanguageTitle(e) {
        this.err_translations = null;
        if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
        this.setTranslations({
            key: this.selectedLanguageKey,
            update_key: 'title',
            update_value: e.target.value
        });

        }
        this.setLangTitle(e.target.value);
    },
    updateLanguageDescription(e) {
        if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
        this.setTranslations({
            key: this.selectedLanguageKey,
            update_key: 'description',
            update_value: e
        });
    }
    this.setLangDescirption(e);
},

async checkLanguageValidation() {
    let errorsArr = [];
    let langArr = [];
    langArr.push(this.languages[0]);
    console.log("langArr",langArr);
    console.log("TEEE",this.languages);
_.map(langArr, (item) => {
    let langInfo = _.find(this.item.translations, { 'key': item });
    if (langInfo) {
        if(langInfo.title == null){
            errorsArr.push(`<li>${item}.title can not be empty.</li>`)
        }
        if(langInfo.description == null){
           //errorsArr.push(`<li>${item}.description can not be empty.</li>`)
        }
        if (langInfo.title!==null && langInfo.title.length < 3) {
            errorsArr.push(`<li>${item}.title atleast 3 chracter long.</li>`)
        }
        if (langInfo.description!==null && langInfo.description.length < 10) {
            //errorsArr.push(`<li>${item}.description atleast 10 chracter long.</li>`)
        }

    } else {
        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
        //errorsArr.push(`<li>${item}.description can not be empty.</li>`)
    }

});
return errorsArr;
},
cancelClick() {
this.resetState()
},
async  submitForm() {
    const isFormCorrect = await this.v$.$validate();
    let validateTranlation = await this.checkLanguageValidation();

        _.map(this.v$.$errors, (item) => {
            if(item.$property == "module_type"){
                validateTranlation.push("<li>Module Type is required</li>");
            }
        });
    
    if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {

        if (!_.isEmpty(validateTranlation)) {
            this.$flashMessage.show({
                group   : 'page_section_edit',
                type    : 'error',
                time    : 1000,
                title   : 'Failed',
                html    : `<ul>${validateTranlation.join("")}</ul>`
            });
            return ;
        }          
    } 
    

    this.updateData(this.docId)
        .then(async (resp) => {
            this.$flashMessage
                .show({
                        group   : 'page_content_index',
                        type    : 'success',
                        title   : 'Success',
                        time    : 1000,
                        html    : '<ul><li>Page Content Section updated successfully.</li></ul>',
                    });
            await  this.fetchAllData(this.docId);
        }) 
        .catch(error => {
            let html =  gettingErrorMessage(error);
            if (error.response.data.data.error) {
                this.$flashMessage.show({
                    group: 'page_section_edit',
                    type: 'error',
                    title: 'Failed',
                    time:1000,
                    text: html || 'Something went wrong',
                    html: html
                });
            }
        });
},





},


}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
border-bottom: 0px solid #ccc;
padding: 10px 0px;

.event_ls_label {
font-weight: bold;
}
}

.setting_wrapper {
float: right;

a {
color: #201e1eba;
padding: 9px;
margin-left: 5px;
background: #c0cdd345;
border-radius: 4px;
border: 1px solid #80808054;
}
}

.toggle label {
color: #000 !important;
}

.drop-files {
margin-top: 10px;
}

.newspeaker {
margin-top: 20px;
}

.newspeaker button {
width: 100%;
}

.add_spk_btn {
background-color: #0088CC;
color: #fff;
}

.mini_txt {
font-size: 0.8em;
color: #585252;
font-style: italic;
margin-bottom: 10px;
}

.v-dialog>.v-overlay__content>.v-card {
border-radius: 15px;
padding-top: 20px;
}

.tabs-left .nav-tabs>li .active {
border-left-color: #0088CC;
}

.orangebtn {
background-color: orange;
}

.wrapp {
display: block;
text-align: center;
margin-bottom: 10px;
}
.flash-content {
z-index: 10;
}

.dark{
--popper-theme-text-color: #ffffff;
--popper-theme-padding: 15px;
--popper-theme-background-color: #333333;
--popper-theme-border-radius: 6px;
--popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img{    
position: absolute;
top: -53px;
left: 50px;
width: 300px;
height: auto;
}
.ck-editor .ck-editor__main .ck-content {
min-height: 500px!important;
}
.ck .ck-content .ck-editor__editable .ck-rounded-corners .ck-editor__editable_inline .ck-blurred{
min-height: 500px !important;
}

</style>
<style scoped> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */
.ck-editor__editable {
min-height: 5000px;
}
</style>
