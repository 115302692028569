<template>
  <form @submit.prevent="" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div>
      <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item">
            <a class="nav-link active" data-bs-target="#language-settings" href="#language-settings" data-bs-toggle="tab">Language</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-target="#general-settings" href="#general-settings" data-bs-toggle="tab">General Settings</a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div id="language-settings" class="tab-pane active">
          <div class="snippet general-event-config-snippet">
            <div class="form-group row">
              <div class="col-lg-6">
                <label>Language *</label>
                <v-select name="language" class="" placeholder="Select language" label="language" @option:selected="updateLaguage" :options="languages" />
              </div>
              <div class="col-lg-6">
                <label>Name *</label>
                <div class="kt-input-icon">
                  <input type="text" class="form-control" name="language_title" maxlength="200" placeholder="Enter language title" :value="getLanguageName()" @input="updateName" v-on:keydown.enter.prevent />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <label>Description *</label>
                  <div class="kt-input-icon">
                  <!--  <textarea rows="3" class="form-control" name="language_description" placeholder="Enter language description" :value="getLanguageDescription()" @input="updateDescription"></textarea>
                -->  


              <ckeditor :editor="editor" 
                                id="content"                                            
                                rows="5" 
                                maxlength="3000"   
                                :model-value="getLanguageDescription()"
                                placeholder="Enter Text" 
                                @input="updateDescription"
                               style="min-height:500px;"></ckeditor>
                </div>
                </div>
              </div>
















            </div>
          </div>
        </div>
        <div id="general-settings" class="tab-pane">
          <DropFiles
              :file="validate_file(exhibitor.logo)"
              :module_id="this.moduleId"
              dimmention="512X512"
              @onUploaded="updateLogo"
              @onRemoved="removeLogo"
              @onError="logoErrCb"
              :accept="'.jpg,.jpeg,.png'"
              :module_type="'events'"
              :asset_type="'event_exhibitor_create'">
            <template #header>
              <label>Logo * (Size should be 512x512 and jpg or png in format allowed)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="offset-md-10 col-md-2">
            <div class="setting_wrapper mt-2">
              <button type="button" class="mb-1 mt-1 me-1  btn btn-primary" @click="submitForm">Save</button>
              <button type="button" class="mb-1 mt-1 me-1 btn btn-secondary" @click="cancelClick">Cancel</button>
   


            </div>
          </div>
        </div>
      </div>
      <FlashMessage group="exhibitor_create"/>
    </div>
  </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
 
export default {
  name: "EventExhibitorsCreate",
  data() {
    return {
      moduleId: ObjectID(),
      selectedLanguage: null,
      languages: [],
      editor      : ClassicEditor,
      editorData  : '',
               
    }
  },
  components: {
            ckeditor: CKEditor.component,
            
        },
  computed: {
    ...mapGetters("EventsSingle", {event: "item"}),
    ...mapGetters("ExhibitorsSingle", {exhibitor: "item", loading: "loading"})
  },
  watch: {
    'event.languages': function (items) {
      this.languages = items;
    },
  },
  mounted() {
    this.fetchEvent(this.$route.params.event_id)
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.exhibitor_create;
  },
  methods: {
    ...mapActions('EventsSingle', {
      fetchEvent: 'fetchData',
    }),
    ...mapActions('ExhibitorsSingle', {
      setTranslation: "setTranslation",
      setLogo: "setLogo",
      storeData: "storeData",
      setIsNew: "setIsNew",
      resetState:"resetState"
    }),
    ...mapActions('ExhibitorsIndex', {
      fetchAllExhibitors: "fetchData",
    }),
    updateLaguage(e) {
      this.selectedLanguage = e.language;
    },
    updateName(e) {
      if (!_.isNull(this.selectedLanguage)) {
        this.setTranslation({
          key: this.selectedLanguage,
          update_key: 'name',
          update_value: e.target.value
        });
      }
    },
    updateDescription(e) {
      if (!_.isNull(this.selectedLanguage)) {
        this.setTranslation({
          key: this.selectedLanguage,
          update_key: 'description',
          update_value: e
        });
      }
    },
    updateLogo(info, data) {
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.exhibitor.logo, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeLogo(e) {
      this.setLogo(null);
    },
    logoErrCb(e) {
      console.log(e)
      this.$flashMessage.show({
              group : 'exhibitor_create',
              type  : 'error',
              title : 'Failed',
              html  : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
              
            })
    },
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    getLanguageName() {
      const translate = _.find(this.exhibitor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'name', '');
    },
    getLanguageDescription() {
      const translate = _.find(this.exhibitor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'description', '');
    },
    submitForm(e) {
      e.preventDefault();
      this.storeData(this.$route.params.event_id)
          .then(async(response) => {
            this.$flashMessage.show({
              group : 'event_exhibitors',
              type  : 'success',
              title : 'Success',
              time  : 1000,
              html  : "<ul><li>Event Exhbitor created successfully</li></ul>",
            });

            await  this.fetchAllExhibitors(this.$route.params.event_id);
            //await this.$router.push({name: 'exhibitors.index', params: {event_id: this.$route.params.event_id}});
            
            setTimeout(() =>  this.cancelEdit(), 2000);
          })

          .catch(error => {
            console.log("**** Error Create",error);
            let html = gettingErrorMessage(error);
            this.$flashMessage.show({
              group: 'exhibitor_create',
              type: 'error',
              title: 'Failed',
              text: html,
              html:html
            });
           });
    },
    cancelEdit() {
      this.setIsNew(false);
      this.resetState();
     
 
      //window.location.reload(); 
    },
    cancelClick() {
      this.resetState()
    }
  },
  
}
</script>

<style scoped lang="scss">
.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>