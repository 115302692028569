import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";

function initialState() {
    return {
        item: {
            _id: null,
            name: null,
            preset_image: null,
            preset_type: null,
            no_of_videos: null,
            no_of_presentations: null,
            no_of_documents: null,
            no_of_logos: null,
            preset_image_svg:null
        },
        eventTypes: [
          
            { "id": 'virtual_exhibition', "title": "VEP 3D" },
            { "id": 'virtual_exhibition_2d', "title": "VEP 2D" },
            { "id": 'physical_event', "title": "DEC" },

        ],
        is_new: false,
        loading: false,
    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    eventTypes: state => state.eventTypes,
    is_new: state => state.is_new,
}

const actions = {
    fetchData({ commit, state }, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/presets/' + id, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .catch(error => {
                console.log("Error",error);
                const message = error.response.data.message || error.message
                console.error(message)
            })
    },
    storeData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);
        console.log("Params**",params);

        delete params._id;
        delete params.createdAt;
        delete params.updatedAt;
        delete params.__v;

        if (params.preset_image) {
            params.preset_image = {
                old_key     : state.item.preset_image.key,
                key         : state.item.preset_image.key,
                temp_key    :  state.item.preset_image.temp_key,
                size        : state.item.preset_image.size,
                extension   : state.item.preset_image.extension,
                uploadedBy  : state.item.preset_image.uploadedBy,
                uploadedAt  : state.item.preset_image.uploadedAt,
            }
        }
        if (params.preset_image_svg) {
            params.preset_image_svg = {
                old_key     : state.item.preset_image_svg.key,
                key         : state.item.preset_image_svg.key,
                temp_key    :  state.item.preset_image_svg.temp_key,
                size        : state.item.preset_image_svg.size,
                extension   : state.item.preset_image_svg.extension,
                uploadedBy  : state.item.preset_image_svg.uploadedBy,
                uploadedAt  : state.item.preset_image_svg.uploadedAt,
            }
        }
        delete params.preset_image_svg;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/presets', params, {headers: headers})
            .then(response => {
                commit('resetState')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({ commit, state, dispatch }) {
        commit('setLoading', true)

        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.is_deleted;
        delete params.status;
        delete params.createdAt;
        delete params.updatedAt;
        if (params.preset_image) {
            params.preset_image = {
                old_key     : state.item.preset_image.key,
                key         : state.item.preset_image.key,
                temp_key    :  state.item.preset_image.temp_key,
                size        : state.item.preset_image.size,
                extension   : state.item.preset_image.extension,
                uploadedBy  : state.item.preset_image.uploadedBy,
                uploadedAt  : state.item.preset_image.uploadedAt,
            }
        }
        if (params.preset_image_svg) {
            params.preset_image_svg = {
                old_key     : state.item.preset_image_svg.key,
                key         : state.item.preset_image_svg.key,
                temp_key    :  state.item.preset_image_svg.temp_key,
                size        : state.item.preset_image_svg.size,
                extension   : state.item.preset_image_svg.extension,
                uploadedBy  : state.item.preset_image_svg.uploadedBy,
                uploadedAt  : state.item.preset_image_svg.uploadedAt,
            }
        }
        delete params.preset_image_svg;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.patch(constants.API_URL + '/api/presets/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data);
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })

    },
    destroyData({commit, state}, id) {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.delete(constants.API_URL + '/api/presets/' + id, {headers: headers})
            .then(response => {
                commit('resetState');
                return response;
            })
            .catch(error => {
                console.error(error)
                return error;
            })
    },
    setPresetType({ commit }, value) {
        commit('setPresetType', value)
    },
    setName({ commit }, value) {
        commit('setName', value)
    },
    setNoOfVideos({ commit }, value) {
        commit('setNoOfVideos', value)
    },
    setNoOfPresentations({ commit }, value) {
        commit('setNoOfPresentations', value)
    },
    setNoOfDocuments({ commit }, value) {
        commit('setNoOfDocuments', value)
    },
    setNoOfLogos({ commit }, value) {
        commit('setNoOfLogos', value)
    },
    setPresetImage({ commit }, value) {
        commit('setPresetImage', value)
    },
    setPresetImageSVG({ commit }, value) {
        commit('setPresetImageSVG', value)
    },

    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    }
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setName(state, value) {
        state.item.name = value
    },
    setNoOfVideos(state, value) {
        state.item.no_of_videos = value
    },
    setNoOfPresentations(state, value) {
        state.item.no_of_presentations = value
    },
    setNoOfDocuments(state, value) {
        state.item.no_of_documents = value
    },
    setNoOfLogos(state, value) {
        state.item.no_of_logos = value
    },
    setPresetImage(state, value) {
        console.log("Preset Image",value);
        state.item.preset_image = value
    },
    setPresetImageSVG(state, value) {
        console.log("SVGPreset Image",value);
        state.item.preset_image_svg = value
    },
    setPresetType(state, value) {
        state.item.preset_type = value
    },
    setIsNew(state, value) {
        state.is_new = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
        console.log('reset')
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
