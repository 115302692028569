<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Agenda Panel Discussion</h1>
        <span v-if="can('panel_discussion_create',checkPermssions)">
          <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create New</button>
          <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
        </span>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid panel-container">
        <div class="row">
          <div class="col-lg-12">
            <OverlayLoading :isLoading="loading" />
            <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="perPage" v-if="can('panel_discussion_view',checkPermssions)">
              <div class="row">
                <div class="col-md-12">
                  <div class="row search_bar">
                    <div class="col-md-2">
                      <div class="input-group w-70">
                        <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                          <option value="5">5</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      <span class="ml-5 rpg_txt">records per page</span>
                    </div>
                    <div class="col-md-2 offset-md-8 ">
                      <div class="input-group">
                        <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                        <span class="input-group-text">
                          <i class="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelhdwrapper row">
                <span class="col1">Title</span>
                <span class="col2"></span>
                <span class="col3"></span>
                <span class="col4">Start/End Time</span>
              </div>
              <TogglePrimaryActive v-if="is_new">
                <template v-slot:default>
                  <PanelAgendaCreateForm :event="event" :languages="languages" />
                </template>
              </TogglePrimaryActive>
             
              <TogglePrimary v-for="(agenda, index) in props.all" :key="index">
                <template v-slot:label>
                 <span class="labelwrapper">
                     <span class="col1"> {{ agenda.translations[0]['title'] }}</span>
                     <span class="col2"></span>
                     <span class="col3"></span>
                     <span class="col4">
                      <svg style="width: 6%; height: auto; margin-right: 15px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> {{ getTimeFromDate(agenda.start_time)}} - {{ getTimeFromDate(agenda.end_time)}}</span>
                 </span>
                </template>
                <template v-slot:default>
                  <PanelAgendaEditForm :event="agenda"  :eventDetail="event" :agenda_id="agenda._id" v-if="is_new === false && is_editing === true && agenda._id === item._id" :languages="languages" />
                  <PanelAgendaShow :item="agenda" :event="event"  :checkPermssions="checkPermssions" v-else />
                </template>
              </TogglePrimary>
            </SearchableContainer>
          </div>
        </div>
       
      </div>
      <FlashMessage group="panel" />
    </template>
  </GeneralDashboardLayout>
  
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import PanelAgendaCreateForm from "@/components/cruds/PanelAgenda/Forms/Create";
import PanelAgendaEditForm from "@/components/cruds/PanelAgenda/Forms/Edit";
import PanelAgendaShow from "@/components/cruds/PanelAgenda/Forms/Show";
import moment from 'moment';
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

export default {
  name: "PanelAgendaIndex",
  components: {
    PanelAgendaCreateForm,
    SearchableContainer,
    TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
    PanelAgendaShow,
    PanelAgendaEditForm,
    TogglePrimaryActive
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
    ...mapGetters('PanelAgendasIndex', ['loading', 'all','event', 'languages']),
    ...mapGetters('PanelAgendasSingle', ['item', 'is_new', "is_editing"]),
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.panel;
  },
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  created() {
    //this.fetchPermissions();
    this.verifyPermissions(this.$route.params.event_id);
    this.fetchData(this.$route.params.event_id).catch(() => {
      this.$flashMessage.show({
        group: 'panel',
        type: 'error',
        title: 'Error',
        text: 'Something went wrong.'
      });
    })
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
      
    ...mapActions('PanelAgendasIndex', ['fetchData']),
    ...mapActions('PanelAgendasSingle', ['resetState', "setIsNew", "setIsEditing"]),
    createClick() {
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
       this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
    
    getTimeFromDate(date) {
      return moment(date).format("hh:mm A");
      
      //return moment.utc(date).tz(this.event.time_zone).format('hh:mm A');
     
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style scoped lang="scss">
.panel-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }

  .card-box {
    background: #cccccc4d;
    border-radius: 10px;
    padding: 30px;
    min-height: 300px;
    font-size: 1.1em;

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 0.01em;
      margin-bottom: 20px;
    }
  }
}
</style>