<template>
  <form @submit.prevent="" novalidate ref="form">
    <div class="container-fluid">
      <div class="snippet general-event-config-snippet">
        <div class="form-group row mb-4">
          <div class="col-lg-6">
            <label>Title*</label>
            <div class="kt-input-icon">
              <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter Blog Title" :value="item.title"  @input="updateTitle" />
              <ValidationMessages :element="v$.item.title" />
            </div>
          </div>
          <div class="col-lg-6">
            <label>Link</label>
            <!-- <v-select name="category" class="" placeholder="Select Category" label="label" @option:selected="updateCategory" :options="categoryEnum" /> -->
           <input type="text" class="form-control" name="link" maxlength="200" placeholder="Enter Blog Link http://test.com"  :value="item.url" @input="updateLink" />
            
          </div>
        </div>
        
        <div class="form-group row mb-4">
          <div class="col-lg-12">
            <DropFiles
                :file="validate_file(item.image)"
                dimmention="512X512"
                :module_id="this.moduleId"
                @onUploaded="updateLogo"
                @onRemoved="removeLogo"
                @onError="logoErrCb"
                :accept="'image/jpeg'"
                :module_type="'events'"
                :asset_type="'documents'">
              <template #header>
                <label>Blog images  (Size should be 512x512 and jpg  in format allowed)</label>
              </template>             
            </DropFiles>
          </div>
        </div>

        <div class="form-group row mb-4">
          <div class="col-lg-12">
            <label>Description*</label>
            <div class="kt-input-icon">
              <textarea rows="3" class="form-control" name="description" placeholder="Enter description"  :value="item.description" @input="updateDescription"></textarea>
              <ValidationMessages :element="v$.item.description" />
            </div>
          </div>
        </div>
      </div>

      
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="button" class="btn btn-primary" @click="submitForm">Save</button>
            &nbsp;<button type="button" class="mb-1 mt-1 me-1 btn btn-primary btn-secondary" @click="cancelClick">Cancel</button>
     
          </div>
        </div>
      </div>

      <FlashMessage group="blog_create" />
    </div>
  </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
  useVuelidate
} from '@vuelidate/core'
import {
  required,
  email,
} from '@vuelidate/validators'

export default {
  name: "BlogsCreate",
  components: {       
        ValidationMessages
    },
  data() {
    return {
      moduleId: ObjectID(),
      selectedLanguage: null,
      languages: []
    }
  },
  setup() {
        return {
            v$: useVuelidate()
        }
    },

    validations() {
        return {
            item: {
               
                title: {
                    required,
                },
                description: {
                    required,
                }
            }
        };
    },
  computed: {
   // ...mapGetters("EventsSingle", {event: "item"}),
    ...mapGetters("BlogsSingle", ["item"])
  },
  

  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.blog_create;
    },
  mounted() {
    // this.fetchEvent(this.$route.params.event_id)
  },
 
  methods: {
    // ...mapActions('BlogsSingle', {
    //   fetchEvent: 'fetchData',

    // }),
    ...mapActions('BlogsSingle', [
      "setLogo",
      "setName",
      "setLink",
      "setDescription",
      "storeData",
      "resetState"
    ]),

    ...mapActions('BlogsIndex', {
      fetchAllSponsors: "fetchData",
      setEditId:"setEditId"
    }),



    
    updateCategory(e) {
      this.setCategory(e.value);
    },
    updateTitle(e) {
      this.setName(e.target.value)
    },
    updateLink(e) {
      this.setLink(e.target.value);
    },
    updateLogo(info, data) {
      console.log("test",data);
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.item.image, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLogo({
        old_key: data.data.key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeLogo(e) {
      this.setLogo(null);
    },
    logoErrCb(e) {
      console.log(e)
      this.$flashMessage.show({
              group: 'blog_create',
              type: 'error',
              title: 'Failed',
              html: "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
              
            })
    },
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },


   
    updateDescription(e) {
      this.setDescription(e.target.value);
    },
    cancelClick() {
      this.resetState()
     
    },
    async submitForm(e) {
      e.preventDefault();



      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {

              _.map(this.v$.$errors, (item) => {
                  if (item.$property.indexOf(['title','description'])) {
                    // this.error_general_tab = true;
                    // this.setErrorGeneralTab = true;
                  } else {
                    // this.setErrorGeneralTab = false;
                  }
              });
            

              return
        }


      this.storeData(this.$route.params.event_id)
          .then(async () => {
            console.log('ppppppp')
            this.$flashMessage.show({
              group : 'blogs',
              type  : 'success',
              title : 'Success',
              time  : 1000,
              html  : "<ul><li>Blog has been created successfully</li></ul>",
            });
            await this.fetchAllSponsors(this.$route.params.event_id);
            //  await this.$router.push({name: 'blogs.index', params: {event_id: this.$route.params.event_id}});
              //setTimeout(() =>  this.cancelEdit(), 2000);
                
          })
          .catch((error) => {
                    let html = gettingErrorMessage(error);
                      this.$flashMessage.show({
                          group: 'blog_create',
                          type: 'error',
                          title: 'Failed',
                          text: html || 'Something went wrong',
                          html: html
                      });
                    
                })
    },
     cancelEdit() {
    
    }
  }
}
</script>

<style scoped lang="scss">
.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>