import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"



function initialState() {
    return {
        item: {
                _id             : null,
                element_type    : null,
                title           : null,
                sorting_order   : null,
                status          : false,
                text            : "",
                image           : null,
                link            : null,
                video           : null,
                caption         : null
           },
       
        element_typeEnum:[  { value: 'Slider',          label: 'Slider' },
                            { value: 'ThumbImgLeft',    label: 'Image on left side' }, 
                            { value: 'ThumbImgRight',   label: 'Image on right side' },
                            { value: 'TextFullWidht',   label: 'Full covered text' }, 
                            { value: 'TextBeforeImg',   label: 'Text before image' }, 
                            { value: 'TextNextToImg',   label: 'Text next to image' },
                            { value: 'TextBeforeVideo', label: 'Text before video' }, 
                            { value: 'TextNextToVideo', label: 'Text next to video' },
                            { value: 'ThumbVideoLeft',  label: 'Video on left side' },
                            { value: 'ThumbVideoRight', label: 'Video on right side' },
                            { value: 'TextTwoCol',      label: 'TextTwoCol' }
                        ],
     
        loading: false,
        is_new: false,
        is_editing: false,
        

    }
}

const getters = {
    item                        : state => state.item,
    loading                     : state => state.loading,
    element_typeEnum            : state => state.element_typeEnum,
    is_new                      : state => state.is_new,
    is_editing                  : state => state.is_editing,
   
}

const actions = {
    setItem({commit,state,dispatch},value){
        commit('setItem', value)
    },
    storeData({ commit, state, dispatch },pageId) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        if (params.image) {
            params.image = {
                old_key: state.item.image.key,
                temp_key:  state.item.image.temp_key,
                size: state.item.image.size,
                extension: state.item.image.extension,
                uploadedBy: state.item.image.uploadedBy,
                uploadedAt: state.item.image.uploadedAt,
            }
        }
        if (params.video) {
            params.video = {
                old_key: state.item.video.key,
                temp_key:  state.item.video.temp_key,
                size: state.item.video.size,
                extension: state.item.video.extension,
                uploadedBy: state.item.video.uploadedBy,
                uploadedAt: state.item.video.uploadedAt,
            }
        }
        return axios.post(constants.API_URL + '/api/event-page/'+pageId+"/page-content", params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
       
    },
    updateData({ commit, state, dispatch },pageId) {
        commit('setLoading', true)
        let params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        if (params.image) {
            params.image = {
                old_key: state.item.image.key,
                size: state.item.image.size,
                temp_key:  state.item.image.temp_key,
                extension: state.item.image.extension,
                uploadedBy: state.item.image.uploadedBy,
                uploadedAt: state.item.image.uploadedAt,
            }
        }
        if (params.video) {
            params.video = {
                old_key: state.item.video.key,
                temp_key :  state.item.video.temp_key,
                size: state.item.video.size,
                extension: state.item.video.extension,
                uploadedBy: state.item.video.uploadedBy,
                uploadedAt: state.item.video.uploadedAt,
            }
        }
     
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };
        
        return axios.patch(constants.API_URL + '/api/event-page/'+pageId+"/page-content/"+state.item._id, params, { headers: headers })
        .then(response => {
            console.log(response)
            commit('setItem', response.data.data)
            return response
        })
        .finally(() => {
            commit('setLoading', false)
        })
   
    },
    setText({commit},value){
        commit('setText',value)
    },
    setElement_type({ commit }, value) {
        commit('setElement_type', value)
    },
    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setStatus({ commit }, value) {
        commit('setStatus', value)
    },
    setSort_order({ commit }, value) {
        commit('setSort_order', value)
    },
    setImage({ commit }, value) {
        commit('setImage', value)
    },
    setVideo({ commit }, value) {
        commit('setVideo', value)
    },
    setLink({ commit }, value) {
        commit('setLink', value)
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setIsNew({ commit }, value) {
        commit('setIsNew', value);
    },
    setIsEditing({ commit }, value) {
        commit('setIsEditing', value);
    },
    setCaption({commit},value){
        commit('setCaption',value);
    }
}
const mutations = {
    setIsNew(state, value) {
        state.is_new = value;
    },
    setIsEditing(state, value) {
        state.is_editing = value;
    },
    setItem(state, item) {
        state.item = item
    },
    setElement_type(state, value) {
        state.item.element_type = value
    },
    setStatus(state, value) {
        state.item.status = value
    },
    setSort_order(state, value) {
        state.item.sorting_order = value
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setTitle(state, value) {
        state.item.title = value;
    },
    setText(state, value) {
        state.item.text = value
    },
    setLink(state,value){
        state.item.link = value
    },
    setImage(state,value){
        state.item.image = value
    },
    setVideo(state,value){
        state.item.video = value
    },
    setCaption(state,value){
        state.item.caption = value
    }
    
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}
