<template>
  <form @submit.prevent="submitForm" novalidate ref="form">
    <OverlayLoading :isLoading="loading" />
    <div class="snippet general-event-config-snippet">
      <div class="form-group row mb-4">
        <div class="col-lg-6">
          <label>Name *</label>
          <div class="kt-input-icon">
            <input type="text" class="form-control" name="name" maxlength="200" placeholder="Enter Sponsor name" :value="sponsor.name" @input="updateName" />
          </div>
        </div>
        <div class="col-lg-6">
          <label>Category</label>
          <v-select name="category" class="" placeholder="Select Category" label="label" @option:selected="updateCategory" :options="categoryEnum" :modelValue="sponsor.category" />
        </div>
      </div>
      <div class="form-group row mb-4">
        <div class="col-lg-12">
          <label>Link</label>
          <div class="kt-input-icon">
            <input type="text" class="form-control" name="link" maxlength="200" placeholder="Enter Sponsor Link http://www.test.com" :value="sponsor.link" @input="updateLink" />
          </div>
        </div>
      </div>
      <div class="form-group row mb-4">
        <div class="col-lg-12">
          <DropFiles
              :file="validate_file(sponsor.logo)"
              :module_id="this.moduleId"
              @onUploaded="updateLogo"
              @onRemoved="removeLogo"
              @onError="logoErrCb"
              :accept="'image/jpeg'"
              :module_type="'events'"
              dimmention="512X512"
              :asset_type="'event_sponsor_create'">
            <template #header>
              <label>Logo * (Size should be 512x512 and jpg  in format allowed)</label>
            </template>
            <template #tooltip>
              <VTooltip style="display:inline-block">
                <i class="fa fa-info-circle" style="font-size:16px"></i>
                <template #popper>
                  <img src="/admin_assets/img/event_garden_smallbanner_5.png" alt="img"/>
                </template>
              </VTooltip>
            </template>
          </DropFiles>
        </div>
      </div>
      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          
          <div class="setting_wrapper mt-2">
              <button type="submit" class="mb-1 mt-1 me-1  btn btn-primary">Save</button>
            <button type="button" class="mb-1 mt-1 me-1  btn btn-secondary" @click="cancelEdit">Cancel</button>
              
            </div>
        </div>
      </div>
    </div>
    <FlashMessage group="sponsor_edit"/>
  </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { gettingErrorMessage } from "@/services/GetValidationMessage";

export default {
  name: "EventSponsorsEdit",
  data() {
    return {
      moduleId: ObjectID(),
      selectedLanguage: null,
      languages: [],
    }
  },
  props: {
    sponsor_id: String
  },
  computed: {
    ...mapGetters("EventsSingle", {event: "item"}),
    ...mapGetters("SponsorsSingle", {
      sponsor: "item",
      loading: "loading",
      categoryEnum: "categoryEnum",
    })
  },
  
  mounted() {
   // this.fetchEvent(this.$route.params.event_id)

    this.fetchSponsor({event_id:this.$route.params.event_id,id:this.sponsor_id});
  },
  beforeUnmount() {
    delete this.$flashMessage.groups.sponsor_edit;
  },
  methods: {
    ...mapActions('EventsSingle', {
      fetchEvent: 'fetchData',
    }),
    ...mapActions('SponsorsSingle', {
      fetchSponsor: "fetchData",
      setLogo: "setLogo",
      setName: "setName",
      setLink: "setLink",
      setCategory: "setCategory",
      updateData: "updateData",
      resetSponsorItem: "resetState"
    }),
    ...mapActions('SponsorsIndex', {
      fetchAllSponsors: "fetchData",
      setEditId: "setEditId",
    }),
    updateCategory(e) {
      this.setCategory(e.value);
    },
    updateName(e) {
      this.setName(e.target.value)
    },
    updateLink(e) {
      this.setLink(e.target.value);
    },
    updateLogo(info, data) {
      
      if (!_.has(data, 'data.key')) {
        return;
      }
      const extension = data.data.key.split('.').pop();
      const key = _.get(this.sponsor.logo, 'key', null);
      const size = _.get(info, 'file.size', 0);
      this.setLogo({
        old_key: key,
        key: data.data.key,
        temp_key: info.id,
        size: size.toString(),
        extension: extension,
        uploadedBy:  info.user.first_name + ' ' + info.user.last_name,
        uploadedAt: '2023.01.01',
      });
    },
    removeLogo(e) {
      this.setLogo(null);
    },
    logoErrCb(e) {
      console.log(e)
      this.$flashMessage.show({
              group : 'sponsor_edit',
              type  : 'error',
              title : 'Failed',
              html  : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
              
            })
    },
    validate_file(fileInfo) {
      if (_.has(fileInfo, 'key'))
        return fileInfo;
      return null;
    },
    getLanguageName() {
      const translate = _.find(this.sponsor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'name', '');
    },
    getLanguageDescription() {
      const translate = _.find(this.sponsor.translations, i => i.key === this.selectedLanguage);
      return _.get(translate, 'description', '');
    },
    async submitForm(e) {
      this.updateData(this.$route.params.event_id)
          .then(async(res) => {
            this.$flashMessage.show({
              group : 'sponsor_edit',
              type  : 'success',
              title : 'Success',
              time  : 1000,
              html  : "<ul><li>Event sponsor has been updated successfully</li></ul>",
            });
           
            await this.fetchAllSponsors(this.$route.params.event_id);
            await this.$router.push({name: 'sponsors.index', params: {event_id: this.$route.params.event_id}});
            
            setTimeout(() =>  this.cancelEdit(), 2000);
            
          })
          .catch(error => {
            let html = gettingErrorMessage(error);
            this.$flashMessage.show({
              group : 'sponsor_edit',
              type  : 'error',
              title : 'Failed',
              time  : 1000,
              text  : html,
              html  : html
            });
          });
    },
    cancelEdit() {
      this.resetSponsorItem();
      this.setEditId(null);
    }
  }
}
</script>

<style scoped lang="scss">
.form-btn {
  color: #201e1eba;
  padding: 9px;
  margin-left: 5px;
  background: #c0cdd345;
  border-radius: 4px;
  border: 1px solid #80808054;

  &.save-btn {
    background: rgba(210, 206, 201, 0.27);
  }
}
</style>