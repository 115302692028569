<template>
  <form @submit.prevent="submitForm" autocomplete="off" novalidate>
    <div class="row" id ="rowId" ref="rowId" >
      <div class="col-md-2">
        <div class="user_img">
          <img v-if="item.profile_picture" :src="IMAGE_URL + item.profile_picture.key" alt="Picture"/>
        </div>
        <div class="upload_user_profile text-center">
               
          <div v-if="item.profile_picture">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-info mt-3" @click="removePicture">Remove</button>
          </div>
          <div v-else>
            <input type="file" ref="file" style="display: none;" @change="fileLoad" accept=".jpg,.jpeg"/>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-info mt-3" @click="$refs.file.click()">Browse</button>
          </div>
        </div>
        <label for="profile_picture">Profile picture (Size should be 512x512 and jpeg,jpg in format allowed)</label>
         
      </div>
      <div class="col-md-5 event_detail_ls">
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Salutation *</div>
            <div class="col-md-8">
              <select class="form-control" @change="updateSalutation">
                <option disabled selected>Select</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
              </select>
              <ValidationMessages :element="v$.item.salutation"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">First Name *</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.first_name" @input="updateFirstName">
              <ValidationMessages :element="v$.item.first_name"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Last Name</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.last_name" @input="updateLastName">
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Email *</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="name" placeholder="Enter Name" :value="item.email" @input="updateEmail">
              <ValidationMessages :element="v$.item.email"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Password*</div>
            <div class="col-md-8">
              <input  type="password" class="form-control" name="password" placeholder="Enter Password" :value="item.password" @input="updatePassword"/>
              <ValidationMessages :element="v$.item.password"/>
            </div>
          </div>
        </div>

        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Confirm Password*</div>
            <div class="col-md-8">
              <input 
              type="password" class="form-control" name="cpassword" placeholder="Enter Confirm Password" :value="item.cpassword" @input="updateConfirmPassword"/>
              <ValidationMessages :element="v$.item.cpassword"/>
            </div>
          </div>
        </div>

        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Facebook Profile</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="facebook_profile" placeholder="Enter Facebook Profile Link" :value="item.facebook_profile" @input="updateFacebookProfile"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Linkedin Profile</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="linkedin_profile" placeholder="Enter Linkedin Profile Link" :value="item.linkedin_profile" @input="updateLinkedinProfile"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Is Paid?</div>
            <div class="col-md-8">
              <input-switch name="is_paid" :value="item.is_paid" @change="changeIsPaid"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Status</div>
            <div class="col-md-8">
              <input-switch name="status" :value="item.status" @change="changeStatus"/>
            </div>
          </div>
        </div>

        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Support User </div>
            <div class="col-md-8">
              <input-switch name="support_user" :value="item.support_user" @change="changeSupportUser"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Personal profile</div>
            <div class="col-md-8">
              <textarea rows="3" class="form-control" name="biography" placeholder="Enter  profile description" :value="item.biography" @input="updateBiography"></textarea>
          
            </div>
                               
          </div>
        </div>
      </div>
      <div class="col-md-5 event_detail_ls">


        
      <!--    <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">User Type *</div>
            <div class="col-md-8">
              <v-select
                        name="user_type"
                        class="form-control"
                        placeholder="User Type"
                        @input="updateUser_type"
                        :value="item.user_type"
                        :options="user_typeEnum"
                        @option:selected="updateUser_type"
                      />




                
                      <ValidationMessages :element="v$.item.user_type"/>
           
            </div>
           
          </div>
        </div>            
-->
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Company</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.company_name" @input="updateCompany"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Job Title</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Company" :value="item.job_title" @input="updateJobTitle"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Phone</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="company" placeholder="Enter Phone number" :value="item.Phone" @input="updatePhone"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Country Code</div>
            <div class="col-md-8">
              <select class="form-control" @change="updateCountryCode">
                            <option value="" selected>Select</option>
                            <option v-for="option in countryCodesAll" :value="option.id" :key="option.id"
                              :selected="(item.id == option.id)">
                              {{ option.name }}
                            </option>
                          </select>

            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Allow notification</div>
            <div class="col-md-8">
              <input-switch name="allow_notification" :value="item.allow_notification" @change="changeAllowNotification"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Porfolio Link</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="portfolio_link" placeholder="Portfolio Link" :value="item.portfolio_link" @input="updatePortfolioLink"/>
            </div>
          </div>
        </div>
        <div class="event_detail_wrapper">
          <div class="row">
            <div class="event_ls_label col-md-2">Twitter Link</div>
            <div class="col-md-8">
              <input type="text" class="form-control" name="portfolio_link" placeholder="Twitter Link" :value="item.twitter_profile" @input="updateTwitterLink"/>
            </div>
          </div>
        </div>

        
      </div>
      <div class="text-right mt-3 mr-5">
        <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary">Save</button>
        <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
 
      </div>
    </div>
    <FlashMessage group="user_create"/>
  </form>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex';
import {
    v4 as uuidv4
} from 'uuid';
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
    sameAs
} from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import { upload, remove } from "@/services/FileUploader";

import ObjectID from 'bson-objectid';
import constants from "@/config/constants";
import InputSwitch from "@/components/Commons/InputSwitch";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
    name: 'UserCreateForm',
  data() {
    return {
      password: '',
      cpassword: '',
     
      IMAGE_URL: constants.IMAGE_URL,
      editor: ClassicEditor,
      config: {
                    toolbar: [
                        ['Bold', 'Italic', 'Underline']
                    ],
                    height: 600
                    }
    }
  },
    components: {
      InputSwitch,
      ValidationMessages
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
   
    validations() {
        return {
            item: {
                salutation: {
                    required,
                },
                first_name: {
                    required,

                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                },
                cpassword: {
                    required 
                }
            }
        };
    },
    computed: {
        ...mapGetters('EventUsersSingle', 
        ['item', 'loading', 'user_typeEnum']
        ),
        ...mapGetters('EventsSingle',{event: "item"}),
        ...mapGetters('EventUsersIndex', ['countryCodesAll']),
    
    },

   
    beforeUnmount() {
        this.resetState()
        delete this.$flashMessage.groups.user_create;
        

    },
    methods: {
        ...mapActions('EventUsersIndex', {
            fetchAllData  : 'fetchData',
            setEditId     : 'setEditId'
            
        }),
        ...mapActions('EventUsersSingle', [
            'storeData',
            'setPicture',
            'resetState',
            'setFirstName',
            'setLastName',
            'setCompany',
            'setEmail',
            'setPassword',
            'setSalutation',
            'setJobTitle',
            'setPhone',
            'setCountry',
            'setAllowNotification',
            'setFacebookProfile',
            'setLinkedinProfile',
            'setPortfolioLink',
            'setTwitterProfile',
            'setIsPaid',
            'setStatus',
            "setUser_type",
            'setCountryCode',
            'setBiography',
            'setUpdateConfirmPassword',
            'setSupportUser'
        ]),

         gettingErrorMessage(error){
          let html = "Something went wrong";
          if(error){
            let errors = error.response.data.data.error;
            html = "<ul>"
            for(let err of errors){
              html+= "<li>"+err.message+"</li>";
            }
            html+="</ul>";
          }
         
          return html;
        },
         getSucessMessage(){
          this.$flashMessage.show({
                        group : 'user_create',
                        type  : 'sucess',
                        title : 'Sucess',
                        time  : 1000,
                        html  : "<ul><li>Event User has been created successfully</li></ul>",
                        
                    });

        },
        async fileLoad(e) {
          try {
            const file = e.target.files[0];
            const id = ObjectID();
            
            let resp = await upload(file, id, "event-users", "512X512", 'user_logo');
            if(resp.response && resp.response.data.status ==="FAILED"){
                this.$flashMessage.show({
                group : 'user_create',
                type  : 'error',
                title : 'Error',
                html  : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
              });
              return;
            }
            
            const user = JSON.parse(localStorage.getItem('user'));
            let extension = resp.data.data.key.split('.').pop();
           
            if(!(extension =="jpg" || extension =="jpeg")){
              this.$flashMessage.show({
                group : 'user_create',
                type  : 'error',
                title : 'Error',
                html  : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
              });
              return;

            }
            this.setPicture({
              key: resp.data.data.key,
              temp_key: id,
              size: '1',
              extension: extension,
              uploadedBy: user.first_name + ' ' + user.last_name,
              uploadedAt: '2023.01.01',
            });
          } catch (e) {
            console.log("Error in set",e);
            this.$flashMessage.show({
              group : 'user_create',
              type  : 'error',
              title : 'Error',
              html  : '<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>'
            });
          }
        },
      removePicture() {
        this.$swal({
          title: 'Are you sure?',
          text: "To fully delete the file submit the form.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          confirmButtonColor: '#dd4b39',
          focusCancel: true,
          reverseButtons: true
        }).then(result => {
          if (typeof result.dismiss === "undefined") {
            remove(this.item.profile_picture.key).then(() => {
              this.setPicture(null);
            })
          }
        })
      },
      updateConfirmPassword(e){
        this.setUpdateConfirmPassword(e.target.value)
      },
        updateSalutation(e) {
            this.setSalutation(e.target.value);
        },
        updateAccessLevel(e) {
            this.setAccessLevel(e.target.value);
        },
        updateFirstName(e) {
            this.setFirstName(e.target.value);
        },
        updateLastName(e) {
            this.setLastName(e.target.value);
        },
        updateEmail(e) {
            this.setEmail(e.target.value);
        },
        updatePassword(e) {
            this.setPassword(e.target.value);
        },
        updateCompany(e) {
            this.setCompany(e.target.value);
        },
        updateJobTitle(e) {
            this.setJobTitle(e.target.value);
        },
        updatePhone(e) {
            this.setPhone(e.target.value);
        },
        
      changeIsPaid(e) {
        this.setIsPaid(e.target.checked);
      },
      changeSupportUser(e){
        this.setSupportUser(e.target.checked);
      },
      changeStatus(e) {
        this.setStatus(e.target.checked)
      },
      changeAllowNotification(e) {
        this.setAllowNotification(e.target.checked)
      },
      updateFacebookProfile(e) {
        this.setFacebookProfile(e.target.value);
      },
      updateLinkedinProfile(e) {
          this.setLinkedinProfile(e.target.value);
      },
      updatePortfolioLink(e) {
          this.setPortfolioLink(e.target.value);
      },
      updateTwitterLink(e) {
          this.setTwitterProfile(e.target.value);
      },
      updateUser_type(value) {
        this.setUser_type(value);
      },
      updateCountryCode(e) {
      this.setCountryCode(e.target.value);
      },
      updateBiography(e){
        this.setBiography(e.target.value);
      },
       checkPwd(str) {
        console.log("PAssword",str);
          if (str.length < 6) {
            return "Password is too short. It should be at least 6 characters.";
          } else if (str.length > 50) {
              return "Password is too long. It should be no more than 50 characters.";
          } else if (str.search(/\d/) === -1) {
              return "Password must contain at least one number.";
          } else if (str.search(/[a-zA-Z]/) === -1) {
              return "Password must contain at least one letter.";
          } else if (str.search(/[!@#$%^&*()_+]/) === -1) {
              return "Password must contain at least one special character (e.g., !@#$%^&*).";
          } else if (str.search(/[^a-zA-Z0-9!@#$%^&*()_+]/) !== -1) {
              return "Password contains invalid characters.";
          }
        return("ok"); 
      },
      cancelClick() {
      this.resetState();
    },
        async submitForm() {

            const isFormCorrect = await this.v$.$validate()
        
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) return
            let resp = this.checkPwd(this.item.password)
            console.log("RESP******",resp);
            
            if(resp!=='ok'){
              this.$flashMessage.show({
                      group : 'user_create',
                      type  : 'error',
                      title : 'Error',
                      time  : 1300,
                      html  : "<ul></li>"+resp+"</li></ul>"
                  });
              return;  
            }
            
            if(this.item.password!== this.item.cpassword){
              this.$flashMessage.show({
                        group : 'user_create',
                        type  : 'error',
                        title : 'Error',
                        time  : 1000,
                        html  : "<ul></li>Confirm Password do not match with Password </li></ul>"
                    });
                return;
            }
            this.storeData(this.$route.params.event_id)
                .then(() => {
              this.$flashMessage.show({
                        group: 'event_users',
                        type: 'success',
                        title: 'Success',
                        time:1000,
                        html: '<ul><li>Event user created successfully.</li></ul>'
                      });
                    this.resetState();
                    this.fetchAllData(this.$route.params.event_id);
                  
                  })
                .catch((error) => {
                    let html =   this.gettingErrorMessage(error);
                    this.$flashMessage.show({
                        group: 'user_create',
                        type: 'error',
                        title: 'Error',
                        time:1000,
                        text:  html,
                        html:html
                    });
                })
        }
    }
}
</script>

<style lang="scss">
.event_detail_wrapper {
    border-bottom: 0px solid #ccc;
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }
}

.setting_wrapper {
    float: right;

    a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;
    }
}
.user_img {
  img {
    width: 100%;
  }
}
</style>
