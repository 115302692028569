<template>
<div class="container-fluid">
    <OverlayLoading :isLoading="loading" />
    <!--begin::Form-->

    <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link active" :data-bs-target="`#panel-settings`" :href="`#panel-settings`" data-bs-toggle="tab">Edit Panel</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#edit_panelists`" :href="`#edit_panelists`" data-bs-toggle="tab">Edit Panelists</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="'#survey-'+changingVariable" :href="`#changingVariable`"  data-bs-toggle="tab" @click="reRenderComponent()">Panel Survey</a>
    
            </li>

        </ul>
        <div class="tab-content">
            <div :id="`panel-settings`" class="tab-pane active">
                <div class="snippet general-event-config-snippet">

                    <div class="tabs">
                        <ul class="nav nav-tabs nav-justified">
                            <li class="nav-item">
                                <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Language
                                    <span v-if="this.error_translation_tab"><span class="tab_error">Errors(*)</span></span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :data-bs-target="`#general-settings`" :href="`#general-settings`" data-bs-toggle="tab">General Settings
                                    <span v-if="this.error_general_tab"><span class="tab_error">Errors(*)</span></span>

                                </a>
                            </li>
                            <li class="nav-item">

                                <a class="nav-link" :data-bs-target="`#theme-settings`" :href="`#theme-settings`" data-bs-toggle="tab">Theme
                                    Settings {{ (this.error_theme_tab) ? 'error' : '' }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :data-bs-target="`#interpretify-settings`" :href="`#interpretify-settings`" data-bs-toggle="tab">Interpretation Settings {{ (this.error_interpretation_tab) ? 'error' : '' }}</a>
                            </li>
                        </ul>

                        <div class="tab-content">

                            <div :id="`language-settings`" class="tab-pane active">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Language *</label>

                                        <v-select :clearable="false" required :rules="requiredRule" :modelValue="selectedLanguageKey" name="language" placeholder="Select language" label="language" class="" @option:selected="updateLaguage" :options="languages" />
                                        <ValidationMessages :element="v$.item.translations" />

                                    </div>
                                    <div class="col-lg-6">
                                        <label>Title *</label>
                                        <div class="kt-input-icon">
                                            <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter language title" :value="getLanguageTitle()" @input="updateLanguageTitle" />
                                        </div>
                                        <ValidationMessages :element="v$.item.translations" />

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <label>Description</label>
                                            <div class="kt-input-icon">
                                                <textarea rows="3" class="form-control" name="description" placeholder="Enter language description" :value="getLanguageDescription()" @input="updateLanguageDescription"></textarea>

                                            </div>
                                            <ValidationMessages :element="v$.item.translations" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div :id="`general-settings`" class="tab-pane">

                                <div class="form-group row">
                                    <div class="col-lg-4">

                                        <label for="event_users">Moderator *</label>

                                        <div class="kt-input-icon">
                                            <v-select :clearable="false" label="name" @option:selected="updateEventUserModerator" :modelValue="getModeratorId(item.moderator)" track-by="id" :reduce="(option) => option.id" :options="moderator" class="" />
                                            <ValidationMessages :element="v$.item.moderator" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label for="event_users">Second Moderator</label>
                                        <div class="kt-input-icon">
                                            <v-select :clearable="false" name="event_users" label="name" @option:selected="updateEventUserSecondModerator" :modelValue="getSecondModeratorId(item.second_moderator)" track-by="id" :options="moderator" :reduce="(option) => option.id" class="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label for="exhibitor">Exhibitor</label>

                                        <div class="kt-input-icon">
                                            <v-select :clearable="false" label="name" class="" placeholder="Select booth exhibitor" @option:selected="updateExhibitor" :modelValue="getExhibitorId(item.exhibitor)" track-by="id" :reduce="(option) => option.id" :options="exhibitor" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label for="start_time">Start Time *</label>
                                        <div class="input-daterange input-group">
                                            <span class="input-group-text">
                                                <i class="fas fa-calendar-alt" />
                                            </span>
                                            <input type="datetime-local" 
                                            class="form-control" 
                                            name="start_date"
                                            :min="getEventDatetime(eventDetail.start_date)" 
                                            :max="getEventDatetime(eventDetail.end_date)" 
                                            :value="getDatetime(item.start_time)" 
                                             @change="updateStart_time" />
                                            <span class="input-group-text rounded-0">from</span>
                                        </div>
                                        <ValidationMessages :element="v$.item.start_time" />
                                    </div>
                                    <div class="col-lg-6">
                                        <label for="end_time">End Time *</label>
                                        <div class="input-daterange input-group">
                                            <span class="input-group-text">
                                                <i class="fas fa-calendar-alt" />
                                            </span>
                                            <input 
                                                type="datetime-local" 
                                                class="form-control" 
                                                name="end_date" 
                                                :min="getEventDatetime(eventDetail.start_date)" 
                                                :max="getEventDatetime(eventDetail.end_date)" 
                                                :value="getDatetime(item.end_time)" 
                                                @change="updateEnd_time" 
                                                />
                                            <span class="input-group-text rounded-0">to</span>
                                        </div>
                                        <ValidationMessages :element="v$.item.end_time" />
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Restrict User to Join before session time ?</label>
                                        <div class="row">
                                            <input-switch name="is_restricted" :value="item.is_restricted" class="custom-control-input" @change="updateIs_restricted" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6" v-show="item.is_restricted">
                                        <label for="minutes">Minutes</label>
                                        <div class="kt-input-icon">
                                            <input type="number" class="form-control" min="0" name="minutes" placeholder="Enter minutes" :value="item.minutes" @input="updateMinutes" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-3">
                                        <label>Enable Rating ?</label>
                                        <div class="row">
                                            <input-switch name="is_rating" :value="item.is_rating" class="custom-control-input" @change="updateIs_rating" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <label>Screen Sharing ?</label>
                                        <div class="row">
                                            <input-switch name="screen_sharing" :value="item.is_screen_sharing" class="custom-control-input" @change="updateScreenSharing" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <label>Live Streaming ?</label>
                                        <div class="row">
                                            <input-switch name="is_live_stream" :value="item.is_live_streaming" class="custom-control-input" @change="updateIsLiveStream" />
                                        </div>
                                    </div>

                                    <div class="col-lg-3" v-if="item.is_live_streaming">
                                        <label for="title">Stream URL</label>
                                        <VTooltip style="display:inline-block">
                                            <i class="fa fa-info-circle" />
                                            <template #popper>
                                                <p style="color:#000">Please insert the embedded url from live stream url.</p>
                                                <img src="/admin_assets/img/live_stream_url.jpg" alt="img" />
                                            </template>
                                        </VTooltip>
                                        <div class="kt-input-icon">
                                            <input type="text" class="form-control" name="stream_url" placeholder="Enter Live Stream URL" :value="item.stream_url" @input="updateStreamUrl" />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <DropFiles :file="item.video" accept="video/mp4" :module_id="this.moduleId" module_type="panel-agenda" @onUploaded="updateVideos" @onRemoved="removeVideo" asset_type="video" @onError="errCb">
                                            <template #header>
                                                <label>Presentation Video (Max size 500MB and format mp4.)</label>
                                            </template>
                                        </DropFiles>
                                    </div>

                                    <div class="col-lg-6" v-show="item.agenda_type != 'pre-recorded'">
                                        <DropFiles :file="item.documents" accept="application/pdf" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updateDocuments" @onRemoved="removeDocument" asset_type="documents" @onError="errCb">
                                            <template #header>
                                                <label for="documents">Presentation Document (Max size 30MB and format pdf.)</label>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <DropFiles :file="item.screen_logo" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="455X256" @onUploaded="updateLogo" @onRemoved="removeLogo" asset_type="documents" @onError="errCb">
                                            <template #header>
                                                <label>Screen Logo* (Size should be 455x256 pixel and format jpg.)</label>
                                            </template>
                                        </DropFiles>
                                        <ValidationMessages :element="v$.item.screen_logo" class="mtb" />
                                    </div>
                                    <div class="col-lg-6">
                                        <DropFiles :file="item.session_logo" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="512X512" @onUploaded="updateSessionTheme" @onRemoved="removeSessionTheme" asset_type="documents" @onError="errCb">
                                            <template #header>
                                                <label>Session Theme Logo (Size should be 512x512 pixel and format jpg.)</label>
                                            </template>
                                        </DropFiles>
                                    </div>
                                </div>
                            </div>

                            <div :id="`theme-settings`" class="tab-pane">

                                <div class="row">
                                    <div class="tabs tabs-vertical tabs-left">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item active" role="presentation">
                                                <a class="nav-link active" :data-bs-target="`#theme_content`" :href="`#theme_content`" data-bs-toggle="tab">Theme Contents</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" :data-bs-target="`#pillar_left_1`" :href="`#pillar_left_1`" data-bs-toggle="tab">Pillar Left 1</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" :data-bs-target="`#pillar_left_2`" :href="`#pillar_left_2`" data-bs-toggle="tab">Pillar Left 2</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" :data-bs-target="`#pillar_right_1`" :href="`#pillar_right_1`" data-bs-toggle="tab">Pillar Right 1</a>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <a class="nav-link" :data-bs-target="`#pillar_right_2`" :href="`#pillar_right_2`" data-bs-toggle="tab">Pillar Right 2</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div id="theme_content" class="tab-pane active show" role="tabpanel">
                                                <div class="snippet general-event-config-snippet">
                                                    <div class="form-group row">
                                                        <div class="col-lg-4">
                                                            <label>Background Color or Picture ?</label>
                                                            <br />
                                                            <div class="switch switch-sm switch-primary">

                                                                <input-switch name="is_background_picture" :value="item.is_background_picture" class="custom-control-input" @change="updateIsBackgroundPicture" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" v-if="item.is_background_picture">
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.background_picture)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="1920X470" @onUploaded="updateBackgroundPicture" @onRemoved="removeBackgroundPicture" asset_type="background_picture">
                                                                <template #header>
                                                                    <label>Background Picture (Size should be 1920x470 pixel and format JPG.) </label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VTooltip style="display:inline-block">
                                                                        <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                                        <template #popper>
                                                                            <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                                        </template>
                                                                    </VTooltip>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row backgroundcolor_option" v-else>
                                                        <div class="col-lg-6">
                                                            <label>Background Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="background_color" :value="item.background_color" @input="updateBackgroundColor" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Background Stripes Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="background_stripes_color" :value="item.background_stripes_color" @input="updateBackgroundStripesColor" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-6">
                                                            <label>Stage Color 1</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="stage_color1" :value="item.stage_color1" @input="updateStageColor1" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Stage Color 2</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="stage_color2" :value="item.stage_color2" @input="updateStageColor2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-6">
                                                            <label>Bottom Background Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="background_color_bottom" :value="item.background_color_bottom" @input="updateBackgroundColorBottom" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pillar_left_1" class="tab-pane" role="tabpanel">
                                                <p>Pillar Left 1</p>
                                                <div class="snippet general-event-config-snippet">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6">
                                                            <label>Pillar Header Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="left1_pillar_header_color" :value="item.left1_pillar_header_color" @input="updateLeft1PillarHeaderColor" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Pillar Bottom Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="left1_pillar_color_bottom" :value="item.left1_pillar_color_bottom" @input="updateLPillarColorBottom" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_left1_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft1Logo1" @onRemoved="removePillarLeft1Logo1" asset_type="pillar_left1_logo1">
                                                                <template #header>
                                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_left1_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft1Logo2" @onRemoved="removePillarLeft1Logo2" asset_type="pillar_left1_logo2">
                                                                <template #header>
                                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_left1_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft1Logo3" @onRemoved="removePillarLeft1Logo3" asset_type="pillar_left1_logo3">
                                                                <template #header>
                                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pillar_left_2" class="tab-pane" role="tabpanel">
                                                <p>Pillar Left 2</p>
                                                <div class="snippet general-event-config-snippet">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6">
                                                            <label>Pillar Header Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="left2_pillar_color" :value="item.left2_pillar_color" @input="updateLeft2PillarColor" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Pillar Bottom Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="left2_pillar_color_bottom" :value="item.left2_pillar_color_bottom" @input="updateL2PCBottom" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_left2_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft2Logo1" @onRemoved="removePillarLeft2Logo1" asset_type="pillar_left2_logo1">
                                                                <template #header>
                                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_left2_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft2Logo2" @onRemoved="removePillarLeft2Logo2" asset_type="pillar_left2_logo2">
                                                                <template #header>
                                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_left2_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarLeft2Logo3" @onRemoved="removePillarLeft2Logo3" asset_type="pillar_left2_logo3">
                                                                <template #header>
                                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pl_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pillar_right_1" class="tab-pane" role="tabpanel">
                                                <p>Pillar Right 1</p>
                                                <div class="snippet general-event-config-snippet">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6">
                                                            <label>Pillar Header Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="right1_pillar_color" :value="item.right1_pillar_color" @input="updateRight1PillarColor" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Pillar Bottom Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="right1_pillar_color_bottom" :value="item.right1_pillar_color_bottom" @input="updateRight1PillarColorBottom" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_right1_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight1Logo1" @onRemoved="removePillarRight1Logo1" asset_type="pillar_right1_logo1">
                                                                <template #header>
                                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_right1_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight1Logo2" @onRemoved="removePillarRight1Logo2" asset_type="pillar_right1_logo2">
                                                                <template #header>
                                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_right1_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight1Logo3" @onRemoved="removePillarRight1Logo3" asset_type="pillar_right1_logo3">
                                                                <template #header>
                                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="pillar_right_2" class="tab-pane" role="tabpanel">
                                                <p>Pillar Right 2</p>
                                                <div class="snippet general-event-config-snippet">
                                                    <div class="form-group row">
                                                        <div class="col-lg-6">
                                                            <label>Pillar Header Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="right2_pillar_color" :value="item.right2_pillar_color" @input="updateRight2PillarColor" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>Pillar Bottom Color</label>
                                                            <VPopper  arrow hover style="display:inline-block" class="dark">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #content>
                                       Select color for the background
                                      </template>
                                    </VPopper>
                                                            <div class="input-group color" data-plugin-colorpicker>
                                                                <input type="color" class="form-control" name="right2_pillar_color_bottom" :value="item.right2_pillar_color_bottom" @input="updateRight2PillarColorBottom" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_right2_logo1)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight2Logo1" @onRemoved="removePillarRight2Logo1" asset_type="pillar_right2_logo1">
                                                                <template #header>
                                                                    <label>Logo 1 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_right2_logo2)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight2Logo2" @onRemoved="removePillarRight2Logo2" asset_type="pillar_right2_logo2">
                                                                <template #header>
                                                                    <label>Logo 2 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <DropFiles @onError="errCb" :file="validate_file(item.pillar_right2_logo3)" accept="image/jpeg" :module_id="this.moduleId" module_type="panel-agenda" dimmention="122X90" @onUploaded="updatePillarRight2Logo3" @onRemoved="removePillarRight2Logo3" asset_type="pillar_right2_logo3">
                                                                <template #header>
                                                                    <label>Logo 3 (Size should be 122x90 pixel and format JPG.)</label>
                                                                </template>
                                                                <template #tooltip>
                                                                    <VPopper hover style="display:inline-block" class="tooltip_img">
                            <i class="fa fa-info-circle" style="font-size:16px"></i>
                            <template #content>
                              <img src ="/assets/img/lp_pr_1_logo.png"/>
                            </template>
                          </VPopper>
                                                                </template>
                                                            </DropFiles>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div :id="`interpretify-settings`" class="tab-pane">
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <label>Enable "Interprefy" Interpretation</label>
                                        <br />
                                        <div class="switch switch-sm switch-primary">
                                            <input-switch name="interprefy" :value="item.interpretation.is_interprefy_type_enabled" id="customSwitchesEx" class="custom-control-input" @change="updateInterprefy" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Interprefy token</label>
                                        <div class="kt-input-icon">
                                            <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter event title" :disabled="interprefy_disabled == true" @change="updateInterprefyToken" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <label>Enable "AWS" Interpretation</label>
                                        <br />
                                        <div class="switch switch-sm switch-primary">
                                            <input-switch name="interprefy" :value="item.interpretation.aws_interpretation" id="customSwitchesEx" class="custom-control-input" @change="updateAwsInterpretation" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label>Enable "Speech to Speech"</label>
                                        <div class="kt-input-icon">
                                            <input-switch name="speech_to_speech" :value="item.interpretation.is_aws_speech_to_speech_enabled" id="enableSpeechToSpeech" class="custom-control-input" @change="updateAwsEnableSpeechToSpeech" :styleDisable="this.aws_interprefy_disabled" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <label>Enable "Speech to Text"</label>
                                        <div class="kt-input-icon">
                                            <input-switch name="speech_to_text" :value="item.interpretation.is_aws_speech_to_text_enabled" id="enableSpeechToText" class="custom-control-input" @change="updateAwsEnableSpeechToText" :styleDisable="this.aws_interprefy_disabled" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <label>Enable "Chat" Interpretation</label>
                                        <br />
                                        <div class="switch switch-sm switch-primary">
                                            <input-switch name="interprefy" :value="item.chat" id="customSwitchesEx" class="custom-control-input" @change="updateChat" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <div class="card-body text-right mt-3 mr-5">
                    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm">Save</button>
                    <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
                </div>

            </div>
            <div :id="`edit_panelists`" class="tab-pane">

                <button class="newbtn" @click="createClick">Add Panelist</button>
                <button class="newbtn" @click="cancelClick" v-if="is_new==true">Cancel</button>

                <div class="snippet general-event-config-snippet">

                    <TogglePrimary v-if="is_new">
                        <template v-slot:default>
                            <div class="container-fluid">

                             

                                <div class="form-group row">
                                    <div class="col-lg-4">
                                        <label>Panelist * </label>
                                        <v-select placeholder="Select Panelist" label="name" :options="filteredpanelistitems" track-by="id" @option:selected="updatePanelistNew" />
                                        <ValidationMessages :element="v$.moderator" />
                                    </div>

                                    <div class="col-lg-4">
                                        <label>Presentation Order *</label>
                                        <v-select placeholder="Presentation Order" label="name" :value="presentation_order" :options="preorder" track-by="id" @option:selected="updatePresentationOrderNew" />
                                        <ValidationMessages :element="v$.presentation_order" />
                                    </div>

                                    <div class="col-lg-4">
                                        <label>Presentation Duration </label>
                                        <div class="kt-input-icon">
                                            <input type="number" class="form-control" name="duration" maxlength="200" oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" placeholder="Enter Duration" @input="updateDurationNew" />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-lg-12">

                                        <DropFiles :file="items.panelist.presentation_video" accept="video/mp4" :module_id="this.moduleId" module_type="panel-agenda" @onUploaded="updatePanelistVideoNew" @onRemoved="removePanelistVideoNew" asset_type="panel_agenda" @onError="errCb">
                                            <template #header>
                                                <label>Presentation Video (Max size 500MB and format mp4.)</label>
                                            </template>
                                        </DropFiles>

                                    </div>

                                    <div class="col-lg-12">
                                        <DropFiles :file="items.panelist.presentation_file" accept="application/pdf" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updatePanelistDocumentNew" @onRemoved="removePanelistDocumentNew" asset_type="documents" @onError="errCb">
                                            <template #header>
                                                <label for="documents">Presentation Document (Max size 30MB and format pdf.)

                                               
                                                </label>
                                            </template>
                                        </DropFiles>
                                    </div>

                                </div>

                            </div>

                            <div class="card-body text-right mt-3 mr-5">
                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitNewPanelistForm">Save</button>
                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
                            </div>
                        </template>

                    </TogglePrimary>

                    <!-- v-if="is_new === false" -->

                    <div>

                        <TogglePrimary v-for="(agenda, index) in getpanelist" :key="index">

                            <template v-slot:label>
                                <span class="labelwrapper">
                                    <span class="col1"><label>Panelist</label>{{getSelectedModerator(moderator,agenda.panelist_id)}}</span>
                                    <span class="col2"><label>Presentation Order</label>{{ agenda.presentation_order }}</span>
                                    <span class="col3"><label>Duration</label>{{ agenda.presentation_duration }}</span>
                                    <span class="col6"><label>Panelist Assets</label>

                                        <FilesShow :file="agenda.presentation_video?agenda.presentation_video:null" asset_type="video/mp4">
                                        </FilesShow>

                                        <FilesShow :file="agenda.presentation_file?agenda.presentation_file:null" asset_type="application/pdf">
                                        </FilesShow>
                                    </span>
                                </span>
                            </template>

                            <!-- {{ items }} -->

                            <div class="form-group row">
                                <div class="col-lg-4">
                                    <label>Panelist *</label>

                                    <v-select placeholder="Select Panelist" label="name" :options="moderator" track-by="id" :modelValue="getSelectedModerator(moderator,agenda.panelist_id)" :reduce="(option) => option.id" @option:selected="updatePanelist($event,index)" />
                                    <input type="hidden" lable="id" :modelValue="getExistingPanelistID(agenda.panelist_id._id)" />
                                    <ValidationMessages :element="v$.moderator" />
                                </div>

                                <div class="col-lg-4">
                                    <label>Presentation Order *</label>
                                    <v-select placeholder="Presentation Order" label="name" :options="preorder" :modelValue="getSelectedPresentationOrder(preorder,agenda.presentation_order)" @option:selected="updatePresentationOrder($event,index)" />
                                    <ValidationMessages :element="v$.presentation_order" />
                                </div>

                                <div class="col-lg-4">
                                    <label>Presentation Duration </label>
                                    <div class="kt-input-icon">
                                        <input type="number" class="form-control" name="duration" :value="agenda.presentation_duration" maxlength="200" oninput="this.value = 
 !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" placeholder="" @input="updateDuration($event,index)" />
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-lg-12">

                                    <!-- {{ item.video }} -->

                                    <!-- {{ getpanelist }} -->

                                    <!-- {{ items.panelist.presentation_video }} -->

                                    <DropFiles :file="agenda.presentation_video" accept="video/mp4" :module_id="this.moduleId" module_type="panel-agenda" @onUploaded="updatePanelistVideo" @onRemoved="removePanelistVideo(index)" :asset_type="'document_'+index" @onError="errCb">
                                        <template #header>
                                            <label>Presentation Video (Max size 500MB and format mp4.)</label>
                                        </template>
                                    </DropFiles>

                                </div>
                                <div class="col-lg-12">

                                    <DropFiles :file="agenda.presentation_file" accept="application/pdf" :module_id="this.moduleId" module_type="panel-agenda" :dimmention="null" @onUploaded="updatePanelistDocument" @onRemoved="removePanelistDocument(index)" :asset_type="'document_'+index" @onError="errCb">
                                        <template #header>
                                            <label for="documents">Presentation Document (Max size 30MB and format pdf.)</label>
                                        </template>
                                    </DropFiles>

                                </div>
                            </div>

                            <div class="card-body text-right mt-3 mr-5">
                                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitPanelistForm">Update Panelist</button>
                                <button class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deletePanelist(agenda_id, agenda.panelist_id)">Delete</button>
                            </div>

                        </TogglePrimary>

                    </div>

                </div>

            </div>

            <div :id ="'survey-' +changingVariable" class="tab-pane">
                <surveyIndex :agenda_id="this.agenda_id" surveyNameFile="PanelDiscussionSurvey" ></surveyIndex>
            </div>

        </div>

    </div>

    <FlashMessage group="panel_agenda_edit" />

</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    upload
} from "@/services/FileUploader";
import {
    v4 as uuidv4
} from 'uuid';
import InputSwitch from "@/components/Commons/InputSwitch";
import _ from "lodash";
import ObjectID from 'bson-objectid';
import moment from 'moment';
// import DropFiles from "@/components/Commons/DropFiles";
//import SurveyForm from "@/components/Commons/SurveyForm";
//import SurveyQuestionCreate from "@/components/Commons/SurveyQuestionCreate";
//import SurveyReport from "@/components/Commons/SurveyReport";
// import SurveyShow from "@/components/Commons/SurveyShow";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import surveyIndex from "@/components/cruds/Survey/Index";

import {
    gettingErrorMessage
} from "@/services/GetValidationMessage";
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators';
import FilesShow from "@/components/Commons/FilesShow";
const maxVideoFileSize = 524288000; // Max file size
const maxPdfFileSize =  32096088;  // Max file size

export default {
    name: "PanelAgendaEditForm",
    components: {
        InputSwitch,
        TogglePrimary,
        // SurveyForm,
        // SurveyQuestionCreate,
        // SurveyReport,
        FilesShow,
        surveyIndex,
        // SurveyShow,
    },
    props: {
        event: null,
        agenda_id: {
            type: [String, null],
            default () {
                return null;
            }
        },
        languages: {
            type: Array,
        },
        eventDetail:null

    },
    data() {
        return {
           
            changingVariable: 0,
         
            survey_new: true,
            moduleId: ObjectID(),
            translation: [],
            languageKey: null,
            questionList: true,
            // setIsSubEditing: false,
            edit_id: null,
            is_new: false,
            selectedLanguage: null,
            aws_interprefy_disabled: true,
            interprefy_disabled: true,
            //setIsAddQuestion: false,
            setIsSurveyEdit: false,
            error_general_tab: false,
            error_translation_tab: false,
            error_theme_tab: false,
            error_interpretation_tab: false,
            err_translations: null,

            // surveyitems: {
            //   title: '',
            //   is_open: false,

            // }
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    
    validations() {
        return {
            item: {

                // translations: {
                //     required
                // },
                start_time: {
                    required,
                },
                end_time: {
                    required,
                },
                screen_logo: {
                    required,
                },
                moderator: {
                    required,
                }
            },

            surveyitems: {

                title: {
                    required,
                }
            },

            // panel:{

            moderator: {
                required,
            },

            presentation_order: {
                required,
            },

            // },

        };
    },

    beforeUnmount() {
        this.resetState();
        delete this.$flashMessage.groups.panel_agenda_edit;
    },
    created(){
      //  let start_time = moment.utc(this.item.start_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
      //  this.setStart_time(start_time);
      //  let end_time = moment.utc(this.item.end_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
      //  this.setEnd_time(end_time);
    },

    mounted() {
        //this.setEventTd(this.event._id);
        this.fetchallpanelist(this.agenda_id);
        this.fetchpanelistdata(this.agenda_id);
        this.updateTimeZone();
        this.fetchData(this.agenda_id);
        //let start_time = moment.utc(this.item.start_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss');
        //console.log("start_time",start_time);
       // this.setStart_time(start_time);
        //let end_time = moment.utc(this.item.end_time).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
        //this.setEnd_time(end_time);

    },
    computed: {

        ...mapGetters('PanelAgendasIndex', ['exhibitor', 'moderator', 'event_timezone','all']),
        ...mapGetters('PanelistIndex', ['all']),
        // ...mapGetters('SurveysIndex', ['surveyitems', 'newSurvey']),
        // ...mapGetters('SurveysSingle', [
        //     'sitems',
        //     'surveytemp',           
        //     'is_survey_editing',
        //     'is_new_question',
        //     'datanum',
        //     'questionstatus',            
        // ]),

        

        ...mapGetters('EventUsersIndex', {
            eventUsers: "all"
        }),
        
        ...mapGetters('PanelAgendasSingle', [
            'filteredpanelistitems',
            'item',
            'eventsAll',
            'loading',
            'panelistitems',
            'selectedLanguageKey',
            'selectedLanguageTitle',
            'selectedLanguageDescirption',
            'getpanelist', //panelist array
            'error_translation_tab',
            'error_general_tab',
            'preorder',
            'items'

        ]),
        
        ...mapGetters('PanelistSingle', [
            // 'getpanelist',
            'panelist_id',
            'presentation_video',
            //'items',
            'is_new',
            'is_editing',
        ])

    },
    methods: {
        ...mapActions('PanelAgendasIndex', {
            fetchAllData: 'fetchData',
        }),

        // ...mapActions('SurveysSingle', [
        //     "setItem",
        //     "destroyData",
        //     "setIsSubEditing",
        //     "setIsNewQuestion",
        //     "setSurveytemp",
        //     "setDataNum",

        // ]),
        ...mapActions('PanelistIndex', {
            fetchpanelistdata: "fetchData"
        }),
        // ...mapActions('SurveysIndex', {
        //     fetchsurveydata: "fetchSurveyData"
        // }), 
        // ...mapActions('SurveysIndex', ["setIs_open", "setSurveytitle", "updateSurveytitleData", "storeSurveyData", "destroySurveyData", "setNewSurvey"]),

        ...mapActions('PanelistSingle', {           
            setIsNew: "setIsNew",
            setIsEditing: "setIsEditing",           
            resetPanelistState: "resetPanelistState",
            setPanelist: "setPanelist",
            destroyPanelistData: "destroyPanelistData",
        }),

        ...mapActions('PanelistSingle', {
            fetchallpanelist: "fetchData",

        }),

        ...mapActions('PanelAgendasSingle', [            
            'setPanelistID', // nnn
            'updatePanelistData', //nn
            'setExistingpanelistID', //nn
            'storeNewPanelistData', //nn
            'setPresentationOrder', //nn
            'setPresentationOrderNew',
            'setDuration', //nn
            'setDurationNew',
            'setPanelistVideo', //nn
            'setPanelistVideoNew',
            'setPanelistDocument', //nn
            'setPanelistDocumentNew',
            'setPanelistIDNew',
            'setErrorGeneralTab',
            'setErrorTranslationTab',
            'updateData',
            'resetState',
            'fetchData',
            'storeData',
            'setTranslations',
            'setEvent_users',
            'setEvent_userss',
            'setExhibitor',
            'setStart_time',
            'setEnd_time',
            'setIs_restricted',
            'setMinutes',
            "setIs_rating",
            'setScreenSharing',
            'setVideos',
            'setDocuments',
            'setIsLiveStream',
            'setStreamUrl',
            'setLogo',
            'setSessionTheme',
            'setIsBackgroundPicture',
            'setBackgroundColor',
            'setBackgroundStripesColor',
            'setStageColor1',
            'setStageColor2',
            'setBackgroundColorBottom',

            'setLeft1PillarHeaderColor',

            'setLeft2PillarColorBottom',

            'setRight1PillarColorBottom',

            'setRight2PillarColorBottom',

            'setLeft1PillarColor',
            'setLeft1PillarBottomColor',
            'setPillarLeft1Logo1',
            'setPillarLeft1Logo2',
            'setPillarLeft1Logo3',
            'setLeft2PillarColor',
            'setLeft2PillarBottomColor',
            'setPillarLeft2Logo1',
            'setPillarLeft2Logo2',
            'setPillarLeft2Logo3',
            'setRight1PillarColor',
            'setRight1PillarBottomColor',
            'setPillarRight1Logo1',
            'setPillarRight1Logo2',
            'setPillarRight1Logo3',
            'setRight2PillarColor',
            'setRight2PillarBottomColor',
            'setPillarRight2Logo1',
            'setPillarRight2Logo2',
            'setPillarRight2Logo3',
            'setEventTd',
            'setCreatedBy',
            'setBackgroundPicture',
            'setSpeakerPicutre',
            'setInterprefy',
            'setInterprefyToken',
            'setAwsInterpretation',
            'setAwsEnableSpeechToSpeech',
            'setAwsEnableSpeechToText',
            'setChat',
            'setLangKey',
            'setLangTitle',
            'setLangDescirption',
            'setTimeZone',

        ]),
        
        ...mapActions('EventUsersIndex', {
            fetchEventUsersAll: "fetchData"
        }),

       
        reRenderComponent() {
          this.changingVariable += 1
        },
      
        fetchCorePanelist(moderator, getpanelist) {
            console.log('MOOOOOOOD',moderator);
            console.log('MOOOOOOOD',getpanelist);           
        },

        

        updateRight1PillarColorBottom(e) {
            this.setRight1PillarColorBottom(e.target.value);
        },

        updateRight2PillarColorBottom(e) {
            this.setRight2PillarColorBottom(e.target.value);
        },

        updateLPillarColorBottom(e) {
            // console.log('coooooloooor',e.target.value)
            this.setLeft1PillarBottomColor(e.target.value)
        },

        updateL2PCBottom(e) {
            this.setLeft2PillarColorBottom(e.target.value);
        },

        getSelectedModerator(moderator, moderatorId) {
            console.log('moderatorId line:', moderatorId)
            const getSelectedModerator = _.find(moderator, i => i.id === moderatorId);
            return _.get(getSelectedModerator, 'name', '');
        },

        getSelectedPresentationOrder(preorder, pid) {
            console.log('preorder line:', preorder,pid)
            const params = _.cloneDeep(preorder);
            params.push({id:pid,name:pid})
            const getSelectedModerator = _.find(params, i => i.id === pid);
            return _.get(getSelectedModerator, 'id', '');
        },

        // deleteData(surid, qid) {
        //     this.$swal({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         type: "warning",
        //         showCancelButton: true,
        //         confirmButtonText: "Delete",
        //         confirmButtonColor: "#dd4b39",
        //         focusCancel: true,
        //         reverseButtons: true
        //     }).then(result => {
        //         if (result.isConfirmed) {
        //             const data = {
        //                 surveyid: surid,
        //                 questionid: qid,
        //             }
        //             this.destroyData(data).then(() => {

        //                 this.$flashMessage.show({
        //                     group: 'panel_agenda_edit',
        //                     type: 'success',
        //                     title: 'Success',
        //                     text: 'Survey question deleted successfully.'
        //                 });
        //                 this.fetchsurveydata(this.agenda_id);
                       
                       
        //             })
        //         }
        //     });
        // },

        deletePanelist(panelid, panelistid) {

            console.log("panelid,===>",panelid," panelistid", panelistid);
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "#dd4b39",
                focusCancel: true,
                reverseButtons: true
            }).then(result => {
                if (result.isConfirmed) {
                    const data = {
                        panelid: panelid,
                        panelistid: panelistid,
                    }
                    this.destroyPanelistData(data).then(() => {

                        this.$flashMessage.show({
                            group: 'panel',
                            type: 'success',
                            title: 'Success',
                            text: 'Panlist has been deleted successfully.'
                        });
                        this.resetState();
                        this.setPanelist();

                    })
                }
            });
        },

        createClick() {
            this.is_new = true;
            //this.setIsNew(true);
            this.setIsEditing(false);
        },
        cancelClick() {
            this.setIsNew(false);
            this.resetState();
        },

        cancelSurveyEdit() {
            this.setIsSurveyEdit = false;
            this.setNewSurvey(false);
        },

        getSpeakerId(panelistitems) {
            return _.has(panelistitems, '_id') ? panelistitems._id : panelistitems;

        },

        getExistingPanelistID(panelistid) {
            this.setExistingpanelistID(panelistid);
        },

        getModeratorId(moderator) {
            return _.has(moderator, '_id') ? moderator._id : moderator;
        },

        getSecondModeratorId(second_moderator) {
            return _.has(second_moderator, '_id') ? second_moderator._id : second_moderator;
        },

        getExhibitorId(exhibitor) {
            return _.has(exhibitor, '_id') ? exhibitor._id : exhibitor;
        },

        getPresentationOrder(porder) {
            return _.has(porder, '_id') ? porder._id : porder;
        },

       

        updateLaguage(value) {
            this.err_translations = null;
            this.setLangKey(value);
            this.languageKey = value;
            this.setLangTitle(null);
            this.setLangDescirption(null);

            if (_.isUndefined(_.find(this.item.translations, {
                    'key': value,
                }))) {
                let obj = {
                    "key": `${value}`,
                    "title": null,
                    "description": null
                }
                this.item.translations.push(obj);
            } else {

                let selectedLang = _.find(this.item.translations, {
                    'key': this.selectedLanguageKey,
                })
                if (!_.isUndefined(selectedLang)) {
                    this.setLangTitle(selectedLang.title);
                    this.setLangDescirption(selectedLang.description);
                }
            }
        },

        updateLanguageTitle(e) {
            this.err_translations = null;
            if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
                this.setTranslations({
                    key: this.selectedLanguageKey,
                    update_key: 'title',
                    update_value: e.target.value
                });

            }
            this.setLangTitle(e.target.value);
        },
        updateLanguageDescription(e) {
            if (!_.isNull(this.selectedLanguageKey) && !_.isEmpty(this.selectedLanguageKey)) {
                this.setTranslations({
                    key: this.selectedLanguageKey,
                    update_key: 'description',
                    update_value: e.target.value
                });
            }
            this.setLangDescirption(e.target.value);
        },
        getLanguageTitle() {
            return this.selectedLanguageTitle;
        },
        getLanguageDescription() {
            return this.selectedLanguageDescirption;
        },

        updateEventUserModerator(value) {
            console.log(value.id);
            this.setEvent_users(value.id);
        },

        updateEventUserSecondModerator(value) {
            //  console.log('moderation id', value.id)
            this.setEvent_userss(value.id);
        },

        updatePanelist(value, keyval) {
            const ary = {
                selval: value.id,
                keyval: keyval,
            }
            this.setPanelistID(ary);
        },

        updatePanelistNew(value) {
            this.setPanelistIDNew(value.id);
        },

        updatePresentationOrder(value, keyval) {
            const ary = {
                selval: value.id,
                keyval: keyval,
            }

            this.setPresentationOrder(ary);
            // this.setPanelist();
        },

        updatePresentationOrderNew(value) {
            this.setPresentationOrderNew(value.id);
            // this.setPanelist();
        },

        updateDuration(e, keyval) {
            // console.log("e.target.value", e.target.value)
            const ary = {
                selval: e.target.value,
                keyval: keyval,
            }

            //  this.setDuration(e.target.value)
            this.setDuration(ary)
        },

        updateDurationNew(e) {
            this.setDurationNew(e.target.value)
        },

        setSelected(value) {
            console.log('%%%%%%%', value);
        },

        errCb(e) {
            this.$flashMessage.show({
                group   : 'panel_agenda_edit',
                type    : 'error',
                title   : 'Failed',
                time    : 1000,
                html    : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
            });

        },

        documentsErrCb(e) {
            this.$flashMessage.show({
                group: 'panel_agenda_edit',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong.'
            });
        },
        updateExhibitor(e) {
            this.setExhibitor(e.id);
        },
        updateStart_time(e) {
            this.setStart_time(e.target.value)
        },
        updateEnd_time(e) {
            this.setEnd_time(e.target.value)
        },
        updateIs_restricted(e) {
            this.setIs_restricted(e.target.checked)
        },
        updateMinutes(e) {
            this.setMinutes(e.target.value)
        },
        updateIs_rating(e) {
            this.setIs_rating(e.target.checked)
        },
        updateScreenSharing(e) {
            this.setScreenSharing(e.target.checked)
        },
        updateVideos(info, data) {
            this.err_video = null;
            const extension = data.data.Key.split('.').pop()
            const key = _.get(this.item.videos, 'key', null);
            const size = _.get(info, 'file.size', 0)
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
              }
            this.setVideos({
                old_key : data.data.Key,
                temp_key: info.id,
                key     : data.data.Key,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },

        removeVideo() {
            this.setVideos(null);
        },

        updatePanelistVideo(info, data) {

            let mainvar = data.data.Location
            let slug = mainvar.slice(mainvar.indexOf('_') + 1);
            let finaldockey = slug.charAt(0);
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key = _.get(this.items.panelist.presentation_video, 'key', null);
            const size = _.get(info, 'file.size', 0);
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
            }
            this.setPanelistVideo({
                doc_array_index : finaldockey,
                old_key         :  data.data.key?data.data.key:data.data.Key,
                temp_key        : info.id,
                key             : data.data.key?data.data.key:data.data.Key,
                size            : size.toString(),
                extension       : extension,
                uploadedBy      : info.user.first_name + ' ' + info.user.last_name,
                uploadedAt      : '2023.01.01',
            });
        },

        removePanelistVideo(keyval) {
            this.setPanelistVideo(keyval);
        },

        //================= New panelist Video===================//

        updatePanelistVideoNew(info, data) {
            console.log(data.data);
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key   = _.get(this.items.panelist.presentation_video, 'key', null);
            const size  = _.get(info, 'file.size', 0);
       
           // if (!_.has(data, 'data.key')) {
           //     return;
            //}
            if(size>maxVideoFileSize){
                this.showCbError();
                return;
            }
            this.setPanelistVideoNew({
                old_key     :  data.data.key?data.data.key:data.data.Key,
                temp_key    : info.id,
                key         : data.data.key?data.data.key:data.data.Key,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },

        removePanelistVideoNew(keyval) {
            this.setPanelistVideoNew(null);
        },

        //================= New panelist Video Ends===================//

        updatePanelistDocument(info, data) {
            let mainvar = data.data.Location
            let slug = mainvar.slice(mainvar.indexOf('_') + 1);
            let finaldockey = slug.charAt(0);
            console.log("finaldockey",info);

           
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key = _.get(this.items.panelist.presentation_file, 'key', null);
            const size = _.get(info, 'file.size', 0);
            console.log("size",size);
            if(size>maxPdfFileSize){
                this.showCbError();
                return;
            }
            this.setPanelistDocument({
                doc_array_index: finaldockey,
                old_key : data.data.key?data.data.key:data.data.Key,
                key     : data.data.key?data.data.key:data.data.Key,
                temp_key: info.id,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  : info.user.first_name + ' ' + info.user.last_name,
                uploadedAt  : '2023.01.01',
            });
        },
        removePanelistDocument(keyval) {
            this.setPanelistDocument(keyval)
        },

        //================= New panelist document===================//

        updatePanelistDocumentNew(info, data) {
            try{
                const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
                const key   = _.get(this.items.panelist.presentation_file, 'key', null);
                const size  = _.get(info, 'file.size', 0);
                if(size>maxPdfFileSize){
                    this.showCbError();
                    return;
                }
                this.setPanelistDocumentNew({
                    old_key     : data.data.key?data.data.key:data.data.Key,
                    temp_key    : info.id,
                    key         : data.data.key?data.data.key:data.data.Key,
                    size        : size.toString(),
                    extension   : extension,
                    uploadedBy  : info.user.first_name + ' ' + info.user.last_name,
                    uploadedAt  : '2023.01.01',
                });
            }catch(ex){
                console.log("Error doc",ex);
            }

           
        },
        removePanelistDocumentNew() {
            this.setPanelistDocumentNew(null)
        },

        //===================New Panelist document end================//

        updateDocuments(info, data) {
            const extension = data.data.key?data.data.key.split('.').pop():data.data.Key.split('.').pop();
            const key   = _.get(this.item.documents, 'key', null);
            const size  = _.get(info, 'file.size', 0);
           
           if(size>maxPdfFileSize){
                this.showCbError();
                return;
            }

            this.setDocuments({
                old_key     :  data.data.key?data.data.key:data.data.Key,
                temp_key    : info.id,
                key         : data.data.key?data.data.key:data.data.Key,
                size        : size.toString(),
                extension   : extension,
                uploadedBy  : info.user.first_name + ' ' + info.user.last_name,
                uploadedAt  : '2023.01.01',
            });
        },
        removeDocument() {
            this.setDocuments(null)
        },

        updateIsLiveStream(e) {
            this.setIsLiveStream(e.target.checked)
        },
        updateStreamUrl(e) {
            this.setStreamUrl(e.target.value)
        },
        updateLogo(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.screen_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setLogo({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeLogo() {
            this.setLogo(null)
        },
        updateSessionTheme(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.session_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setSessionTheme({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeSessionTheme() {
            this.setSessionTheme(null)
        },
        updateIsBackgroundPicture(e) {
            this.setIsBackgroundPicture(e.target.checked)
        },
        updateBackgroundPicture(info, data) {
            console.log(info);
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.background_picture, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setBackgroundPicture({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removeBackgroundPicture(e) {
            this.setBackgroundPicture(null);
        },

        updateSpeakerPicutre(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }

            const extension = data.data.key.split('.').pop()
            const key = _.get(this.item.speaker_picutre, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setSpeakerPicutre({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },

        removeSpeakerPicutre(e) {
            this.setSpeakerPicutre(null);
        },

        updateBackgroundColor(e) {
            this.setBackgroundColor(e.target.value)
        },
        updateBackgroundStripesColor(e) {
            this.setBackgroundStripesColor(e.target.value)
        },
        updateStageColor1(e) {
            this.setStageColor1(e.target.value)
        },
        updateStageColor2(e) {
            this.setStageColor2(e.target.value)
        },
        updateBackgroundColorBottom(e) {
            this.setBackgroundColorBottom(e.target.value)
        },
        updateLeft1PillarColor(e) {
            this.setLeft1PillarColor(e.target.value)
        },

        updatePillarLeft1Logo1(info, data) {
            console.log(info, 'innnnnnfo', data)
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft1Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft1Logo1() {
            this.setPillarLeft1Logo1(null)
        },
        updatePillarLeft1Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft1Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft1Logo2() {
            this.setPillarLeft1Logo2(null)
        },
        updatePillarLeft1Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left1_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft1Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft1Logo3() {
            this.setPillarLeft1Logo3(null)
        },
        updateLeft2PillarColor(e) {
            this.setLeft2PillarColor(e.target.value)
        },
        updateLeft2PillarBottomColor(e) {
            this.setLeft2PillarBottomColor(e.target.value)
        },
        updatePillarLeft2Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left2_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft2Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft2Logo1() {
            this.setPillarLeft2Logo1(null)
        },
        updatePillarLeft2Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left2_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft2Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft2Logo2() {
            this.setPillarLeft2Logo2(null)
        },
        updatePillarLeft2Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_left2_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarLeft2Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarLeft2Logo3() {
            this.setPillarLeft2Logo3(null)
        },
        updateRight1PillarColor(e) {
            this.setRight1PillarColor(e.target.value)
        },
        updateRight1PillarBottomColor(e) {
            this.setRight1PillarBottomColor(e.target.value)
        },
        updatePillarRight1Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right1_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight1Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight1Logo1() {
            this.setPillarRight1Logo1(null)
        },
        updatePillarRight1Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right1_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight1Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight1Logo2() {
            this.setPillarRight1Logo2(null)
        },
        updatePillarRight1Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right1_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight1Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight1Logo3() {
            this.setPillarRight1Logo3(null)
        },
        updateRight2PillarColor(e) {
            this.setRight2PillarColor(e.target.value)
        },
        updateRight2PillarBottomColor(e) {
            this.setRight2PillarBottomColor(e.target.value)
        },
        updatePillarRight2Logo1(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right2_logo1, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight2Logo1({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight2Logo1() {
            this.setPillarRight2Logo1(null)
        },
        updatePillarRight2Logo2(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right2_logo2, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight2Logo2({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight2Logo2() {
            this.setPillarRight2Logo2(null)
        },
        updatePillarRight2Logo3(info, data) {
            if (!_.has(data, 'data.key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.pillar_right2_logo3, 'key', null);
            const size = _.get(info, 'file.size', 0);

            this.setPillarRight2Logo3({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2023.01.01',
            });
        },
        removePillarRight2Logo3() {
            this.setPillarRight2Logo3(null)
        },

        updateInterprefy(e) {
            this.interprefy_disabled = true;
            if (e.target.checked) {
                this.interprefy_disabled = false;
                this.setAwsInterpretation(false)
                this.aws_interprefy_disabled =true;
                this.setAwsEnableSpeechToSpeech(false);
                this.setAwsEnableSpeechToText(false);
            }
            this.setInterprefy(e.target.checked);
        },

        updateInterprefyToken(e) {
            this.setInterprefyToken(e.target.value);
        },

        updateAwsInterpretation(e) {

            this.setInterprefy(false); //
            this.interprefy_disabled = true; //

            this.aws_interprefy_disabled = true;
            if (e.target.checked) {
                this.aws_interprefy_disabled = false;
            }

            this.setAwsInterpretation(e.target.checked);
        },
        updateAwsEnableSpeechToSpeech(e) {
            this.setAwsEnableSpeechToSpeech(e.target.checked);
        },
        updateAwsEnableSpeechToText(e) {
            this.setAwsEnableSpeechToText(e.target.checked);
        },
        updateChat(e) {
            this.setChat(e.target.checked);
        },

        updateIs_open(e) {
            this.setIs_open(e.target.checked);
        },

        updatesurvey_title(e) {
            this.setSurveytitle(e.target.value);
        },

        async checkLanguageValidation() {
            let errorsArr = [];
            _.map(this.languages, (item) => {
                let langInfo = _.find(this.item.translations, { 'key': item });
                if (langInfo) {
                    if(langInfo.title == null){
                        errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    }
                    if(langInfo.description == null){
                        errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                    }
                    if (langInfo.title!==null && langInfo.title.length < 3) {
                        errorsArr.push(`<li>${item}.title atleast 3 character long.</li>`)
                    }
                    if (langInfo.description!==null && langInfo.description.length < 10) {
                        errorsArr.push(`<li>${item}.description atleast 10 character long.</li>`)
                    }

                } else {
                    errorsArr.push(`<li>${item}.title can not be empty.</li>`)
                    errorsArr.push(`<li>${item}.description can not be empty.</li>`)
                }

            });
            return errorsArr;
        },
        showCbError() {
            this.$flashMessage.show({
                group   : 'panel_agenda_edit',
                type    : 'error',
                title   : 'Failed',
                time    : 1000,
                html    : "<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>"
            });
            },

        async submitForm() {
            const isFormCorrect = await this.v$.$validate();
            let validateTranlation = await this.checkLanguageValidation();
            this.error_general_tab = false;
            this.error_translation_tab = false;
            this.error_theme_tab = false;
            this.error_interpretation_tab = false;

            let generalError = []; 
            _.map(this.v$.$errors, (item) => {
              if(item.$property == "moderator"){
                generalError.push("<li>Moderator is required</li>");
              }
              if(item.$property == "start_time"){
                generalError.push("<li>Start Time is required</li>");
              }
              if(item.$property == "end_time"){
                generalError.push("<li>End Time is required</li>");
              }
              if(item.$property == "screen_logo"){
                generalError.push("<li>Screen Logo is required</li>");
              }
                
            });
            if(!_.isEmpty(validateTranlation) && !_.isEmpty(generalError)){
                this.error_general_tab = true;
                this.error_translation_tab = true;
                
                this.setErrorTranslationTab(true);
                this.setErrorGeneralTab(true);
                
            }
            if (!isFormCorrect || !_.isEmpty(validateTranlation) ) {
           
                if (!_.isEmpty(validateTranlation)) {
            
                    this.error_translation_tab = true;
                    this.setErrorTranslationTab(true);
                    this.$flashMessage.show({
                        group  : 'panel_agenda_edit',
                        type   : 'error',
                        time   : 1000,
                        title  : 'Failed',
                        html   : `<ul>${validateTranlation.join("")}</ul>`
                    });
                        return ;
                    }          
            } 

            if (!isFormCorrect ) {
                if (!_.isEmpty(generalError)) {
                this.error_general_tab = true;
                    this.setErrorGeneralTab(true);
                    this.$flashMessage.show({
                        group   : 'panel_agenda_edit',
                        type    : 'error',
                        time    : 1000,
                        title   : 'Validation Failed',
                        html    : `<ul>${generalError.join("")}</ul>`
                    });
                    return ;
                }
            }


            
            this.updateData(this.agenda_id)
                .then(async () => {

                    this.$flashMessage.show({
                        group   : 'panel',
                        type    : 'success',
                        title   : 'Success',
                        time    : 1000,
                        html    :'<ul><li>Panel Discussion has been updated successfully</li></ul>'

                    });

                    this.resetState();
                    //await this.fetchData(this.agenda_id);
                    this.fetchAllData(this.$route.params.event_id);
                

                    //await this.fetchEventUsersAll(this.$route.params.event_id);
                    //this.resetState();
                    this.dialog = false;
                })
                .catch((error) => {
                    console.error("Error update panel discussion", error)
                    let html = gettingErrorMessage(error);

                    this.$flashMessage.show({
                        group: 'panel_agenda_edit',
                        type: 'error',
                        title: 'Error',
                        text: html || 'Something went wrong.',
                        html: html
                    });
                })
        },

        async submitPanelistForm() {

            const isFormCorrect = await this.v$.$validate()

            // this.error_general_tab = false;
            //this.error_translation_tab = false;
            // this.error_theme_tab = false;
            // this.error_interpretation_tab = false;

            if (!isFormCorrect) {
                _.map(this.v$.$errors, (item) => {
                    if (item.$property.indexOf(['moderator', 'presentation_order'])) {
                        this.error_general_tab = true;
                    }
                });

            }

            this.setPanelist();

            let multidata = {
                agenda_id: this.agenda_id,
                eventid: this.$route.params.event_id
            };
            this.updatePanelistData(multidata)
                .then(async () => {

                    this.$flashMessage.show({
                        group   : 'panel',
                        type    : 'success',
                        title   : 'Success',
                        time    : 1200,
                        html    : '<ul><li>Panelist has been updated successfully</li></ul>'
                    });
                    this.resetState();
                    this.dialog = false;
                })
                .catch((error) => {
                    let html = gettingErrorMessage(error);

                    this.$flashMessage.show({
                        group: 'panel',
                        type: 'error',
                        title: 'Error',
                        text: html || 'Something went wrong.',
                        html: html
                    });
                })
        },

        async submitNewPanelistForm() {

            const isFormCorrect = await this.v$.$validate()

        
            if (!isFormCorrect) {
                _.map(this.v$.$errors, (item) => {
                    if (item.$property.indexOf(['moderator', 'presentation_order'])) {
                        this.error_general_tab = true;
                    }
                });

            }

            let multidata = {
                agenda_id: this.agenda_id,
                eventid: this.$route.params.event_id
            };
            this.storeNewPanelistData(multidata)
                .then(async () => {

                    this.$flashMessage.show({
                        group   : 'panel',
                        type    : 'success',
                        title   : 'Success',
                        time    : 1000,
                        html    : '<ul><li>Panelist has been updated successfully.</li></ul>'
                    });

                    await this.fetchpanelistdata(this.agenda_id);
                    this.resetState();
                })
                .catch((error) => {
                    let html = gettingErrorMessage(error);

                    this.$flashMessage.show({
                        group: 'panel',
                        type: 'error',
                        title: 'Error',
                        text: html || 'Something went wrong.',
                        html: html
                    });
                })
        },

        fileLoad(e) {
            const file = e.target.files[0];
            const id = uuidv4();
            upload(file, id, "users", null, file.type)
                .then((resp) => {
                    console.log(resp)

                })
                .catch((e) => {
                    console.error(e)
                    this.$flashMessage.show({
                        group: 'live_presentation_edit',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong.'
                    });
                })
        },

        updateLeft1PillarHeaderColor(e) {
            this.setLeft1PillarHeaderColor(e.target.value);
        },

        validate_file(fileInfo) {
            if (_.has(fileInfo, 'key'))
                return fileInfo;
            return null;
        },
        getDatetime(datetime) {
           // return moment.utc(datetime).tz(this.eventDetail.time_zone).format('YYYY-MM-DD HH:mm:ss')
            return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
    
     
        },
        updateTimeZone() {
            this.setTimeZone(this.event_timezone)
        },
        getEventDatetime(datetime) {
            return moment(datetime).format('YYYY-MM-DD HH:mm');
        }
    }
}
</script>

<style lang="scss" scoped>
.labelwrapper {
    display: flex;
    justify-content: space-around;
}

.labelwrapper label {
    display: block;
}

.toggle.active i.fa-minus {
    display: none !important;
}

.toggle>label {
    background: #fff !important;
}

.fas .fa-minus {
    display: none !important;
}

.v-dialog>.v-overlay__content>.v-card {
    border-radius: 15px;
    padding-top: 20px;
}

html .toggle-primary .toggle.active>label .labelwrapper span label {
    color: #ffffff !important;
}

.question_wrapper {
    background: #efefef;
    padding: 20px;
    margin-bottom: 15px;
    position: relative;
}

.question {
    font-weight: bold;
    margin-bottom: 10px;
}

.question_action {
    position: absolute;
    top: 50%;
    right: 50px;
}

.question_edit {
    background: #1896e3;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 5px;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;
}

.question_delete {
    background: #fff;
    color: #5a5757;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 1px 2px 0px #999;
    cursor: pointer;

}

.newbtn {
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-right: 5px;
}

.form-control div {
    border: 0px !important;
}

.vs__dropdown-toggle {
    border: 0px !important;
}

.surveytitlehd {
    background: #0088CC;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
}

.surveytitlehd label {
    color: #fff !important;
    font-weight: bold
}

.surveytitlehd span {
    margin-left: 20px;
}

.dark{
  --popper-theme-text-color: #ffffff;
  --popper-theme-padding: 15px;
  --popper-theme-background-color: #333333;
  --popper-theme-border-radius: 6px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img{    
    position: absolute;
    top: -53px;
    left: 50px;
    width: 300px;
    height: auto;
}
</style>
