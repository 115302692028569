<template>
 
    <div class="tabs">
        <ul class="nav nav-tabs nav-justified">
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#survey`" :href="`#survey`" data-bs-toggle="tab">Survey        
             </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :data-bs-target="`#survey_report`" :href="`#survey_report`" data-bs-toggle="tab">Survey Report</a>
            </li>
        </ul>
    
        <div class="tab-content">
    
            <div :id="`survey`" class="tab-pane active">
                <div class="snippet general-event-config-snippet">
                    <div class="survey_form" v-if="newSurvey===true || setIsSurveyEdit===true">
    
                        <div class="row">
                            <div class="col-lg-12 mb-3">
                                <label>Title *</label>
                                <div class="kt-input-icon">
                                    <input type="text" class="form-control" :value="surveyitems.title" name="title" maxlength="200" placeholder="Title" @input="updatesurvey_title" />
    
                                </div>
                                <ValidationMessages :element="v$.surveyitems.title" />
                            </div>
    
                            <div class="col-lg-9 mb-3">
                                <label>Result public?</label>
                                <br />
                                <div class="switch switch-sm switch-primary">
                                    <input-switch name="is_open" :value="surveyitems.is_open" id="customSwitchesEx" class="custom-control-input" @change="updateIs_open" />
                                </div>
                            </div>
    
                            <div class="col-lg-3 text-right">
                                <button v-if="newSurvey===true" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitSurveyTitleForm()">Create Survey</button>
                                <button v-else type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="udpateSurveyTitleForm(surveyitems._id)">Save</button>
                                <button v-if= "isSurveyNew ==false" type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelSurveyEdit">Cancel</button>
                                <button v-if= "isSurveyNew ==false" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteSurvey(surveyitems._id)">Delete</button>
                            </div>
                        </div>
                    </div>
    
                    <hr  />
                    <div  v-if= "isSurveyNew ===false">
                        <button class="newbtn" @click="createQuestionClick">Add Question</button>
    
                        <!-- <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(item)">Delete</button> -->
                    </div>
    
                    <div v-show="is_new_question == false"  v-if= "isSurveyNew ==false">
    
                        <div class="col-lg-12 mb-3 surveytitlehd">
                            <label>Title: </label>
                            {{ surveyitems.title }}
    
                            <span href="" class="" @click="showSurveyEditform"><i data-v-484bfbf9="" class="fas fa-pen" style=""></i></span>
    
                        </div>
    
                        <!-- {{ surveyitems }} -->
    
                        <!-- {{is_survey_editing}} -->
    
                        <div v-if="datanum <1">
    
                            <div class="question_wrapper" v-for="(survey, index) in surveyitems.questions_detail" :key="index">
                                <!-- v-show="survey._id !== edit_id" -->
                                <div v-show="survey._id !== edit_id || surveytemp ==true ||  is_survey_editing === false">
                                    <div class="question">Question: {{ survey.question_title }}</div>
    
                                    <ul v-if="survey.question_type!='textbox'">
                                        <li v-for="(ans, index2) in survey.answers" :key="index2">
                                            <span v-if="ans.answer_title!='textbox'">Option {{ index2+1 }}: {{ ans.answer_title }}</span>
                                            <span v-else>Question type is text.</span>
                                        </li>
                                    </ul>
    
                                    <ul v-else>
                                        <li>
                                            <span>Question type is text.</span>
                                        </li>
                                    </ul>
    
                                    <div class="question_action" v-show=" is_survey_editing === false">
                                        <span href="" class="question_edit" v-on:click="editSurvey(survey._id,survey)">Edit</span>
                                        <span href="" class="question_delete" v-on:click="deleteData(surveyitems._id,survey._id)">Delete</span>
                                    </div>
                                </div>
                                <SurveyForm :agenda_id="this.agenda_id" v-show="is_survey_editing === true && survey._id === edit_id" :surveyid=surveyitems._id :questionid=survey._id></SurveyForm>
                                <!-- <SurveyShow :panelsurvey="survey" :id="survey._id"></SurveyShow> -->
                            </div>
                        </div>
    
                        <!--  After New Question  -->
    
                        <div v-else>
                            <div class="question_wrapper" v-for="(survey, index) in sitems.data.questions_detail" :key="index">
                                <!-- v-show="survey._id !== edit_id" -->
                                <div v-show="survey._id !== edit_id || surveytemp ==true">
                                    <div class="question">Question: {{ survey.question_title }}</div>
                                    <ul>
                                        <li v-for="(ans, index2) in survey.answers" :key="index2">
                                            <span v-if="ans.answer_title!='textbox'">Option {{ index2+1 }}: {{ ans.answer_title }}</span>
                                            <span v-else>Question type is text.</span>
                                        </li>
                                    </ul>
                                    <div class="question_action" v-show=" is_survey_editing === false">
                                        <span href="" class="question_edit" v-on:click="editSurvey(survey._id,survey)">Edit</span>
                                        <span href="" class="question_delete" v-on:click="deleteData(surveyitems._id,survey._id)">Delete</span>
                                    </div>
                                </div>
                                <SurveyForm :agenda_id="this.agenda_id" v-show="is_survey_editing === true && survey._id === edit_id" :surveyid=surveyitems._id :questionid=survey._id></SurveyForm>
                                <!-- <SurveyShow :panelsurvey="survey" :id="survey._id"></SurveyShow> -->
                            </div>
                        </div>
    
                    </div>
    
                    <!-- <SurveyCreate v-show="is_survey_editing != true && is_new_question == true" :surveyid=surveyitems._id></SurveyCreate> -->
                    <SurveyQuestionCreate v-if="is_new_question && questionstatus===true" :surveyid=surveyitems._id :agenda_id="this.agenda_id"></SurveyQuestionCreate>
    
    
    
                </div>
            </div>
    
            <div :id="`survey_report`" class="tab-pane">
                <div class="snippet general-event-config-snippet">
                    <SurveyReport :agenda_id="this.agenda_id" :surveyId="surveyitems._id" :surveyNameFile="this.surveyNameFile"></SurveyReport>
    
                </div>
            </div>
    
        </div>
    
    </div>
        <FlashMessage group="survey_index"  />
    </template>
    <script>
import { v4 as uuidv4 } from 'uuid';
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    
    
    import _ from "lodash";
    import InputSwitch from "@/components/Commons/InputSwitch";
    import ObjectID from 'bson-objectid';
    import SurveyForm from "@/components/Commons/SurveyForm";
    import SurveyQuestionCreate from "@/components/Commons/SurveyQuestionCreate";
    import SurveyReport from "@/components/Commons/SurveyReport";
    
    import {
        gettingErrorMessage
    } from "@/services/GetValidationMessage";
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    
    export default {
        name: 'SurveyIndex',
        data() {
            return {
                moduleId: ObjectID(),
                dialog: false,
                questionList: true,
                setIsSurveyEdit: false,
    
            }
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        validations() {
            return {
                surveyitems: {
                    title: {
                        required,
                    }
                }
               
            };
        },
    
        props: {
            agenda_id:null,
            surveyNameFile:null
    
            
        },
        watch: {
    
        },
        components: {
            InputSwitch,
            SurveyForm,
            SurveyQuestionCreate,
            SurveyReport,
        },
        computed: {
    
            ...mapGetters('SurveysIndex', ['surveyitems', 'newSurvey','isSurveyNew']),
            ...mapGetters('SurveysSingle', [
                'sitems',
                'surveytemp',
                'is_survey_editing',
                'is_new_question',
                'datanum',
                'questionstatus',
            ]),
    
          
            
        },
        beforeUnmount() {
            this.resetState()
            this.indexResetState();
            delete this.$flashMessage.groups.survey_index;
        },
        created() {
            this.fetchsurveydata(this.agenda_id); //questions data   
      
        },
        mounted() {
        },
        methods: {
    
            ...mapActions('SurveysIndex', {
                fetchsurveydata :   "fetchSurveyData",
                indexResetState :   "resetState"
            }), //questions data
    
            ...mapActions('SurveysIndex', ["setIs_open", "setSurveytitle", "updateSurveytitleData", "storeSurveyData", "destroySurveyData", "setNewSurvey"]),
    
            ...mapActions('SurveysSingle', [
                "setItem",
                "destroyData",
                "setIsSubEditing",
                "setIsNewQuestion",
                "setSurveytemp",
                "setDataNum",
                'resetState'
    
            ]),
            
            updateIs_open(e) {
                this.setIs_open(e.target.checked);
            },
    
            updatesurvey_title(e) {
                this.setSurveytitle(e.target.value);
            },
    
            async submitSurveyTitleForm() {
                console.log("this.agenda_id",this.agenda_id);
                const isFormCorrect = await this.v$.$validate();
    
                if (!isFormCorrect) {
    
                    _.map(this.v$.$errors, (item) => {
                        if (item.$property.indexOf(['title'])) {
                            this.error_general_tab = true;
                        }
                    });
    
                }
                this.storeSurveyData(this.agenda_id)
                    .then(async (resp) => {
                        this.$flashMessage
                            .show({
                                id: uuidv4(),
                                group   : 'survey_index',
                                type    : 'success',
                                title   : 'Success',
                                time    : 800,
                                html    : '<ul><li>Survey created successfully.</li></ul>',
                            });
    
                        this.setIsSurveyEdit = false
                        this.setNewSurvey(false)
                        await this.fetchsurveydata(this.agenda_id);
                    }).catch(error => {
                        let html = gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                id: uuidv4(),
                                group   : 'survey_index',
                                type    : 'error',
                                title   : 'Failed',
                                time    : 1000,
                                text    : html || 'Something went wrong',
                                html    : html
                            });
                        }
    
                    });
            },
    
            createQuestionClick() {
                this.setIsNewQuestion(true);
                this.setSurveytemp(false);
                this.questionList = false;
            },
    
            editSurvey(item, survey) {
    
                // console.log(survey,'333333333'); 
                this.edit_id = item;
                this.setIsSubEditing(true);
                this.questionList = false;
                this.updateSItems(survey);
    
            },
            showSurveyEditform() {
                this.setIsSurveyEdit = true;
            },
    
            updateSItems(items) {
                this.setItem(items);
    
            },
    
            async udpateSurveyTitleForm(survey_id) {
    
                const isFormCorrect = await this.v$.$validate();
    
                if (!isFormCorrect) {
    
                    _.map(this.v$.$errors, (item) => {
                        if (item.$property.indexOf(['title'])) {
                            // this.error_general_tab = true;
                        }
                    });
    
                }
    
                let multidatasur = {
                    module_id: this.agenda_id,
                    survey_id: survey_id
                };
                this.updateSurveytitleData(multidatasur)
                    .then(async () => {
                        this.$flashMessage
                            .show({
                                id: uuidv4(),
                                group   : 'survey_index',
                                type    : 'success',
                                title   : 'Success',
                                time    : 800,
                                html    : '<ul><li>Survey updated successfully.</li></ul>',
                            });
    
                        this.setIsSurveyEdit = false
                        await this.fetchsurveydata(this.agenda_id);
                    }).catch(error => {
                        let html = gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                id: uuidv4(),
                                group: 'survey_index',
                                type: 'error',
                                title: 'Failed',
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
                        //this.setIsSurveyEdit = false
    
                    });
            },
    
            deleteSurvey(item) {
                this.$swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#dd4b39",
                    focusCancel: true,
                    reverseButtons: true
                }).then(result => {
                    if (result.isConfirmed) {
                        this.destroySurveyData(item).then(() => {
    
                            this.$flashMessage.show({
                                group: 'survey_index',
                                type: 'success',
                                title: 'Success',
                                time:1000,
                                html: '<ul><li>Survey has been deleted successfully.</li></ul>'
                            });
                            this.fetchsurveydata(this.agenda_id);
                            this.setIsSurveyEdit = false
                            this.setNewSurvey(true);
    
                        })
                    }
                });
            },
    
    
            async deleteData(surid, qid) {
                this.$swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#dd4b39",
                    focusCancel: true,
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const data = {
                            surveyid: surid,
                            questionid: qid,
                        }
                        await  this.destroyData(data).then(async() => {
    
                            this.$flashMessage.show({
                                group   : 'survey_index',
                                type    : 'success',
                                title   : 'Success',
                                time    : 1000,
                                html    : '<ul><li>Survey question deleted successfully.</li></ul>'
                            });
                          this.fetchsurveydata(this.agenda_id);
                            
                           // this.setDataNum(0);
                           
                        })
                    }
                });
            },
            cancelSurveyEdit(){
                this.setIsSurveyEdit = false
                this.setNewSurvey(false);
                this.fetchsurveydata(this.agenda_id); //questions data   
            }
    
        },
    
    }
    </script>
    <style lang="scss" scoped>
    .event_detail_wrapper {
        border-bottom: 0px solid #ccc;
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    }
    
    .setting_wrapper {
        float: right;
    
        a {
            color: #201e1eba;
            padding: 9px;
            margin-left: 5px;
            background: #c0cdd345;
            border-radius: 4px;
            border: 1px solid #80808054;
        }
    }
    
    .toggle label {
        color: #000 !important;
    }
    
    .drop-files {
        margin-top: 10px;
    }
    
    .newspeaker {
        margin-top: 20px;
    }
    
    .newspeaker button {
        width: 100%;
    }
    
    .add_spk_btn {
        background-color: #0088CC;
        color: #fff;
    }
    
    .mini_txt {
        font-size: 0.8em;
        color: #585252;
        font-style: italic;
        margin-bottom: 10px;
    }
    
    .v-dialog>.v-overlay__content>.v-card {
        border-radius: 15px;
        padding-top: 20px;
    }
    
    .tabs-left .nav-tabs>li .active {
        border-left-color: #0088CC;
    }
    
    .orangebtn {
        background-color: orange;
    }
    
    .wrapp {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    
    .dark {
        --popper-theme-text-color: #ffffff;
        --popper-theme-padding: 15px;
        --popper-theme-background-color: #333333;
        --popper-theme-border-radius: 6px;
        --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    }
    
    .tooltip_img img {
        position: absolute;
        top: -53px;
        left: 50px;
        width: 300px;
        height: auto;
    }
    
    .surveytitlehd {
        background: #0088CC;
        color: #fff;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 10px;
    }
    
    .surveytitlehd label {
        color: #fff !important;
        font-weight: bold
    }
    
    .surveytitlehd span {
        margin-left: 20px;
    }
    
    .question_wrapper {
        background: #efefef;
        padding: 20px;
        margin-bottom: 15px;
        position: relative;
    }
    
    .question {
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .question_action {
        position: absolute;
        top: 50%;
        right: 50px;
    }
    
    .question_edit {
        background: #1896e3;
        color: #fff;
        padding: 8px 20px;
        border-radius: 5px;
        text-decoration: none;
        margin-right: 5px;
        box-shadow: 0px 1px 2px 0px #999;
        cursor: pointer;
    }
    
    .question_delete {
        background: #fff;
        color: #5a5757;
        padding: 8px 20px;
        border-radius: 5px;
        text-decoration: none;
        box-shadow: 0px 1px 2px 0px #999;
        cursor: pointer;
    
    }
    
    .newbtn {
        border: 1px solid #cccccc;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-right: 5px;
    }
    </style>
    