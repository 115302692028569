<template>
  <div class="snippet event-subscription-config-snippet">
    <div class="form-group row">
      <div class="col-lg-6">
        <label>No. of Meeting Session</label>
        <div class="kt-input-icon">
          <input
              type="number"
              name="add_virtual_meeting"
              @input="updateAddVirtualMeeting"
              :value="item.add_virtual_meeting"
              placeholder="Enter No. of Meeting Sessions"
              class="form-control"
              min="0"
          />
        </div>
      </div>

      <div class="col-lg-6">
        <label for="add_training_session">No. of Panel Discussions</label>
        <div class="kt-input-icon">
          <input name="add_panel_discussions"
                 @input="updateAddPanelDiscussions"
                 :value="item.add_panel_discussions"
                 placeholder="Enter No. of Panel Discussions"
                 class="form-control"
                 type="number"
                 min="0"
          />
        </div>
      </div>

      <!--  <div class="col-lg-6">
          <label for="add_booths">No. of Booths</label>
          <div class="kt-input-icon">
            <input
                name="add_booths"
                type="number"
                @input="updateAddBooths"
                :value="item.add_booths"
                placeholder="Enter No. of Booths"
                class="form-control"
            />
          </div>
        </div>-->
    </div>
    <div class="form-group row">
      <div class="col-lg-6">
        <label class>No. of Virtual Roundtable Session</label>
        <input name="add_roundtable_session"
            type="number"
            @input="updateAddRoundtableSessions"
            :value="item.add_roundtable_session"
            placeholder="Enter No. of Roundtable Sessions"
            class="form-control"
            min="0"
        />
      </div>
      <div class="col-lg-6">
        <label class>No. of Live Presentation</label>
        <input
            type="number"
            name="add_presentations"
            @input="updateAddPresentationsLive"
            :value="item.add_presentations"
            placeholder="Enter No. of Live Presentations"
            class="form-control"
            min="0"
        />
      </div>
    </div>
    <div class="form-group row">
      <!--
      <div class="col-lg-6">
        <label class>No. of Webcasts</label>
        <input name="add_webcast"
               type="number"
               @input="updateAddWebcast"
               :value="item.add_webcast"
               placeholder="Enter No. of Webcasts"
               class="form-control"
        />
      </div>
      -->
      <div class="col-lg-6">
        <label class>No. of Presentation On-Demand</label>
        <input name="add_presentations_on_demand"
               @input="updateAddPresentationsOnDemand"
               :value="item.add_presentations_on_demand"
               placeholder="Enter No. of Presentations On-Demand"
               class="form-control"
               type="number"
               min="0"
        />
      </div>


      <div class="event_detail_wrapper">
          <div class="form-group form-inline">
            <div class="col-md-2">
              <label for="address">Select No. of Booth Theme  *</label>
            </div>
              <div class="form-group preset_wrapper row" v-for="(preset) in boothPresets" v-bind:key="preset" v-bind:preset="preset">
                    <div class="radio col-lg-4" >
                      <div>

                        <label class= "lphoto">
                          <input
                              type="checkbox"
                              name="preset_id"
                              :checked="getSelectedPresetId(preset.id)"
                              @click="updatePreset($event,preset.id)"
                          >
                          {{ preset.name }}:
                          <img v-if="preset.preset_image" class="preset_images" disabled width="200" :src="IMAGE_URL + preset.preset_image.key"/>
                        </label>
                        <label class="lphoto-name">{{ preset_name }}</label>

                        
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="kt-input-icon quan">
                        <input 
                          @input="updateNoOfBooth($event,preset.id)"
                          type = "number"
                          placeholder="Enter No. of Booth"
                          name="no_of_booths"
                          class="form-control"
                          :value="getupdatedNoOfBooths(preset.id)"
                          min="0"
                          max = "10"
                         />
                      </div>
                  </div>
                  
                </div><!--End of fomr inline-->

              


          </div>
        </div>

    </div>
    <div class="form-group row">
      <div class="col-lg-4">
        <label>Analytics &amp; Reporting</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_analytics"
                        :value="item.event_analytics"
                        id="customSwitches2"
                        class="custom-control-input"
                        @change="updateEventAnalytics" />
          <label
              class="custom-control-label"
              for="customSwitches2"
          >{{item.event_analytics ? "Yes" : "No"}}</label>
        </div>
      </div>
      <div class="col-lg-4">
        <label>Content Creation &amp; Setup Support</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_setups"
                        :value="item.event_setups"
                        id="customSwitches3"
                        class="custom-control-input"
                        @change="updateEventSetups" />
          <label
              class="custom-control-label"
              for="customSwitches3"
          >{{item.event_setups ? "Yes" : "No"}}</label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-4">
        <label>Event Games</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_theme"
                        :value="item.event_games"
                        id="event_games"
                        class="custom-control-input"
                        @change="updateEventGames" />
          <label
              class="custom-control-label"
              for="event_games"
          >{{item.event_games ? "Yes" : "No"}}</label>
        </div>
      </div>
      <div class="col-lg-4">
        <label>Event Questions</label>
        <br/>
        <div class="switch switch-sm switch-primary">
          <input-switch name="event_questions"
                        :value="item.event_questions"
                        id="event_questions"
                        class="custom-control-input"
                        @change="updateEventQuestions" />
          <label
              class="custom-control-label"
              for="event_questions"
          >{{item.event_questions ? "Yes" : "No"}}</label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-4">
            <div class="switch switch-sm switch-primary">
              <input-switch name="interprefy_option"
                            :value="item.is_interprefy_interpretation_active"
                            id="interprefy_option"
                            class="custom-control-input"
                            @change="changeInterprefyOption" />
              <label class="custom-control-label" for="interprefy_option">Interprefy Interpretation</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="switch switch-sm switch-primary">
              <input-switch name="aws_option"
                            :value="item.is_aws_interpretation_active"
                            id="aws_option"
                            class="custom-control-input"
                            @change="changeAwsOption" />
              <label class="custom-control-label" for="aws_option">Aws Interpretation</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="switch switch-sm switch-primary">
              <input-switch name="aws_chat_option"
                            :value="item.is_chat_interpretation_active"
                            id="aws_chat_option"
                            class="custom-control-input"
                            @change="changeChatOption" />
              <label class="custom-control-label" for="aws_chat_option">Chat Interpretation</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import $ from 'jquery';
import InputSwitch from "@/components/Commons/InputSwitch";
import constants from "@/config/constants";
export default {
  name: "EventSubscriptionConfigSnippet",
  components: {
    InputSwitch
  },
  data(){
    return {
          IMAGE_URL: constants.IMAGE_URL,
          inputValue: 0,
          maxValue: 10, // initial max value
          selectedPresetId:null
    
    }
    },
    computed: {
    ...mapGetters("EventsSingle", [
      "item"
    ]),
    ...mapGetters('BoothsIndex', ['boothPresets']),
  },
  created(){
    this.fetchBoothPresetsAll({type:"event",eventId:0});
  },
  mounted() {
    const switchers = document.querySelectorAll('.event-subscription-config-snippet input[data-plugin-ios-switch]')
    switchers.forEach(item => {const elem = new window.theme.PluginIOS7Switch($(item)) })
  },
  methods: {
    ...mapActions('BoothsIndex', { fetchBoothPresetsAll:'fetchBoothPresetsAll'}),
    
    ...mapActions("EventsSingle", [
      "setAddVirtualMeeting",
      //"setAddBooths",
      "setAddRoundtableSessions",
      "setAddPresentationsLive",
      "setAddWebcast",
      "setAddPresentationsOnDemand",
      "setPanelDiscussions",
      "setEventAnalytics",
      "setEventSetups",
      "setEventGames",
      "setEventQuestions",
      "activateInterpretation",
      "setAwsInterpretationStatus",
      "setInterprefyInterpretationStatus",
      "setChatInterpretationStatus",
      "setPresets",
      "unSetPresets"
    ]),

    updateAddVirtualMeeting(e) {
      this.setAddVirtualMeeting(e.target.value);
    },
   // updateAddBooths(e) {
     // this.setAddBooths(e.target.value);
   // },
    updateAddRoundtableSessions(e) {
      this.setAddRoundtableSessions(e.target.value);
    },
    updateAddPresentationsLive(e) {
      this.setAddPresentationsLive(e.target.value);
    },
    updateAddWebcast(e) {
      this.setAddWebcast(e.target.value);
    },
    updateAddPresentationsOnDemand(e) {
      this.setAddPresentationsOnDemand(e.target.value);
    },
    updateAddPanelDiscussions(e) {
      this.setPanelDiscussions(e.target.value);
    },
    updateEventAnalytics(e) {
      this.setEventAnalytics(e.target.checked);
    },
    updateEventSetups(e) {
      this.setEventSetups(e.target.checked);
    },
    updateEventGames(e) {
      this.setEventGames(e.target.checked);
    },
    updateEventQuestions(e) {
      this.setEventQuestions(e.target.checked);
    },
    changeInterprefyOption(e) {
      this.setInterprefyInterpretationStatus(e.target.checked);
    },
    changeAwsOption(e) {
      this.setAwsInterpretationStatus(e.target.checked);
    },
    changeChatOption(e) {
      this.setChatInterpretationStatus(e.target.checked);
    },
    updatePreset(e,id){
      console.log(e.target.checked)
      if(e.target.checked== false){
        this.unSetPresets({id:id})
      }else{
        this.selectedPresetId = id;
      }

    },
    updateNoOfBooth(e,preset_id) {
        if (!_.isNull(e.target.value)) {
          if(e.target.value){
            this.setPresets({
            //id: this.selectedPresetId,
            id: preset_id,
            update_key: 'no_of_booths',
            update_value: e.target.value
            });
          }else{
            this.unSetPresets({id:preset_id})
          }
        }
    },
    
    getupdatedNoOfBooths(presetSelectedId) {
      const all_presets = _.find(this.item.presets, i => i.id == presetSelectedId);
      return _.get(all_presets, 'no_of_booths', '');
    },
    getSelectedPresetId(presetSelectedId){
      let selected_type = false;
      const all_presets = _.find(this.item.presets, i => i.id == presetSelectedId);
      if(all_presets){
        selected_type = true;
      }
      return selected_type;
    }
  }
}
</script>

<style scoped>
.preset_wrapper{
  position:relative;
  padding-top:0px;
}

.lphoto {
	border-radius: 6px;
	border: 1px solid #ccc;
	width: 200px;
	height: 100px;
	margin-top: 10px;
	margin-left: 10px;
	float: left;
	overflow: hidden;
	position: relative;
  margin:0 auto;
  text-align:center;
  min-height:240px;
}

.lphoto-name {
  position: absolute;
  bottom: 15px;
  width: 100%;
  padding: 5px 0;
  font-size: 30px;
  color: white;
  text-align: center;
  font-style: italic;
  font-family: sans-serif;
}

.quan{
  position:absolute;
  bottom:16px;
}
.preset_images{
  
  display:block;
  margin:0 auto;
  margin-top:5px;
}


</style>