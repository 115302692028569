<template>
  <div class="row">
    <div class="col-md-2">
      <div class="user_img">
        <img v-if="booths.presets" :src="IMAGE_URL + booths.presets.preset_image.key" alt="pic" />
      </div>
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Booth Name</div>
          <div class="col-md-8">{{booths.title}}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Exhibitor</div>
          <div class="col-md-8">{{booths.exhibitor_id.translations[0].name}}</div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Representative</div>
          <div class="col-md-8" v-if="booths.booth_representative_id">

            {{booths.booth_representative_id.first_name}} {{booths.booth_representative_id.last_name}}
          </div>
        </div>
      </div>
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Booth Location </div>
          <div class="col-md-8">{{booths.location}}</div>
        </div>
      </div>


  
    </div>
    <div class="col-md-5 event_detail_ls">
      <div class="event_detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Booth Color</div>
          <div class="col-md-8" ><span v-bind:style="{ backgroundColor: booths.booth_color }">{{booths.booth_color}}</span></div>

        </div>
      </div>
 </div>
    <div class="event_detail_ls text-right mt-3 mr-5">
      <button type="button" v-if="can('booth_edit',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(booths._id)">Edit</button>
      <button type="button" v-if="can('booth_delete',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="deleteData(booths._id)">Delete</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import constants from "@/config/constants";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "ShowItem",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  props: {
    booths: {
      type: Object,
      default() {
        return {
          title: null,
          location: null,
         
        }
      }
    },
    checkPermssions:null
  },
  methods: {

    ...mapActions('BoothsIndex', ['fetchData', 'setEditId']),
    ...mapActions('BoothsSingle', ['setIsNew', 'destroyData']),
    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.destroyData(id).then(() => {
            
            this.$flashMessage.show({
                        group : 'booths',
                        type  : 'success',
                        title : 'Success',
                        time  :1000,
                        html  : '<ul><li>Booth has been deleted successfully.</li></ul>'
                      });

            this.fetchData(this.$route.params.event_id);
            this.setIsNew(false);
            this.setEditId(false);
          })
        }
      });
    },
    editData(id) {
      this.setEditId(id);
      this.setIsNew(false);
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  },

}
</script>

<style scoped lang="scss">
.user_img{
  img {
    width: 100%;
  }
}

.event_detail_wrapper{
  padding:10px 0px;

  .event_ls_label{
    font-weight: bold;
  }

  .row{
    .col-md-8{
      border-bottom:1px dotted #ccc;
      padding-bottom:5px;
    }
    .col-md-3{
      border-bottom:1px dotted #ccc;
      padding-bottom:5px;
    }
  }

  .card-body{
    box-shadow: 0px 0px #ffffff;
  }
}
</style>