import timeZoness from 'countries-and-timezones'
import axios from "axios";
import constants from "@/config/constants";
import _ from "lodash";
import moment from "moment"
import 'moment-timezone';
import languages from "@/store/modules/Languages";

function initialState() {
    return {
        item: {
            title: '',
            company: '', 
            start_date: '',
            end_date: '', 
            is_restricted: false, 
            country: '',
            city: '', 
            time_zone: '', 
            description: '', 
            address: '', 
            login_hours: 0, // missing
            login_message: null,
            email: '',
            Phone: '',
            add_virtual_meeting: 0,
           // add_booths: 0, // missing
            add_roundtable_session: 0,
            add_presentations: 0,
            add_webcast: 0,
            add_presentations_on_demand: 0,
            add_panel_discussions: 0,
            event_domain: '',
            event_games: false,
            event_questions: false,
            interpretation_options: false,
            languages: [],
            website_logo: null,
            user_guide: null,
            background_picture: null,
            additional_logo: null,
            // privacy_url: '',// missing need to add it
            is_paid: false,
            // promo_code: '', //missing
            is_sandbox: false,
            paypal_app_id: null,
            paypal_production_app_id: null,
            search_exhibitor: false, 
            search_company: false, 
            search_attendee: false, 
            search_match_making: false, 
            screen_sharing: false, //missing
            is_rating: false,
            enable_map: false,
            meeting_with_attendee: false,
            primary_landing_color: '#000',
            primary_text_color: false,
            common_text_color: false,
            counter_text_color: false,
            footer_text_color: false,
            primary_color: '#000',
            theme_header_color: '#000',
            theme_footer_color: '#000',
            theme_background_color: '#000',
            theme_booth_box_color: '#000',
            theme_schedule_box_color: '#000',
            counter_background_color: '#000',
            common_color: '#000',
            register_button_color: '#000',
            menu_text_color: '#000',
            logo_background_color: '#000',
            status: true, // @TODO: need to clarify. How it should works
            event_analytics: false,
            event_setups: false,
            is_interprefy_interpretation_active: false,
            is_aws_interpretation_active: false,
            is_chat_interpretation_active: false,
            owners: [],
            presets:[],
        },
        countriesAll: [],
        timezonesAll: [],
        loading: false,
        event_domain_custom: null,
        domain_invalid: 0,
        userId:null,
        userEmail:null,
        roleTitle:null,
        roleId:null,


    }
}

const getters = {
    item: state => state.item,
    loading: state => state.loading,
    event_domain_custom: state => state.event_domain_custom,
    countriesAll: state => state.countriesAll,
    timezonesAll: state => state.timezonesAll,
}

const actions = {
    async storeData({commit, state, dispatch}) {

        commit('setLoading', true)
        commit('setUTCTime', 'start_date');
        commit('setUTCTime', 'end_date');


        const params = _.cloneDeep(state.item);

        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;

        /* @TODO: need to discuss next properties. Do we need it or not */
        delete params.is_deleted_by_admin;
        delete params.is_banned_by_admin;
        delete params.event_admins;
        delete params.live_presentations;
        delete params.event_users;
        delete params.event_hall_id;
        delete params.agenda_on_demand;
        console.log("This is params",state.item.languages)
        params.languages = _.map(state.item.languages, lang => {
            return {
                language_id : lang._id,
                translates  : lang.event_translates,
                email_text  : lang.email_text
            }
        })

        if (!_.has(params.website_logo, 'key')) {
            delete params.website_logo;
        }
        if (!_.has(params.user_guide, 'key')) {
            delete params.user_guide;
        }
        if (!_.has(params.background_picture, 'key')) {
            delete params.background_picture;
        }
        if (!_.has(params.additional_logo, 'key')) {
            delete params.additional_logo;
        }

        if (params.website_logo) {
            params.website_logo = {
                old_key: state.item.website_logo.key,
                temp_key:  state.item.website_logo.temp_key,
                size: state.item.website_logo.size,
                extension: state.item.website_logo.extension,
                uploadedBy: state.item.website_logo.uploadedBy,
                uploadedAt: state.item.website_logo.uploadedAt,
            }
        }

        if (params.user_guide) {
            params.user_guide = {
                old_key: state.item.user_guide.key,
                temp_key:  state.item.user_guide.temp_key,
                size: state.item.user_guide.size,
                extension: state.item.user_guide.extension,
                uploadedBy: state.item.user_guide.uploadedBy,
                uploadedAt: state.item.user_guide.uploadedAt,
            }
        }

        if (params.background_picture) {
            params.background_picture = {
                old_key: state.item.background_picture.key,
                temp_key:  state.item.background_picture.temp_key,
                size: state.item.background_picture.size,
                extension: state.item.background_picture.extension,
                uploadedBy: state.item.background_picture.uploadedBy,
                uploadedAt: state.item.background_picture.uploadedAt,
            }
        }

        if (params.additional_logo) {
            params.additional_logo = {
                old_key: state.item.additional_logo.key,
                temp_key:  state.item.additional_logo.temp_key,
                size: state.item.additional_logo.size,
                extension: state.item.additional_logo.extension,
                uploadedBy: state.item.additional_logo.uploadedBy,
                uploadedAt: state.item.additional_logo.uploadedAt,
            }
        }
           
        
            
      
          //  params.start_date   =  state.item.start_date
          //  params.end_date     =  state.item.end_date


        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.post(constants.API_URL + '/api/events', params, {headers: headers})
            .then((response) => {
                commit('resetState')
               return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    updateData({commit, state, dispatch}) {
        commit('setLoading', true)
        commit('setUTCTime', 'start_date');
        commit('setUTCTime', 'end_date');

        const params = _.cloneDeep(state.item);
        delete params._id;
        delete params.__v;
        delete params.createdAt;
        delete params.updatedAt;

        /* @TODO: need to discuss next properties. Do we need it or not */
        delete params.is_deleted_by_admin;
        delete params.is_banned_by_admin;
        delete params.event_admins;
        delete params.live_presentations;
        delete params.panel_discussions;
        delete params.booth;
        delete params.event_users;
        delete params.event_hall_id;
        delete params.agenda_on_demand;
        delete params.event_exhibition_hall_id;
        console.log("state.item.languages",state.item.languages);
        params.languages = _.map(state.item.languages, lang => {

            console.log("lang udpate********",lang);
            return {
                language_id : lang._id,
                translates  : lang.event_translates,
                //translates  : lang.translates,
                email_text  : lang.email_text
                
            }
        })

       /* if (!_.has(params.website_logo, 'key')) {
            delete params.website_logo;
        }
        if (!_.has(params.user_guide, 'key')) {
            delete params.user_guide;
        }
        if (!_.has(params.background_picture, 'key')) {
            delete params.background_picture;
        }
        if (!_.has(params.additional_logo, 'key')) {
            delete params.additional_logo;
        }*/

        if (params.website_logo) {
            params.website_logo = {
                key: state.item.website_logo.key,
                old_key: state.item.website_logo.key,
                size: state.item.website_logo.size,
                extension: state.item.website_logo.extension,
                uploadedBy: state.item.website_logo.uploadedBy,
                uploadedAt: state.item.website_logo.uploadedAt,
            }
        }
        if (params.user_guide) {
            params.user_guide = {
                key: state.item.user_guide.key,
                old_key: state.item.user_guide.key,
                size: state.item.user_guide.size,
                extension: state.item.user_guide.extension,
                uploadedBy: state.item.user_guide.uploadedBy,
                uploadedAt: state.item.user_guide.uploadedAt,
            }
        }
        if (params.background_picture) {
            params.background_picture = {
                key: state.item.background_picture.key,
                old_key: state.item.background_picture.key,
                size: state.item.background_picture.size,
                extension: state.item.background_picture.extension,
                uploadedBy: state.item.background_picture.uploadedBy,
                uploadedAt: state.item.background_picture.uploadedAt,
            }
        }
        if (params.additional_logo) {
            params.additional_logo = {
                key: state.item.additional_logo.key,
                old_key: state.item.additional_logo.key,
                size: state.item.additional_logo.size,
                extension: state.item.additional_logo.extension,
                uploadedBy: state.item.additional_logo.uploadedBy,
                uploadedAt: state.item.additional_logo.uploadedAt,
            }
        }

        
        
      //  params.start_date = state.item.start_date;
      //  params.end_date = state.item.end_date;

        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.patch(constants.API_URL + '/api/events/' + state.item._id, params, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data)
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchData({commit, dispatch}, id) {
        commit('setLoading', true);
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        };

        return axios.get(constants.API_URL + '/api/events/event-id/' + id, {headers: headers})
            .then(response => {
                commit('setItem', response.data.data);
                commit('setLanguages', _.reduce(response.data.data.languages, (acumulator, item) => {
                    console.log("item",item);
                    const lang = _.cloneDeep(item.language_id);
                    console.log("langlanglang",lang);
                    //lang.translates = item.event_translates;
                    lang.event_translates = item.translates;
                    //lang.translates = item.translates;
                    lang.email_text = item.email_text;
                    acumulator.push(lang);
                    return acumulator;
                }, []))
                dispatch('fetchCountriesAll')
                dispatch('fetchTimezonesAll')
                commit('setLocalTime', 'start_date')
                commit('setLocalTime', 'end_date')
                return response;
            })
            .finally(() => {
                commit('setLoading', false)
            })
    },
    fetchCountriesAll({ commit }) {
        const zones = timeZoness.getAllCountries();
        commit('setCountriesAll', zones)
    },
    fetchTimezonesAll({ commit }) {
        const zones = timeZoness.getAllTimezones();
        commit('setTimezonesAll', zones)
    },
    setEventDomainCustom({commit}, is_custom) {
        commit('setEventDomainCustom', !!is_custom);
    },
    setCompany({ dispatch, commit }, value) {
        commit('setCompany', value)
    },

    setTitle({ commit }, value) {
        commit('setTitle', value)
    },
    setPrivacyUrl({ commit }, value) {
        commit('setPrivacyUrl', value)
    },
    setPrimaryLandingColor({ commit }, value) {
        commit('setPrimaryLandingColor', value)
    },
    setPrimaryColor({ commit }, value) {
        commit('setPrimaryColor', value)
    },
    setThemeHeaderColor({ commit }, value) {
        commit('setThemeHeaderColor', value)
    },
    setThemeFooterColor({ commit }, value) {
        commit('setThemeFooterColor', value)
    },
    setThemeBackgroundColor({ commit }, value) {
        commit('setThemeBackgroundColor', value)
    },
    setThemeBoothBoxColor({ commit }, value) {
        commit('setThemeBoothBoxColor', value)
    },
    setScreenSharing({ commit }, value) {
        commit('setScreenSharing', value)
    },
    setEnableMap({ commit }, value) {
        commit('setEnableMap', value)
    },
    setMeetingWithAttendee({ commit }, value) {
        commit('setMeetingWithAttendee', value)
    },
    setAdditionalLogo({ commit }, value) {
        commit('setAdditionalLogo', value)
    },
    setSearchExhibitor({ commit }, value) {
        commit('setSearchExhibitor', value)
    },
    setSearchCompany({ commit }, value) {
        commit('setSearchCompany', value)
    },
    setSearchAttendee({ commit }, value) {
        commit('setSearchAttendee', value)
    },
    setSearchMatchMaking({ commit }, value) {
        commit('setSearchMatchMaking', value)
    },
    setThemeScheduleBoxColor({ commit }, value) {
        commit('setThemeScheduleBoxColor', value)
    },
    setLogoBackgroundColor({ commit }, value) {
        commit('setLogoBackgroundColor', value)
    },
    setMenuTextColor({ commit }, value) {
        commit('setMenuTextColor', value)
    },
    setCounterColor({ commit }, value) {
        commit('setCounterColor', value)
    },
    setCommonColor({ commit }, value) {
        commit('setCommonColor', value)
    },
    setRegisterButtonColor({ commit }, value) {
        commit('setRegisterButtonColor', value)
    },
    setTitleEdit({ commit }, value) {
        commit('setTitleEdit', value)
    },
    setCountry({ commit }, value) {
        commit('setCountry', value)
        if (typeof (value) === "string") {
            commit('fetchCountryTimezone', value)
        } else {
            commit('setTimezonesAll', value.timezones)
        }
    },
    setCity({ commit }, value) {
        commit('setCity', value)
    },
    setStartDate({ commit }, value) {
        commit('setStartDate', value)
    },
    setEventAnalytics({ commit }, value) {
        commit('setEventAnalytics', value)
    },
    setEndDate({ commit }, value) {
        commit('setEndDate', value)
    },
    setEventSetups({ commit }, value) {
        commit('setEventSetups', value)
    },
    setIs_paid({ commit }, value) {
        commit('setIs_paid', value)
    },
    setIs_restricted({ commit }, value) {
        commit('setIs_restricted', value)
    },
    setPrimaryTextColor({ commit }, value) {
        commit('setPrimaryTextColor', value)
    },
    setFooterTextColor({ commit }, value) {
        commit('setFooterTextColor', value)
    },
    setCounterTextColor({ commit }, value) {
        commit('setCounterTextColor', value)
    },
    setCommonTextColor({ commit }, value) {
        commit('setCommonTextColor', value)
    },
    setExternalListing({ commit }, value) {
        commit('setExternalListing', value)
    },
    setIs_sandbox({ commit }, value) {
        commit('setIs_sandbox', value)
    },
    setIs_live({ commit }, value) {
        commit('setIs_live', value)
    },
    setAmount({ commit }, value) {
        commit('setAmount', value)
    },
    setLoginHours({ commit }, value) {
        commit('setLoginHours', value)
    },
    setLoginMessage({ commit }, value) {
        commit('setLoginMessage', value)
    },
    setEventDomain({ commit }, value) {
        commit('setEventDomain', value)
    },
    setPromoCode({ commit }, value) {
        commit('setPromoCode', value)
    },
    setPayPalAppId({ commit }, value) {
        commit('setPayPalAppId', value)
    },
    setPayPalProductionAppId({ commit }, value) {
        commit('setPayPalProductionAppId', value)
    },
    setDescription({ commit }, value) {
        commit('setDescription', value)
    },
    setAddress({ commit }, value) {
        commit('setAddress', value)
    },
    setEmail({ commit }, value) {
        commit('setEmail', value)
    },
    setPhone({ commit }, value) {
        commit('setPhone', value)
    },
    setTheme({ commit }, value) {
        commit('setTheme', value)
    },
    setLanguages({ commit }, value) {
        commit('setLanguages', value)
    },
    setTimezone({ commit }, value) {
        commit('setTimezone', value)
    },
    setWebsiteLogo({ commit }, value) {
        commit('setWebsiteLogo', value)
    },
    setBackgroundPicture({ commit }, value) {
        commit('setBackgroundPicture', value)
    },
    setUserGuide({ commit }, value) {
        commit('setUserGuide', value)
    },
    setAddVirtualMeeting({ commit }, value) {
        commit('setAddVirtualMeeting', value)
    },
    setAddBooths({ commit }, value) {
        commit('setAddBooths', value)
    },
    setAddRoundtableSessions({ commit }, value) {
        commit('setAddRoundtableSessions', value)
    },
    setAddPresentationsLive({ commit }, value) {
        commit('setAddPresentationsLive', value)
    },
    setAddWebcast({ commit }, value) {
        commit('setAddWebcast', value)
    },
    setAddPresentationsOnDemand({ commit }, value) {
        commit('setAddPresentationsOnDemand', value)
    },
    setPanelDiscussions({ commit }, value) {
        commit('setPanelDiscussions', value)
    },
    setEventGames({ commit }, value) {
        commit('setEventGames', value)
    },
    setEventQuestions({ commit }, value) {
        commit('setEventQuestions', value)
    },
    activateInterpretation({ commit }, value) {
        commit('activateInterpretation', value)
    },
    setAwsInterpretationStatus({ commit }, value) {
        commit('setAwsInterpretationStatus', value)
    },
    setChatInterpretationStatus({ commit }, value) {
        commit('setChatInterpretationStatus', value)
    },
    setInterprefyInterpretationStatus({ commit }, value) {
        commit('setInterprefyInterpretationStatus', value)
    },
    setOwners({ commit }, value) {
        console.log("set owrn",value);
        //if(value){
        commit('setOwners', value);
        //}
        
    },
    resetState({ commit }) {
        commit('resetState')
    },
    setPresets({ commit }, value) {
        commit('setPresets', value)
    },
    unSetPresets({commit},value){
        commit('unSetPresets',value)
    },
    setUserId({ commit }, value) {
        commit('setUserId', value)
    },
    setUserEmail({ commit }, value) {
        commit('setUserEmail', value)
    },
    setRoleId({ commit }, value) {
        commit('setRoleId', value)
    },
    setRoleTitle({ commit }, value) {
        commit('setRoleTitle', value)
    },
    setOwners2({ commit }, value){
        commit('setOwners2', value)
    }   
}

const mutations = {
    setItem(state, item) {
        state.item = item
    },
    setEnableMap(state, value) {
        state.item.enable_map = value;
    },
    setMeetingWithAttendee(state, value) {
        state.item.meeting_with_attendee = value;
    },
    setSearchExhibitor(state, value) {
        state.item.search_exhibitor = value;
    },
    setAdditionalLogo(state, value) {
        state.item.additional_logo = value;
    },
    setSearchCompany(state, value) {
        state.item.search_company = value;
    },
    setSearchAttendee(state, value) {
        state.item.search_attendee = value;
    },
    setSearchMatchMaking(state, value) {
        state.item.search_match_making = value
    },
    setCompany(state, value) {
        state.item.company = value;
    },
    setLanguages(state, value) {
        console.log("VAlue*******",value);
        state.item.languages = value
    },
    setTimezone(state, value) {
        state.item.time_zone = value
    },
    setScreenSharing(state, value) {
        state.item.screen_sharing = value;
    },
    fetchCountryTimezone(state, country) {
        const zones = timeZoness.getAllCountries();
        const timezones = Object.values(zones)
        const timez = timezones.findIndex(x => x.name === country)
        const selected = timezones[timez]
        state.timezonesAll = selected.timezones
    },
    setCountriesAll(state, value) {
        const countries = Object.values(value)
        countries.sort((a, b) => (a.name > b.name) ? 1 : -1)
        state.countriesAll = countries
    },
    setTimezonesAll(state, value) {
        state.timezonesAll = Object.values(value)
    },
    setTitle(state, value) {
        state.item.title = value
    },
    setPrivacyUrl(state, value) {
        state.item.privacy_url = value
    },
    setPrimaryLandingColor(state, value) {
        state.item.primary_landing_color = value
    },
    setPrimaryColor(state, value) {
        state.item.primary_color = value
    },
    setThemeHeaderColor(state, value) {
        state.item.theme_header_color = value
    },
    setThemeFooterColor(state, value) {
        state.item.theme_footer_color = value
    },
    setThemeBackgroundColor(state, value) {
        state.item.theme_background_color = value
    },
    setThemeBoothBoxColor(state, value) {
        state.item.theme_booth_box_color = value
    },
    setThemeScheduleBoxColor(state, value) {
        state.item.theme_schedule_box_color = value
    },
    //missing in backend
    setLogoBackgroundColor(state, value) {
        state.item.logo_background_color = value
    },
    //missing in backend
    setMenuTextColor(state, value) {
        state.item.menu_text_color = value
    },
    setCounterColor(state, value) {
        state.item.counter_background_color = value
    },
    setCommonColor(state, value) {
        state.item.common_color = value
    },
    setRegisterButtonColor(state, value) {
        state.item.register_button_color = value
    },
    setCountry(state, value) {
        if (typeof (value) === "string") {
            state.item.country = value
        } else {
            state.item.country = value.name
        }
    },
    setCity(state, value) {
        state.item.city = value
    },
    setStartDate(state, value) {
        console.log("Mutation call",state,value);
        state.item.start_date = value
    },
    setEventAnalytics(state, value) {
        state.item.event_analytics = value
    },
    setEndDate(state, value) {
        state.item.end_date = value
    },
    setEventSetups(state, value) {
        state.item.event_setups = value
    },
    setIs_paid(state, value) {
        state.item.is_paid = value
    },
    setIs_restricted(state, value) {
        state.item.is_restricted = value
    },
    setPrimaryTextColor(state, value) {
        state.item.primary_text_color = value
    },
    setFooterTextColor(state, value) {
        state.item.footer_text_color = value
    },
    setCounterTextColor(state, value) {
        state.item.counter_text_color = value
    },
    setCommonTextColor(state, value) {
        state.item.common_text_color = value
    },
    setExternalListing(state, value) {
        state.item.external_listing = value
    },
    //missing in backand
    setIs_sandbox(state, value) {
        state.item.is_sandbox = value
    },
    //missing in backend
    setIs_live(state, value) {
        state.item.is_live = value
    },
    //missing in backend
    setAmount(state, value) {
        state.item.amount = value
    },
    //missing in backend
    setLoginHours(state, value) {
        state.item.login_hours = value
    },
    //missing in backend
    setLoginMessage(state, value) {
        state.item.login_message = value
    },
    setEventDomain(state, value) {
        state.item.event_domain = value
    },
    setEventDomainCustom(state, value) {
        state.event_domain_custom = value
    },
    //missing in backend
    setPromoCode(state, value) {
        state.item.promo_code = value
    },
    //missing in backend
    setPayPalAppId(state, value) {
        state.item.paypal_app_id = value
    },
    //missing in backend
    setPayPalProductionAppId(state, value) {
        state.item.paypal_production_app_id = value
    },
    setAddress(state, value) {
        state.item.address = value
    },
    setEmail(state, value) {
        state.item.email = value
    },
    setPhone(state, value) {
        state.item.Phone = value
    },
    setDescription(state, value) {
        state.item.description = value
    },
    setWebsiteLogo(state, value) {
        state.item.website_logo = value
    },
    setBackgroundPicture(state, value) {
        state.item.background_picture = value
    },
    setUserGuide(state, value) {
        state.item.user_guide = value
    },
    setUTCTime(state, type) {
        if (state.item.time_zone == null) {
            return
        }
       // moment.tz.setDefault(state.item.time_zone);

        if (type == 'start_date') {
            // var usaTime = moment.utc(state.item.start_time).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
           // var localTime = moment(state.item.start_date).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
           // var utcTime = moment(localTime).tz('UTC').format('YYYY-MM-DD HH:mm');
           // var m = moment.tz(state.item.start_date, 'YYYY-MM-DD HH:mm', state.item.time_zone);
            //var utcTime = m.utc().format('YYYY-MM-DD HH:mm');
            //var utcTime = m.utc().format('YYYY-MM-DD HH:mm zz');
            let dateInTimezone = moment.tz(state.item.start_date, state.item.time_zone);

            // Step 2: Convert the moment object to UTC
            let dateInUTC = dateInTimezone.utc();
            console.log("dateInUTCdateInUTC",dateInUTC);

            // Step 3: Save the UTC time and the original timezone
            var utcTime = dateInUTC.format('YYYY-MM-DD HH:mm'+ (dateInUTC.utcOffset() === 0 ? '[Z]' : 'Z'));
            console.log("utcTimeutcTimeutcTime",utcTime);

            console.log("UTC CHANGE****************");
            
        }
        if (type == 'end_date') {
           /* var m = moment.tz(state.item.end_date, 'YYYY-MM-DD HH:mm', state.item.time_zone);
            var utcTime = m.utc().format('YYYY-MM-DD HH:mm');*/

            let dateInTimezone = moment.tz(state.item.end_date, state.item.time_zone);

            // Step 2: Convert the moment object to UTC
            let dateInUTC = dateInTimezone.utc();
            console.log("dateInUTCdateInUTC",dateInUTC);

            // Step 3: Save the UTC time and the original timezone
            var utcTime = dateInUTC.format('YYYY-MM-DD HH:mmZ');
            console.log("utcTimeutcTimeutcTime",utcTime);
       
        }

        if (type == 'start_date') {
            state.item.start_date = utcTime;
        }
        if (type == 'end_date') {
            state.item.end_date = utcTime;
        }

    },
    setLocalTime(state, type) {
        if (type == 'start_date') {
            var value = state.item.start_date;
        }
        if (type == 'end_date') {
            var value = state.item.end_date;
        }

        let date = moment.utc(value).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
        

       // var date = moment.utc(value).tz(state.item.time_zone).format('YYYY-MM-DD HH:mm');
       
        //var formatedDate = moment(String(date)).format('YYYY-MM-DD HH:mm')
        var formatedDate = date;
        
        if (type == 'start_date') {
            state.item.start_date = formatedDate;
        }
        if (type == 'end_date') {
            state.item.end_date = formatedDate;
        }
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setAddVirtualMeeting(state, value) {
        state.item.add_virtual_meeting = value
    },
    setAddBooths(state, value) {
        state.item.add_booths = value
    },
    setAddRoundtableSessions(state, value) {
        state.item.add_roundtable_session = value
    },
    setAddPresentationsLive(state, value) {
        state.item.add_presentations = value
    },
    setAddWebcast(state, value) {
        state.item.add_webcast = value
    },
    setAddPresentationsOnDemand(state, value) {
        state.item.add_presentations_on_demand = value
    },
    setPanelDiscussions(state, value) {
        state.item.add_panel_discussions = value
    },
    setEventGames(state, value) {
        state.item.event_games = value
    },
    setEventQuestions(state, value) {
        state.item.event_questions = value
    },
    setAwsInterpretationStatus(state, value) {
        state.item.is_aws_interpretation_active = value
    },
    setChatInterpretationStatus(state, value) {
        state.item.is_chat_interpretation_active = value
    },
    setInterprefyInterpretationStatus(state, value) {
        state.item.is_interprefy_interpretation_active = value
    },
    setOwners(state, value) {
        console.log("one");
        let objPush = {userId: state.userId, userEmail: state.userEmail, roleId: state.roleId,roleTitle:state.roleTitle};
        if (_.isUndefined(_.find(state.item.owners, { 'userId': state.userId }))) {
             objPush = {userId: state.userId, userEmail: null, roleId: null,roleTitle:null};
             console.log("2");
            state.item.owners.push(objPush);
        }
        let index = _.findIndex(state.item.owners, { 'userId': state.userId });
        console.log("Index",index);
        console.log("three");
        state.item.owners[index]=  {userId: state.userId, userEmail: state.userEmail, roleId: state.roleId,roleTitle:state.roleTitle};
        
         
        //state.item.owners.push(objPush)
       // state.item.owners = value
    },
    resetState(state) {
        state = Object.assign(state, initialState())
    },
    setPresets(state, value) {
        if (_.isUndefined(_.find(state.item.presets, { 'id': value.id }))) {
            let obj = { "id": `${value.id}`, "no_of_booths": null }
            state.item.presets.push(obj);
        }
        let index = _.findIndex(state.item.presets, { 'id': value.id });
        console.log("Index",index);
        state.item.presets[index][value.update_key] = value.update_value;
        console.log(" state.item.presets", state.item.presets);
    },
    unSetPresets(state, value) {
        _.remove(state.item.presets, preset => preset.id === value.id);
    },
    setUserId(state, value) {
       state.userId = value;
    },
    setUserEmail(state, value) {
        state.userEmail = value;
    },
    setRoleId(state, value) {
       state.roleId = value;
    },
    setRoleTitle(state, value) {
       state.roleTitle = value;
    },
    setOwners2(state,value){
        state.item.owners = value;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,

}
