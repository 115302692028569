<template>
<form @submit.prevent="submitForm" novalidate ref="form">
  <OverlayLoading :isLoading="loading" />
    <div class="row">
        <div class="col-md-10 event_detail_ls">
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="col-md-2">
                        <label for="company_name">Company Name *</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" name="company_name" placeholder="Enter Company Name *" :value="item.company_name" @input="updateCompany_name" />
                        <ValidationMessages :element=" v$.item.company_name" />
                    </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="col-md-2">
                        <label for="company_email">Company Email *</label>
                    </div>
                    <div class="col-md-10">
                        <input type="email" class="form-control" name="company_email" placeholder="Enter Company Email *" :value="item.company_email" @input="updateCompany_email" />
                        <ValidationMessages :element=" v$.item.company_email" />
                    </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="col-md-2">
                        <label for="contactus_email">Contact Form Email</label>
                    </div>
                    <div class="col-md-10">
                        <input type="email" class="form-control" name="contactus_email" placeholder="Enter Contact Form Email" :value="item.contactus_email" @input="updateContactus_email" />
                        <ValidationMessages :element=" v$.item.contactus_email" />
                    </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="col-md-2">
                        <label for="company_logo">Company Logo</label>
                    </div>
                    <div class="col-md-10">
                      <DropFiles :file="item.company_logo"
                                 :module_id="this.moduleId"
                                 @onUploaded="updateCompany_logo"
                                 @onRemoved="removeCompany_logo"
                                 @onError="errCb"
                                 accept="image/jpeg"
                                 :module_type="'events'"
                                 :asset_type="'companies'">
                        <template #header>
                          <p>Company Logo picture</p>
                        </template>
                      </DropFiles>
                    </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="col-md-2">
                        <label for="address">Company Phone</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" name="phone" placeholder="Enter Company Phone" :value="item.phone" @input="updatePhone">
                    </div>
                </div>
            </div>
            <div class="event_detail_wrapper">
                <div class="row">
                    <div class="col-md-2">
                        <label for="address">Company Address</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" name="address" placeholder="Enter Company Address" :value="item.address" @input="updateAddress" />
                    </div>
                </div>
            </div>
          <div class="event_detail_wrapper">
            <div class="row">
              <div class="col-md-2">
                <label for="company_description">Company Description</label>
              </div>
              <div class="col-md-10">
                <textarea rows="3" class="form-control" name="company_description"
                          placeholder="Enter Company Description" :value="item.company_description"
                          @input="updateCompany_description"/>
              </div>
            </div>
          </div>
      </div>
      



      <div class="form-group row">
        <div class="offset-md-10 col-md-2">
          <div class="setting_wrapper">
            <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary ">Save</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn btn-secondary" @click="cancelClick">Cancel</button>
          </div>
        </div>
      </div>


    </div>
  <FlashMessage group="company_edit" />
  </form>
</template>

<script>
import { mapGetters, mapActions} from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import _ from 'lodash';
import ObjectID from 'bson-objectid';

export default {
    name: 'CompaniesEditForm',
    components: {ValidationMessages},
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
      return {
        moduleId: ObjectID().toHexString(),
      }
    },
    validations() {
        return {
            item: {
                company_name: {
                    required,
                },
                company_email: {
                    required,
                    email
                },
                contactus_email: {
                    email
                }
            }
        };
    },
    props: {
        company_id: [String, null]
    },
    watch: {
        company_id(value) {
            if (value) {
                this.fetchData(value);
            }
        }
    },
    computed: {
        ...mapGetters('CompaniesSingle', ['item', 'loading']),
    },
    created() {
        this.fetchData(this.company_id);
    },
    beforeUnmount() {
      delete this.$flashMessage.groups.company_edit;
    },
    unmounted() {
        this.resetState()
    },
    methods: {
        ...mapActions('CompaniesSingle', ['fetchData', 'updateData', 'resetState', 'setCompany_name', 'setCompany_email', 'setContactus_email', 'setCompany_logo', 'setPhone', 'setAddress', 'setCompany_description']),
        ...mapActions('CompaniesIndex', {setEditId: "setEditId", fetchAllData :'fetchData'}),
        updateCompany_name(e) {
            this.setCompany_name(e.target.value)
        },
        updatePhone(e) {
            this.setPhone(e.target.value)
        },
        updateCompany_email(e) {
            this.setCompany_email(e.target.value)
        },
        updateContactus_email(e) {
            this.setContactus_email(e.target.value)
        },
        removeCompany_logo(e, id) {
          this.setCompany_logo(null);
        },
      onError() {
        this.$flashMessage.show({
          group: 'company_edit',
          type: 'error',
          title: 'Error',
          text: 'Something went wrong!'
        });
      },
       /* updateCompany_logo(e) {
          this.setCompany_logo(e.data);
        },*/

        updateCompany_logo(info, data) {
            if (!_.has(data.data, 'key')) {
                return;
            }
            const extension = data.data.key.split('.').pop();
            const key = _.get(this.item.company_logo, 'key', null);
            const size = _.get(info, 'file.size', 0);
            this.setCompany_logo({
                old_key: key,
                key: data.data.key,
                temp_key: info.id,
                size: size.toString(),
                extension: extension,
                uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                uploadedAt: '2026.01.01',
            });

        },

        updateAddress(e) {
            this.setAddress(e.target.value)
        },
        updateCompany_description(e) {
            this.setCompany_description(e.target.value)
        },
        gettingErrorMessage(error){
          let html = "Something went wrong";
          if(error){
            let errors = error.response.data.data.error;
            console.log("errors",errors);
            html = "<ul>"
            for(let err of errors){
              html+= "<li>"+err.message+"</li>";
            }
            html+="</ul>";
          }
         
          return html;
        },
        async submitForm() {
            const isFormCorrect = await this.v$.$validate()

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (!isFormCorrect) return

            this.updateData()
                .then(() => {
                    this.resetState();
                    this.setEditId(null);
                    this.$flashMessage.show({
                        group   : 'company', 
                        type    : 'success',
                        title   : 'Success',
                        time    : 1000,
                        html    : '<ul><li>Company has been updated successfully</li></ul>',
                        
                        });
                    this.fetchAllData();

                })

                .catch((error) => {
                    let html = this.gettingErrorMessage(error);
                    this.$flashMessage.show({
                        group   : 'company_edit',
                        type    : 'error',
                        title   : 'Error',
                        text    : html,
                        html    : html
                      });
                  
                })
        },
        cancelClick() {
            this.setEditId(null);
        },
        errCb(e) {
            this.$flashMessage.show({
                    group: 'company_edit',
                    type: 'error',
                    title: 'Failed',
                    text: "Invalid File/Doc format.Please use described valid format"
                  });
        }

    }
}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;

    .event_ls_label {
        font-weight: bold;
    }
}

.setting_wrapper {
    float: right;

    a {
        color: #201e1eba;
        padding: 9px;
        margin-left: 5px;
        background: #c0cdd345;
        border-radius: 4px;
        border: 1px solid #80808054;
    }
}

.form-btn {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;

    &.save-btn {
        background: rgba(210, 206, 201, 0.27);
    }
}
</style>
