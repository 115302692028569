<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-roles">
        <BackButton :to="{ name: 'dashboard.index' }" />
        <h1 class="title text-center">Roles</h1>
        <div>
          <button v-if="can('role_create',checkPermssions)" class="btn btn-outline-primary" @click="createClick">Create a New Role</button>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <OverlayLoading :isLoading="loading" />
      <div class="roles-template container-fluid">
        <SearchableContainer :all="permissions" :search="search" v-slot="props" v-if="can('role_view',checkPermssions)">
          <div class="row ty_code">
            <div class="col-12">
              <div class="permission_searh_wrapper">
                <div class="form-group row pb-4">
                  <label class="col-lg-3 control-label text-lg-end pt-2" for="inputRounded"></label>
                  <div class="col-lg-6">
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" v-model="search">
                      <button type="button" class=" btn btn-primary"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="role_listing">
                <div class="title"></div>
                <div v-for="(role, index) in all" v-bind:key="index" @click="editRole(role)">
                  {{ role.title }}
                </div>
              </div>
              <div class="listing_wrapper">
                <div v-for="(group, index) in getGroupedPermission(props.all)" v-bind:key="index">
                  <div class="role_title">
                    <div class="title">{{ getTitle(index) }}</div>
                    <div v-for="(role, index) in all" v-bind:key="index">
                      <div class="checkbox-custom checkbox-primary">
                      <!--  <input type="checkbox" :id="'role_all_' + index">
                        <label :for="'role_all_' + index"></label>-->
                      </div>
                    </div>
                  </div>
                  <div class="role_inner_content">
                    <div class="permt_row" v-for="(item, item_index) in group" v-bind:key="item_index">
                      <div class="title">{{ item.title }}</div>
                      <div v-for="(role, index) in all" v-bind:key="index">
                        <div class="checkbox-custom checkbox-primary">
                          <input v-if="can('role_edit',checkPermssions)" type="checkbox" :checked="hasRolePermission(role, item)"
                            :id="'role_' + item_index + index" @change="changedPermission($event, role, item)">
                          <label :for="'role_' + item_index + index"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SearchableContainer>
        <FlashMessage group="roles" />
      </div>
    </template>
  </GeneralDashboardLayout>
  <vue-final-modal v-model="isNew" v-if="isNew">
    <RoleCreateForm @onChanged="onCahnged" @closeModal="closeNewRoleModal" />
  </vue-final-modal>
  <vue-final-modal v-model="isEdit" v-if="isEdit">
    <RoleEditForm :role_id="edit_id" @onChanged="onCahnged" @closeModal="closeNewRoleModal"/>
  </vue-final-modal>
</template>

<script>
import GeneralDashboardLayout from '../../layouts/GeneralDashboardLayout';
import BackButton from "@/components/Commons/BackButton";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import RoleCreateForm from "@/components/cruds/Roles/Forms/Create";
import RoleEditForm from "@/components/cruds/Roles/Forms/Edit";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "RoleIndex",
  components: { RoleEditForm, RoleCreateForm, SearchableContainer, BackButton, GeneralDashboardLayout },
  data() {
    return {
      edit_id: null,
      search: null,
      isNew: false,
      isEdit: false
     
    }
  },
   async created() {
    this.verifyPermissions();
      const access_level = localStorage.getItem('access_level');
      if(access_level =='event'){
        this.$router.push({name: 'notFound'});
      }
      console.log("Fetching data...");
      try{
        await this.fetchData();
        await this.fetchAllPermissions();
      }
      catch(error){
        console.error("Error fetching data:", error);
      }
      console.log("Ennd Fetching data...");
    },
   mounted() {
    console.log("Mount call");
    //this.verifyPermissions();
    console.log("end of mount")
  },
  unmounted() {
    this.resetState();
    delete this.$flashMessage.groups.roles;
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", current_role:"role",isCan:"isCan"}),
    ...mapGetters('RolesIndex', ['loading', 'relationships', 'all']),
    ...mapGetters('PermissionsIndex', { permissions: "all" }),
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
    ...mapActions('RolesIndex', ['fetchData', 'resetState']),
    ...mapActions('RolesSingle', { setRole: "setItem", setPermissions: "setPermission", updateRole: "updateData", resetRoleSingleState: "resetState" }),
    ...mapActions('PermissionsIndex', {
      fetchAllPermissions: "fetchData",
    }),
    createClick() {
      this.isNew = true;
    },
    editRole(role) {
      this.isEdit = true;
      this.edit_id = role._id;
    },

    onCahnged() {
      this.isEdit = false;
      this.edit_id = null;
      this.isNew = false;
    },
    getGroupedPermission(permissions) {
      return _.groupBy(permissions, (i) => i.group);
    },
    hasRolePermission(role, permission) {
      return _.some(role.permissions, (i) => i === permission.slug)
    },
    getTitle(title) {
      if (title === undefined) {
        return '';
      }

      return title.replace('_', ' ').toUpperCase();
    },
    changedPermission(e, role, group) {
      this.setRole(role);
      let permissions = _.cloneDeep(role.permissions);

      if (e.target.checked) {
        permissions = _.union(permissions, [group.slug])
      } else {
        permissions = _.remove(permissions, i => i !== group.slug)
      }

      this.setPermissions(permissions);
      this.updateRole()
        .then((resp) => {
          this.$flashMessage.show({
            group   : 'roles',
            type    : 'success',
            title   : 'Permissions',
            html    : '<ul><li>Permissions have been updated successfully.</li></ul>.',
            time    : 1000
          });

          this.resetRoleSingleState();
          this.fetchAllPermissions();
          this.fetchData();
        })
        .catch(() => {
          this.$flashMessage.show({
            group: 'roles',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
          });
        });
    },
    closeNewRoleModal() {
      this.isEdit = false;
      this.edit_id = null;
      this.isNew = false;
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
    
  }
}
</script>

<style scoped lang="scss">
.header-roles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.role_listing {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  transition: all .15s ease-out;
  background: #fff;
  border-left: 4px solid #076bdf;
  padding: 15px 5px;
  font-size: 1.1em;
  min-height: 20px;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 2px;
  box-shadow: -1px 1px 5px 1px #bdbaba;

  div {
    font-weight: bold;
  }

  .title {
    width: 25%;
  }
}

.roles-template {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ty_code {
  .role_inner_content {
    padding: 15px 0px 15px 0px;
    background: #fff;
    border-bottom: 2px solid #076bdf;
    margin-bottom: 10px;
  }

  .permt_row {
    border-bottom: 1px solid #efefef;
    padding: 10px;
    border-left: 4px solid #df8e07;
    margin-bottom: 5px;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    .title {
      width: 25%;
    }
  }

  .role_title {
    background: #98b5c92e;
    padding: 10px;
    font-weight: bold;
    font-size: 1.2em;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    .title {
      width: 25%;
    }
  }

  .checkbox-custom {
    margin-left: 15px;
  }
}
</style>