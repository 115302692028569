<template>
    <div class="snippet general-event-config-snippet">
        <div class="form-group row">
            <div class="col-lg-6">
                <label>Element Type *</label>
                <select class="form-control" @change="updateElementType">
                    <option value="" selected>Select Element Type</option>
                    <option v-for="option in element_typeEnum" :value="option.value" :key="option.value" :selected="(item.element_type == option.value)">
                        {{ option.label }}
                    </option>
                </select>

            </div>
        </div>

    <div class="form-group row">
        <div class="col-lg-6">
            <label>Title</label>
            <div class="kt-input-icon">
                <input type="text" class="form-control" name="title" maxlength="200" placeholder="Enter title" :value="item.title" @input="updateTitle" />
            </div>
    
        </div>
    </div>

    <div class="form-group row">
        <div class="col-lg-6">
            <label>Sorting Order</label>
            <div class="kt-input-icon">
                <input type="number" name="sortingOrder" min="0" class="form-control"  placeholder="Enter Sorting Order" label="label" :value="item.sorting_order" @input="updateSortingOrder" />
            </div>
    
        </div>
    </div>
    <div class="form-group row">
        <div class="col-lg-6">
                <label>Status </label>
                <div class="kt-input-icon">
                    <input-switch name="status" :value="item.status" id="customSwitchesEx" class="custom-control-input" @change="updateStatus" />
                </div>
        </div>
    </div>
                        <div id="groupby" >
                            <div class="form-group row">
                                <div class="col-lg-6" v-if="this.showLink">
                                    <label>Links </label>
                                    <div class="kt-input-icon">
                                     <input type="text" class="form-control" name="link" maxlength="200" placeholder="Enter Link" :value="item.link" @input="updateLink" />
                                    </div>
                                </div>
                                <div class="col-lg-6" v-if="this.showLink">
                                    <label>Caption </label>
                                    <div class="kt-input-icon">
                                     <input type="text" class="form-control" name="caption" maxlength="200" placeholder="Enter Caption" :value="item.caption" @input="updateCaption" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-4" v-if="this.showAsset">
                            <div class="col-lg-12">
                                <DropFiles
                                    :file="item.image"
                                    dimmention="1300X550"
                                    :module_id="this.moduleId"
                                    @onUploaded="updateImage"
                                    @onRemoved="removeImage"
                                    @onError="logoErrCb"
                                    :accept="'image/jpeg'"
                                    :module_type="'events'"
                                    :asset_type="'event_page_image'">
                                <template #header>
                                    <label>Assets * (Size should be 1300X550 pixel and format JPG.)</label>
                                </template>
                                <template #tooltip>
                                    <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" alt="img"/>
                                    </template>
                                    </VTooltip>
                                </template>
                                </DropFiles>
                            </div>
                            
                        </div>
                        <div class="form-group row mb-4" v-if="this.showVideo">
                            <div class="col-lg-12">
                                <DropFiles :file="item.video" accept="video/mp4" :module_id="this.moduleId" module_type="events" :dimmention="null" @onUploaded="updateVideo" @onRemoved="removeVideo" asset_type="event_page_video"  @onError="VideoErrCb">
                              <template #header>
                                  <label> Video *(Max size 500MB and format mp4.)</label>
                              </template>
                              <template #tooltip>
                                  <VTooltip style="display:inline-block">
                                      <i class="fa fa-info-circle" style="font-size:16px"></i>
                                      <template #popper>
                                          <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                      </template>
                                  </VTooltip>
                              </template>
                          </DropFiles>
                            </div>
                            
                        </div>


                        </div>
                       
                    <div class="form-group row" v-if="this.showText">
                        
                       
                        <div class="col-lg-6">
                            <label>Text  </label>
                            
                            <div class="kt-input-icon">
                                <ckeditor :editor="editor" 
                                id="content"                                            
                                rows="5" 
                                maxlength="3000"   
                                :model-value="item.text"
                                placeholder="Enter Text" 
                                :config="editorConfig"
                                @input="updateText"
                               style="min-height:500px;"></ckeditor>
                            </div>
                          
                       
                        </div>

                    </div>
                </div>
            <div class="card-body text-right mt-3 mr-5">
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
                <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
      
            </div>
    
       
    <FlashMessage group="event_page_section_edit"/>
    
    </template>
    
    <script>
    import InputSwitch from "@/components/Commons/InputSwitch";

    import { gettingErrorMessage } from "@/services/GetValidationMessage";
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import _ from "lodash";
    import moment from 'moment';
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    import ObjectID from 'bson-objectid';
    import { UploadAdapter } from "@/services/CKUploader";
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    const maxVideoFileSize = 524288000; // Max file size

    export default {
 
        data() {
            return {
                showAsset   :false,
                showLink    :false,
                showVideo   :false,
                showText    :true,
                editor      : ClassicEditor,
                editorData  : '',
                fontColor: {
                        colors: [
                            {
                                color: 'hsl(0, 0%, 0%)',
                                label: 'Black'
                            },
                            {
                                color: 'hsl(0, 0%, 30%)',
                                label: 'Dim grey'
                            },
                            {
                                color: 'hsl(0, 0%, 60%)',
                                label: 'Grey'
                            },
                            {
                                color: 'hsl(0, 0%, 90%)',
                                label: 'Light grey'
                            },
                            {
                                color: 'hsl(0, 0%, 100%)',
                                label: 'White',
                                hasBorder: true
                            },
                            // More colors.
                            // ...
                        ]
                    },
                editorConfig: {
                    fontColor: {
                        colors: [
                            {
                                color: 'hsl(0, 0%, 0%)',
                                label: 'Black'
                            },
                            {
                                color: 'hsl(0, 0%, 30%)',
                                label: 'Dim grey'
                            },
                            {
                                color: 'hsl(0, 0%, 60%)',
                                label: 'Grey'
                            },
                            {
                                color: 'hsl(0, 0%, 90%)',
                                label: 'Light grey'
                            },
                            {
                                color: 'hsl(0, 0%, 100%)',
                                label: 'White',
                                hasBorder: true
                            },
                            // More colors.
                            // ...
                        ]
                    },
                    
                    
                    toolbar: ['heading','fontColor', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' | 'fontColor', 'fontBackgroundColor'],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                 //   plugins: [  Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage ],
                   // extraPlugins: [this.uploader],
                   extraPlugins:[function(editor){
                            editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                                return new UploadAdapter( loader ) 
                            }
                        }
                    ],
                    language: 'en'
                },
                moduleId        : ObjectID(),
                dialog          : false,
             
            }
            
        },
 
        setup() {
                return {
                    v$: useVuelidate()
                }
        },
        validations() {
            return {
                item: {
                    element_type: {
                        required
                    }
                   
                }
            };
        },
    
        props: {
            event: {
                type: Object,
                default () {
                    return {
                        name: null,
                        email: null,
                        company: null,
                        role: null,
                        langauge:null
                    }
                }
            },
            pageId:null
        },
        watch: {
        
        },
        name: 'EventEditForm',
        components: {
            ckeditor: CKEditor.component,
            InputSwitch
            
        },
       
        computed: {
            ...mapGetters('EventPageContentSingle', ['item', 'loading','element_typeEnum']),
            ...mapGetters('EventPageContentIndex', []),
        },
        beforeUnmount() {
            this.resetState()
            delete this.$flashMessage.groups.event_page_section_edit;
        },
        created(){
        },
        mounted() {
                this.showAsset = false;
                this.showLink  = false;
                this.showVideo = false;
                let chkValue    = this.item.element_type;
     
                if(chkValue =='ThumbImgLeft' || chkValue =='ThumbImgRight'
                     || chkValue =='Slider' || chkValue =='ThumbVideoLeft' || chkValue == 'ThumbVideoRight' ){
                        this.showAsset = true;
                        this.showLink  = true;
                        this.showVideo = false;
                }
                if(chkValue =='ThumbVideoLeft' || chkValue == 'ThumbVideoRight' ){
                        this.showAsset = false;
                        this.showLink  = true;
                        this.showVideo = true;
                }
        },
        methods: {
            ...mapActions('EventPageContentIndex', {
                fetchAllData: 'fetchData',
            }),
            ...mapActions('EventPageContentSingle', [
                'resetState',
                'updateData',
                'storeData',
                'setElement_type',
                'setSort_order',
                'setText',
                'setTitle',
                'setStatus',
                'setImage',
                'setLink',
                'setVideo',
                'setCaption'

            ]),
            updateElementType(e) {
                this.showAsset = false;
                this.showLink  = false;
                this.showVideo = false;
                this.showText = false;
                let chkValue =e.target.value;
     
                if(chkValue =='ThumbImgLeft' || chkValue =='ThumbImgRight'
                     || chkValue =='Slider' || chkValue =='ThumbVideoLeft' || chkValue == 'ThumbVideoRight' ){
                        this.showAsset = true;
                        this.showLink  = true;
                        this.showVideo = false;
                }
                if(chkValue =='ThumbVideoLeft' || chkValue == 'ThumbVideoRight' ){
                        this.showAsset = false;
                        this.showLink  = true;
                        this.showVideo = true;
                }
                if(chkValue =='TextFullWidht' || chkValue == 'TextBeforeImg' || chkValue =='TextNextToImg' 
                    ||chkValue =='TextBeforeVideo'  || chkValue == "TextNextToVideo" || chkValue =="TextTwoCol"){
                        this.showAsset = false;
                        this.showLink  = false;
                        this.showVideo = false;
                        this.showText   = true;
                }
                this.setElement_type(e.target.value);
            },
            updateTitle(e) {
                this.setTitle(e.target.value);
            },
            updateSortingOrder(e) {
                this.setSort_order(e.target.value);
            },
            updateText(e) {
                this.setText(e);
            },
            updateStatus(e){
                this.setStatus(e.target.checked);
            },
            cancelClick() {
                this.resetState()
            },
            updateLink(e){
                this.setLink(e.target.value);
            },
            logoErrCb() {
                this.$flashMessage.show({
                    group   :   'event_page_section_edit',
                    type    :   'error',
                    title   :   'Failed',
                    time    :   1000,
                    html    :   "<ul><li>Invalid Image format.Please use described valid format and size</li></ul>"
                });
            },
            VideoErrCb() {
                this.$flashMessage.show({
                    group   :   'event_page_section_edit',
                    type    :   'error',
                    title   :   'Failed',
                    time    :   1000,
                    html    :   "<ul><li>Invalid Video format.Please use described valid format and size</li></ul>"
                });
            },
            updateImage(info, data) {
                if (!_.has(data, 'data.key')) {
                    return;
                }

                const extension = data.data.key.split('.').pop();
                const key = _.get(this.item.image, 'key', null);
                const size = _.get(info, 'file.size', 0);

                this.setImage({
                    old_key: data.data.key,
                    key: data.data.key,
                    temp_key: info.id,
                    size: size.toString(),
                    extension: extension,
                    uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                    uploadedAt: '2023.01.01',
                });
            },
            removeImage() {
                this.setImage(null)
            },
            updateVideo(info, data){
               
                let keyExt = data.data.key?data.data.key:data.data.Key;
                const extension = keyExt.split('.').pop();
                const key = _.get(this.item.pillar_left1_logo1, 'key', null);
                const size = _.get(info, 'file.size', 0);
                if(size>maxVideoFileSize){
                    this.VideoErrCb();
                    return;
                }
                this.setVideo({
                    old_key: data.data.key?data.data.key:data.data.Key,
                    key: data.data.key?data.data.key:data.data.Key,
                    temp_key: info.id,
                    size: size.toString(),
                    extension: extension,
                    uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                    uploadedAt: '2023.01.01',
                });
            },
            removeVideo() {
                this.setVideo(null)
            },
            async  submitForm() {
                const isFormCorrect = await this.v$.$validate();
                let generalError = []; 
                _.map(this.v$.$errors, (item) => {
                    if(item.$property == "element_type"){
                        generalError.push("<li>Element Type is required</li>");
                    }
                });
             
                if (!isFormCorrect ) {
                    if (!_.isEmpty(generalError)) {
                        this.$flashMessage.show({
                            group   : 'event_page_section_edit',
                            type    : 'error',
                            time    : 1000,
                            title   : 'Validation Failed',
                            html    : `<ul>${generalError.join("")}</ul>`
                        });
                        return ;
                    }
                }
                if(this.item.element_type == "TextFullWidht" || 
                    this.item.element_type ==  "TextBeforeImg" || 
                    this.item.element_type == "TextNextToImg" || 
                    this.item.element_type == "TextBeforeVideo" || 
                    this.item.element_type == "TextNextToVideo" || 
                    this.item.element_type =="TextTwoCol"){

                        if(this.item.text=="" || this.item.text==null){
                            this.$flashMessage.show({
                            group   : 'event_page_section_edit',
                            type    : 'error',
                            time    : 1000,
                            title   : 'Validation Failed',
                            html    : `<ul><li>Text is required.</li></ul>`
                        });
                        return 
                        }

                    }
                    if( this.item.element_type == "Slider" || 
                        this.item.element_type ==  "ThumbImgLeft" || 
                        this.item.element_type == "ThumbImgRight"){

                        if(this.item.image=="" || this.item.image==null){
                            this.$flashMessage.show({
                            group   : 'event_page_section_edit',
                            type    : 'error',
                            time    : 1000,
                            title   : 'Validation Failed',
                            html    : `<ul><li>Image is required.</li></ul>`
                        });
                        return 
                        }

                    }
                    if( this.item.element_type == "ThumbVideoLeft" || this.item.element_type ==  "ThumbVideoRight"){

                        if(this.item.video=="" || this.item.video==null){
                            this.$flashMessage.show({
                            group   : 'event_page_section_edit',
                            type    : 'error',
                            time    : 1000,
                            title   : 'Validation Failed',
                            html    : `<ul><li>Video is required.</li></ul>`
                        });
                        return 
                        }

                    }
                this.updateData(this.pageId)
                    .then(async (resp) => {
                        this.resetState();
                        this.$flashMessage
                            .show({
                                    group   : 'event_page_content_index',
                                    type    : 'success',
                                    title   : 'Success',
                                    time    : 1000,
                                    html    : '<ul><li>Page Content Section created successfully.</li></ul>',
                                });
                        await  this.fetchAllData(this.pageId);
                    }).catch(error => {
                        console.log("Error",error);
                        let html =  gettingErrorMessage(error);
                        if (error.response.data.data.error) {
                            this.$flashMessage.show({
                                group: 'event_page_section_edit',
                                type: 'error',
                                title: 'Failed',
                                time:1000,
                                text: html || 'Something went wrong',
                                html: html
                            });
                        }
                    });
                }
                
        },

    
    }
    </script>
    
    <style lang="scss" scoped>
    .event_detail_wrapper {
        border-bottom: 0px solid #ccc;
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    }
    
    .setting_wrapper {
        float: right;
    
        a {
            color: #201e1eba;
            padding: 9px;
            margin-left: 5px;
            background: #c0cdd345;
            border-radius: 4px;
            border: 1px solid #80808054;
        }
    }
    
    .toggle label {
        color: #000 !important;
    }
    
    .drop-files {
        margin-top: 10px;
    }
    
    .newspeaker {
        margin-top: 20px;
    }
    
    .newspeaker button {
        width: 100%;
    }
    
    .add_spk_btn {
        background-color: #0088CC;
        color: #fff;
    }
    
    .mini_txt {
        font-size: 0.8em;
        color: #585252;
        font-style: italic;
        margin-bottom: 10px;
    }
    
    .v-dialog>.v-overlay__content>.v-card {
        border-radius: 15px;
        padding-top: 20px;
    }
    
    .tabs-left .nav-tabs>li .active {
        border-left-color: #0088CC;
    }
    
    .orangebtn {
        background-color: orange;
    }
    
    .wrapp {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    .flash-content {
        z-index: 10;
    }
    
    .dark{
      --popper-theme-text-color: #ffffff;
      --popper-theme-padding: 15px;
      --popper-theme-background-color: #333333;
      --popper-theme-border-radius: 6px;
      --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    }
    
    .tooltip_img img{    
        position: absolute;
        top: -53px;
        left: 50px;
        width: 300px;
        height: auto;
    }
    .ck-editor .ck-editor__main .ck-content {
        min-height: 500px!important;
    }
    .ck .ck-content .ck-editor__editable .ck-rounded-corners .ck-editor__editable_inline .ck-blurred{
        min-height: 500px !important;
    }
    
    </style>
    <style scoped> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */
    .ck-editor__editable {
        min-height: 500px;
    }
    span.cke_wrapper cke_ltr,table.cke_editor, td.cke_contents, span.cke_skin_kama, span.cke_wrapper, span.cke_browser_webkit{
        min-height: 500px!important;    
    }
</style>
    