<template>
                      
    <div class="snippet general-event-config-snippet">

        <div class="form-group row">

            <div class="col-lg-6">
                <label>Title *</label>
                <input type="text" name="title" class="form-control"  placeholder="Enter Ttitle" label="label" :value="item.title" @input="updateTitle" />
                    
            </div>
            <div class="col-lg-6">
                <label>Asset Type *</label>
                <select class="form-control" @change="updatePageType">
                        <option value="" selected>Select Assets Type</option>
                        <option v-for="option in asset_typeEnum" :value="option.value" :key="option.value" :selected="(item.page_type == option.value)">
                                    {{ option.label }}
                        </option>
                </select>
            </div>
            

        </div>

        <div class="form-group row">

            <div class="col-lg-6">
                <label>Description</label>
                <div class="kt-input-icon">
                    <textarea rows="3" class="form-control" name="description" placeholder="Enter description"  :value="item.description" @input="updateDescription"></textarea>
                </div>
         
            </div>
        </div>

        <div class="form-group row">
            <div class="col-lg-12">

                <div id ="multiple_images" v-show="item.asset_type == 'image' || item.asset_type == 'image_gallary'">
                    <input
                        placeholder="Upload Multiple Images"
                        type="file"
                        class="form-control"
                        @change="updateImages"
                        multiple="multiple"
                        accept="image/jpeg"
                      >
                </div>

                <div id ="multiple_video" v-show="item.asset_type == 'video' || item.asset_type == 'video_gallary'">
                    <input
                        placeholder ="Upload Multiple Videos"
                        type="file"
                        class="form-control"
                        @change="updateImages"
                        multiple="multiple"
                        aaccept="video/mp4,video/x-m4v,video/*"
                      >
                </div>
                
                <div id ="multiple_document" v-show="item.asset_type == 'document'">
                    <input
                        placeholder ="Upload Multiple Documents"
                        type="file"
                        class="form-control"
                        @change="updateImages"
                        multiple="multiple"
                        accept="application/pdf"
                      >
                </div>
                
            </div>
                
                <div class="image-list">
                        <div class="image-item" v-for="file in item.asset_file" v-bind:key="file">
                        <img :src="IMAGE_URL + file.key" alt="Image 1" v-if="item.asset_type == 'image' || item.asset_type == 'image_gallary'">
                        <div class="image-details">
                            <span class="image-name">{{getFileName(file.key)}}</span>
                            <button class="remove-btn" @click="removeAssetfile($event, file.key)">Remove</button>
                        </div>
                        </div>
                </div>







        </div>
    </div>
    
    <div class="form-group row">
        <div class=" text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>

        </div>
    </div>
    
        
    <FlashMessage group="event_assets_create"/>
    
    </template>
    <script>
    import { upload, remove } from "@/services/FileUploader";
    import constants from "@/config/constants";
    import $ from 'jquery';
    import { gettingErrorMessage } from "@/services/GetValidationMessage";
    import {
        mapGetters,
        mapActions
    } from 'vuex';
    import _ from "lodash";
    import moment from 'moment';
    import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
    import {
        useVuelidate
    } from '@vuelidate/core'
    import {
        required,
        email,
    } from '@vuelidate/validators'
    import ObjectID from 'bson-objectid';
    const maxVideoFileSize = 524288000; // Max file size
    const maxPdfFileSize =  32096088;  // Max file size

    export default {
        data() {
            return {
                moduleId: ObjectID(),
                dialog: false,
                translation: [],
                languageKey: '',
                languageTitle: '',
                languageDescription: '',
                error_translation_tab: false,
                error_general_tab: false,
                selectet_asset_type :"",
                IMAGE_URL:constants.IMAGE_URL,
           
          
                
            }
            
        },
       
      setup() {
            return {
                v$: useVuelidate()
            }
        },
        validations() {
            return {
                item: {
                   
                    asset_type: {
                        required
                    },
                    title:{
                        required
                    }
                    
                   
                }
            };
        },
    
        props: {
            event: {
                type: Object,
                default () {
                    return {
                        name: null,
                        email: null,
                        company: null,
                        role: null,
                        langauge:null
                    }
                }
            },
            languages: {
                type: Array,
            }
    
        },
        watch: {
        
        },
        name: 'CreatePageForm',
        components: {
           // DropFilesMultiple
        },
       
        computed: {
            ...mapGetters('EventAssetsSingle', ['asset_typeEnum','error_general_tab','typesEnum','selectedLanguageKey','selectedLanguageTitle','selectedLanguageDescirption','item', 'loading',]),
            ...mapGetters('EventAssetsIndex', []),
        },
        beforeUnmount() {
            this.resetState()
            delete this.$flashMessage.groups.event_assets_create;
        },
        created(){
        },
        mounted() {
            this.setEvent(this.$route.params.event_id);
        },
        methods: {
            ...mapActions('EventAssetsIndex', {
                fetchAllData: 'fetchData',
            }),
            ...mapActions('EventAssetsSingle', [
                'resetState',
                'storeData',
                'setStatus',
                'setTitle',
                'setAssetType',
                'setEvent',
                'setPageType',
                'setLanguage',
                'setAssetFile',
                'removeAssetFile',
                'setDescription',
                'setLoading'
            ]),

            updateTitle(e) {
                this.setTitle(e.target.value);
            },
            updatePageType(e) {  
                this.setAssetType(e.target.value)
             },
            updateStatus(e){
                this.setStatus(e.target.checked);
            },
            updateDescription(e){
                this.setDescription(e.target.value);
            },
            cancelClick() {
                this.resetState()
            },
            async updateImages(e){
                try {
                    const files = e.target.files;
                    console.log("this.item.asset_type",this.item.asset_type)
                    for(let file of files){
                        let file_size = file.size;
                        if(this.item.asset_type=="image" || this.item.asset_type== "image_gallary"){
                            let img_ext = file.name.split('.').pop().toLowerCase();
                            if(['jpg','jpeg'].includes(img_ext) ==false){
                                this.showErr();
                                return;
                                
                            }
                        }
                        if(this.item.asset_type=="video" || this.item.asset_type === "video_gallary"){
                            let img_ext = file.name.split('.').pop().toLowerCase();
                            if(['mp4'].includes(img_ext) ==false){
                                this.showErr();
                                return;
                                
                            }
                            if(file_size>maxVideoFileSize){
                                this.VideoErrCb();
                                return;
                            }
                           
                        }
                        if(this.item.asset_type=="document"){
                            let img_ext = file.name.split('.').pop().toLowerCase();
                            if(['pdf'].includes(img_ext) ==false){
                                this.showErr();
                                return;
                                
                            }
                            if(file_size>maxPdfFileSize){
                                this.DocErrCb();
                                return;
                            }
                        }
                    }
                    const user = JSON.parse(localStorage.getItem('user'));
                    for(let file of files){
                        let file_size = file.size;
                        let id = ObjectID();
                        let resp = await upload(file, id, "events", null, 'event_asset_images');
                        let fileKey = resp.data.data.key?resp.data.data.key:resp.data.data.Key; 
                        let extension = fileKey.split('.').pop();
                        this.setAssetFile({
                            key: fileKey,
                            old_key: fileKey,
                            temp_key: id,
                            size: file_size.toString(),
                            extension: extension,
                            uploadedBy: user.first_name + ' ' + user.last_name,
                            uploadedAt: '2023.01.01',
                        });
                    }
                    
                } catch (e) {
                    console.log("Error in set",e);
                    this.$flashMessage.show({
                    group: 'event_assets_create',
                    type: 'error',
                    title: 'Error',
                    text: 'Invalid File/Doc format.Please use described valid format.'
                    });
                }
            },
            removeAssetfile(e, id) {
                this.$swal({
                    title: "Are you sure ? ",
                    text: "To fully delete the file submit the form.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    confirmButtonColor: "#dd4b39",
                    focusCancel: true,
                    reverseButtons: true
                }).then(result => {
                    if (typeof result.dismiss === "undefined") {
                        remove(id).then(() => {
                        this.removeAssetFile(id);
                        })
                    }
                });
            },
            getFileName(str){
                let stringNew =  str.split("/").pop();
                return stringNew.slice(-25);

            },
            showErr(){
                this.$flashMessage.show({
                                group   : 'event_assets_create',
                                type    : 'error',
                                title   : 'Error',
                                time    : 1000,
                                html    : '<ul><li>Invalid File/Doc format.Please use described valid format.</li></ul>'
                                });
           
            },
            VideoErrCb(){
                this.$flashMessage.show({
                                group   : 'event_assets_create',
                                type    : 'error',
                                title   : 'Fiel Uploading Error',
                                time    : 1000,
                                html    : '<ul><li>Uploaded video size is  not more than 50mb<li></ul>'
                                });
            },
            DocErrCb(){
                this.$flashMessage.show({
                                group   : 'event_assets_create',
                                type    : 'error',
                                title   : 'Fiel Uploading Error',
                                time    : 1000,
                                html    : '<ul><li>Uploaded document size is  not more than 5mb<li></ul>'
                                });
            },
            async  submitForm() {
                const isFormCorrect = await this.v$.$validate();
                let generalError = []; 
                    _.map(this.v$.$errors, (item) => {
                        if(item.$property == "asset_type"){
                            generalError.push("<li>Asset Type is required</li>");
                        }
                        if(item.$property == "title"){
                            generalError.push("<li>Asset Title is required</li>");
                        }
                    });
                    if (!isFormCorrect ) {
                        if (!_.isEmpty(generalError)) {
                            this.$flashMessage.show({
                                group   : 'event_assets_create',
                                type    : 'error',
                                time    : 1000,
                                title   : 'Validation Failed',
                                html    : `<ul>${generalError.join("")}</ul>`
                            });
                            return ;
                        }
                    }
            
                    this.storeData()
                        .then(async (resp) => {
                            this.resetState();
                            this.$flashMessage
                                .show({
                                        group   : 'event_assets_index',
                                        type    : 'success',
                                        title   : 'Success',
                                        time    : 1000,
                                        html    : '<ul><li>Event Assets created successfully.</li></ul>',
                                    });
                            await  this.fetchAllData(this.$route.params.event_id);
                        }) 
                        .catch(error => {
                            console.log("Error",error);
                            let html =  gettingErrorMessage(error);
                            if (error.response.data.data.error) {
                                this.$flashMessage.show({
                                    group: 'event_assets_create',
                                    type: 'error',
                                    title: 'Failed',
                                    time:1000,
                                    text: html || 'Something went wrong',
                                    html: html
                                });
                            }
                        });
            },

            
            
           
        },
    
    }
    </script>
    
    
    <style lang="scss" scoped>
 .media-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.media-item {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.media-item img,
.media-item video {
  width: 100px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.media-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.media-item video {
  display: block;
}

.media-item video:hover {
  outline: 2px solid #ccc;
}

.media-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.remove-btn {
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  background-color: #e74c3c;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.remove-btn:hover {
  background-color: #c0392b;
}
    .event_detail_wrapper {
        border-bottom: 0px solid #ccc;
        padding: 10px 0px;
    
        .event_ls_label {
            font-weight: bold;
        }
    }
    
    .setting_wrapper {
        float: right;
    
        a {
            color: #201e1eba;
            padding: 9px;
            margin-left: 5px;
            background: #c0cdd345;
            border-radius: 4px;
            border: 1px solid #80808054;
        }
    }
    
    .toggle label {
        color: #000 !important;
    }
    
    .drop-files {
        margin-top: 10px;
    }
    
    .newspeaker {
        margin-top: 20px;
    }
    
    .newspeaker button {
        width: 100%;
    }
    
    .add_spk_btn {
        background-color: #0088CC;
        color: #fff;
    }
    
    .mini_txt {
        font-size: 0.8em;
        color: #585252;
        font-style: italic;
        margin-bottom: 10px;
    }
    
    .v-dialog>.v-overlay__content>.v-card {
        border-radius: 15px;
        padding-top: 20px;
    }
    
    .tabs-left .nav-tabs>li .active {
        border-left-color: #0088CC;
    }
    
    .orangebtn {
        background-color: orange;
    }
    
    .wrapp {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
    .flash-content {
        z-index: 10;
    }
    
    .dark{
      --popper-theme-text-color: #ffffff;
      --popper-theme-padding: 15px;
      --popper-theme-background-color: #333333;
      --popper-theme-border-radius: 6px;
      --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    }
    
    .tooltip_img img{    
        position: absolute;
        top: -53px;
        left: 50px;
        width: 300px;
        height: auto;
    }
    
    
    </style>
    