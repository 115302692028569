<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Event Exhibitors</h1>
        <span v-if="can('event_exhibitor_create',checkPermssions)">
          <button class="btn btn-outline-primary" @click="createClick" v-if="is_new === false">Create a New Exhibitor</button>
          <button class="btn btn-outline-primary" @click="cancelClick" v-else>Cancel</button>
        </span>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid exhibitor-container">
        <div class="row">
          <div class="col-lg-12">
            <div id="live-presentations">
              <OverlayLoading :isLoading="loading" />
              <SearchableContainer :all="all" :search="search" v-slot="props" :perPage="this.perPage" v-if="can('event_exhibitor_view',checkPermssions)">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row search_bar">
                      <div class="col-md-2">
                        <div class="input-group w-70">
                          <select class="form-control input-rounded mb-3 pageview" @change="perRowChange">
                            <option value="5">5</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <span class="ml-5 rpg_txt">records per page</span>
                      </div>
                      <div class="col-md-2 offset-md-8 ">
                        <div class="input-group">
                          <input type="text" class="form-control pageview" placeholder="Search" v-model="search">
                          <span class="input-group-text">
                            <i class="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="labelhdwrapper row">
                  <span class="col1">Name</span>
                  <span class="col2">Description</span>
                </div>
                <TogglePrimaryActive v-if="is_new">
                  <template v-slot:default>
                    <EventExhibitorsCreate />
                  </template>
                </TogglePrimaryActive>
                <TogglePrimary v-for="(exhibitor, index) in props.all" v-bind:key="index">

                  <template v-slot:label>
                      <span class="labelwrapper">
                        <span class="col1">{{ getLanguageName(exhibitor) }}</span>
                        <span class="col2" v-html="getLanguageDescription(exhibitor)"></span>
                      </span>
                </template>
                  <template v-slot:default>
                    <EventExhibitorsEdit :exhibitor_id="exhibitor._id" v-if="edit_id === exhibitor._id" />
                    <EventExhibitorsShow :event="exhibitor" :checkPermssions="checkPermssions" v-else />
                  </template>
                </TogglePrimary>
              </SearchableContainer>
            </div>
          </div>
        </div>
        <FlashMessage group="event_exhibitors" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import _ from 'lodash';
import {mapGetters, mapActions} from 'vuex'
import BackButton from "@/components/Commons/BackButton";
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import SearchableContainer from "@/components/Commons/SearchableContainer";
import EventExhibitorsCreate from "@/components/cruds/EventExhibitors/Forms/Create";
import EventExhibitorsShow from "@/components/cruds/EventExhibitors/Forms/Show";
import EventExhibitorsEdit from "@/components/cruds/EventExhibitors/Forms/Edit";
import { isPermits} from "@/services/CheckPermissions";
import TogglePrimaryActive from "@/components/Commons/TogglePrimaryActive";

export default {
  name: "EventExhibitorsIndex",
  components: {
    EventExhibitorsEdit,
    EventExhibitorsShow,
    SearchableContainer,
    TogglePrimary,
    BackButton,
    GeneralDashboardLayout,
    EventExhibitorsCreate,
    TogglePrimaryActive
  },
  data() {
    return {
      search: null,
      perPage: 5,
    }
  },
  computed: {
    ...mapGetters("AuthenticationsIndex",{checkPermssions:"all", role:"role",isCan:"isCan"}),
    ...mapGetters('ExhibitorsIndex', ['loading', 'all', 'edit_id']),
    ...mapGetters('ExhibitorsSingle', ['item', 'is_new', "is_editing"]),
  },
  created() {
    //this.fetchPermissions()
    this.verifyPermissions(this.$route.params.event_id)
    
    this.fetchData(this.$route.params.event_id).catch(() => {
      this.$flashMessage.show({
        group: 'users',
        type: 'error',
        title: 'Error',
        text: 'Something went wrong.'
      });
    })
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.event_exhibitors;
  },
  methods: {
    ...mapActions("AuthenticationsIndex",["fetchPermissions","fetchPermissionsOnLoad","verifyPermissions"]),
    ...mapActions('ExhibitorsIndex', ['fetchData']),
    ...mapActions('ExhibitorsSingle', ['resetState', "setIsNew", "setIsEditing"]),
    createClick() {
      this.setIsNew(true);
      this.setIsEditing(false);
    },
    cancelClick() {
      this.resetState();
    },
    perRowChange(e) {
      this.perPage = Number(e.target.value);
    },
    getLanguageName(exhibitor) {
      const first_lang = _.head(exhibitor.translations);
      return _.get(first_lang, 'name', '');
    },
    getLanguageDescription(exhibitor) {
      const first_lang = _.head(exhibitor.translations);
      return _.truncate(_.get(first_lang, 'description', ''),{length:100,separator:"[...]"});
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>

<style lang="scss" scoped>
.header-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
  }
}

.exhibitor-container {
  .form-control {
    font-family: 'Poppins';
  }

  .labelwrapper {
    width: 100%;
  }

  .labelhdwrapper {
    width: 100%;
    margin-left: 0px;
    border: 1px solid #ccc;
    padding: 10px;
    background: #e1e1e1;
  }

  .labelhdwrapper span {
    font-weight: bold;
  }

  .col1 {
    width: 25%;
    display: inline-flex;
  }

  .col2 {
    width: 25%;
    display: inline-flex;
  }

  .col3 {
    width: 25%;
    display: inline-flex;
  }

  .col4 {
    width: 20%;
    display: inline-flex;
  }

  .search_bar {
    margin-bottom: 20px;
  }

  .pageview {
    font-family: 'Poppins';
    font-size: 1em !important;
  }

  .w-70 {
    width: 30%;
    display: inline-flex;
  }

  .rpg_txt {
    display: inline-block;
    margin-left: 10px;
  }
}

</style>


