<template>
  <div class="row">
    <div class="col-md-2">
      <div class="user_img">
        <img  v-if="event.logo" :src="IMAGE_URL + event.logo.key" alt="pic" />
      </div>
    </div>
    <div class="col-md-10 event_detail_ls">
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Exhibitor Name</div>
          <div class="col-md-10">{{getName()}}</div>
        </div>
      </div>

      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Exhibitor Description</div>
          <div class="col-md-10" v-html="getDescription()"></div>
        </div>
      </div>

    </div>
    
    <div class="text-right mt-3 mr-5">
      <button type="button" v-if="can('event_exhibitor_edit',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="editData(event._id)">Edit</button>
      <button type="button" v-if="can('event_exhibitor_delete',checkPermssions)" class="mb-1 mt-1 me-1 btn btn-lg btn-danger" @click="removeCompany(event._id)">Delete</button>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from "lodash";
import constants from "@/config/constants";
import { isPermits} from "@/services/CheckPermissions";

export default {
  name: "EventExhibitorsShow",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    checkPermssions:null
  },
  methods: {
    ...mapActions('ExhibitorsIndex', ['fetchData', 'setEditId']),
    ...mapActions('ExhibitorsSingle', ['setIsNew', 'destroyData']),

    getName() {
      const first_lang = _.head(this.event.translations);
      return _.get(first_lang, 'name', '');
    },
    getDescription() {
      const first_lang = _.head(this.event.translations);
      return _.get(first_lang, 'description', '');
    },
    editData(id) {
      this.setEditId(id);
      this.setIsNew(false);
    },
    removeCompany(id) {
      
      this.$swal({
        title: "Are you suress?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.destroyData(id)
              .then(() => {
                this.$flashMessage.show({
                        group : 'event_exhibitors',
                        type  : 'success',
                        title : 'Success',
                        time  : 1000,
                        html  : '<ul><li>Event Exhbitor has been deleted successfully.</li></ul>'
                      });
                this.fetchData(this.$route.params.event_id);
              });
        }
      });
    },
    can(permit,permissions){
      return isPermits(permit,permissions);
    }
  }
}
</script>


<style scoped lang="scss">
.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
  .small-picture {
    max-width: 100px;
    width: 100%;
  }
}
</style>