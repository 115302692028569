<template>
  <div class="row">
    <div class="col-md-10 event_detail_ls">
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Preset Type</div>
          <div class="col-md-10">{{getPresetType(booth_presets.preset_type)}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Preset Name</div>
          <div class="col-md-10">{{booth_presets.name}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">No: Of Videos</div>
          <div class="col-md-10">{{booth_presets.no_of_videos}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">No: Of Documents</div>
          <div class="col-md-10">{{booth_presets.no_of_documents}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">No: Of Presentations</div>
          <div class="col-md-10">{{booth_presets.no_of_presentations}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">No: Of Logos</div>
          <div class="col-md-10">{{booth_presets.no_of_logos}}</div>
        </div>
      </div>
      <div class="detail_wrapper">
        <div class="row">
          <div class="event_ls_label col-md-2">Preset Image</div>
          <div class="col-md-10">
            <img :src="IMAGE_URL + booth_presets.preset_image.key" alt="Img" v-if="booth_presets.preset_image" class="small-picture">
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="setting_wrapper">
        <a class="" @click="editData(booth_presets._id)" href="#">
          <i class="fa fa-edit"></i>
        </a>
        <a class="" @click="removeBoothPreset(booth_presets._id)" href="#">
          <i class="fa fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
  
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import constants from "@/config/constants";
import _ from "lodash";

export default {
  name: "ShowItem",
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  
  props: {
    booth_presets: {
      type: Object,
      default() {
        return {
          preset_type: null,
          name: null,
          no_of_videos: 0,
          no_of_documents: 0,
          no_of_presentations: 0,
          no_of_logos: 0,
          preset_image: null,
        }
      }
    }
  },
  computed: {
    ...mapGetters('BoothPresetsSingle', ['item', 'loading', 'is_new','eventTypes']),
  },
  
  methods: {
    ...mapActions('BoothPresetsIndex', ['fetchData', 'setEditId']),
    ...mapActions('BoothPresetsSingle', ['setIsNew', 'destroyData']),
    editData(id) {
      this.setEditId(id);
      this.setIsNew(false);
    },
    getPresetType(type){
      const getSelectedPreset = _.find(this.eventTypes, i => i.id ===type);
      return _.get(getSelectedPreset, 'title', '');

    },
    removeBoothPreset(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#dd4b39",
        focusCancel: true,
        reverseButtons: true
      }).then(result => {

        if (result.isConfirmed) {
          this.$flashMessage.show({
          group: 'booth_presets',
          type: 'success',
          title: 'Success!',
          text: 'Preset has been deleted successfully!',
         
        })
          this.destroyData(id)
              .then(() => {
                this.fetchData();
              });
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.setting_wrapper {
  float: right;

  a {
    color: #201e1eba;
    padding: 9px;
    margin-left: 5px;
    background: #c0cdd345;
    border-radius: 4px;
    border: 1px solid #80808054;
  }
}

.detail_wrapper {
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;

  .event_ls_label {
    font-weight: bold;
  }
  .small-picture {
    max-width: 100px;
    width: 100%;
  }
  
}
</style>