<template>
  <GeneralDashboardLayout>
    <template v-slot:header>
      <div class="header-template">
        <BackButton :to="{ name: 'event_dashboard.index' }" />
        <h1 class="title text-center">Languages</h1>
      </div>
    </template>
    <template v-slot:content>
      <div class="container-fluid user-container">
        <div class="row">
          <div class="col-lg-12">
            <OverlayLoading :isLoading="loading" />
            <TogglePrimary v-for="(language, index) in languages" v-bind:key="index">
              <template v-slot:label>
                <span class="labelwrapper">
                  <span class="col1">{{ language.language }}</span>
                </span>
              </template>
              <template v-slot:default>
                <div class="row">
                  <div class="col-md-10">
<!--                    <img :src="IMAGE_URL + language.language_logo.key" alt="Img" v-if="language.language_logo !== null">-->
                  </div>
                  <div class="col-md-2">
                    <RouterLink :to="{name: 'event_languages.edit', params: {event_id: $route.params.event_id, language_id: language._id}}" class="btn btn-primary">Edit Language Settings</RouterLink>
                  </div>
                </div>
              </template>
            </TogglePrimary>
          </div>
        </div>
        <FlashMessage group="languages_index" />
      </div>
    </template>
  </GeneralDashboardLayout>
</template>

<script>
import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
import BackButton from "@/components/Commons/BackButton";
import {mapGetters, mapActions} from "vuex";
import TogglePrimary from "@/components/Commons/TogglePrimary";
import constants from "@/config/constants";

export default {
  name: "EventLanguagesIndex",
  components: {GeneralDashboardLayout, BackButton, TogglePrimary},
  data() {
    return {
      IMAGE_URL: constants.IMAGE_URL
    }
  },
  created() {
    this.fetchLanguages(this.$route.params.event_id)
        .catch(() => {
          this.$flashMessage.show({
            group: 'languages_index',
            type: 'error',
            title: 'Error',
            text: 'Something went wrong.'
          });
        })
  },
  beforeUnmount() {
    this.resetState();
    delete this.$flashMessage.groups.languages_index;
  },
  computed: {
    ...mapGetters('EventLanguagesIndex', {languages: "all", loading: "loading"}),
  },
  methods: {
    ...mapActions('EventLanguagesIndex', {fetchLanguages: "fetchData", resetState: "resetState"}),
  }
}
</script>

<style lang="scss" scoped>
.header-template {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px;

  .title {
    font-size: 1.6rem;
    margin-left: 40%;
  }
}
</style>