<template>
       
    <div class="snippet general-event-config-snippet">
            <div class="col-lg-6">
                <label>Points Content</label>
                <div class="kt-input-icon">
                    <div class="kt-input-icon">
                    <ckeditor :editor="editor" 
                    id="content"                                            
                    rows="5" 
                    maxlength="3000"   
                    :model-value="allContent.points_contents"
                    placeholder="Enter Point Content" 
                    :config="editorConfig"
                    @input="updatePointsContents"
                   style="min-height:500px;"></ckeditor>
                </div>
              
                          
                </div>
            </div>
            <div class="col-lg-6">
                <label>Leaderboard Content</label>
                
                <div class="kt-input-icon">
                    <ckeditor :editor="editor" 
                    id="content2"                                            
                    rows="5" 
                    maxlength="3000"   
                    :model-value="allContent.leaderboard_contents"
                           
                    placeholder="Enter Leaderboard Content" 
                    :config="editorConfig"
                    @input="updateLeaderboardContent"
                   style="min-height:500px;"></ckeditor>
                </div>
              
           
            </div>
                    <div class="col-lg-6">
                        <label>Reward Image</label>
            
                        <DropFiles :file="allContent.rewards_image" accept="image/jpeg" :module_id="this.moduleId" module_type="events" dimmention="500X500" @onUploaded="updaterewardImage" @onRemoved="removeRewardImage" asset_type="rewards_image"  @onError="rewardImageErrCb">
                            <template #header>
                                <label>Reward Image * (Size should be 500x500 pixel and format jpg.)</label>
                            </template>
                            <template #tooltip>
                                <VTooltip style="display:inline-block">
                                    <i class="fa fa-info-circle" style="font-size:16px"></i>
                                    <template #popper>
                                        <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                    </template>
                                </VTooltip>
                            </template>
                        </DropFiles>
                    </div>
                       
        <div class="text-right mt-3 mr-5">
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-primary" @click="submitForm()">Save</button>
            <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
        </div>
    </div>
    



<FlashMessage group="leaderboard_content"/>

</template>

<script>
import { gettingErrorMessage } from "@/services/GetValidationMessage";
import {
mapGetters,
mapActions
} from 'vuex';
import $ from 'jquery';
import {
    upload
} from "@/services/FileUploader";
import {
    v4 as uuidv4
} from 'uuid';
import _ from "lodash";
import moment from 'moment';
import ValidationMessages from "@/components/Commons/Validations/ValidationMessages";
import {
useVuelidate
} from '@vuelidate/core'
import {
required,
email,
} from '@vuelidate/validators'
import ObjectID from 'bson-objectid';
import { UploadAdapter } from "@/services/CKUploader";
import CKEditor from '@ckeditor/ckeditor5-vue';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {

data() {
return {
    editor      : ClassicEditor,
    editorData  : '',
    editorConfig: {
    
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' ],
        table: {
            toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
       // extraPlugins: [this.uploader],
       extraPlugins:[function(editor){
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    console.log("loader=======>",loader);
                    return new UploadAdapter( loader ) 
                }
            }
        ],
       //extraPlugins:[ (editor)=>{editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
         //   return new UploadAdapter( loader );
        //} }],
        language: 'en',
    },
    moduleId        : ObjectID(),
    dialog          : false,
 
}

},

setup() {
    return {
        v$: useVuelidate()
    }
},
validations() {
return {
   
};
},

props: {
    allContent:null,

},
watch: {

},
name: 'DocSectionCreateForm',
components: {
    ckeditor: CKEditor.component,
    //ValidationMessages
},

computed: {
    ...mapGetters('LeaderboardSingle', ['loading']),
    ...mapGetters('LeaderboardIndex', []),
},
beforeUnmount() {
    this.resetState()
    delete this.$flashMessage.groups.leaderboard_content;
},
created(){
},
mounted() {
},
methods: {
...mapActions('LeaderboardIndex', {
    fetchAllData: 'fetchData',
}),
...mapActions('LeaderboardSingle', [
    'updateContentData',
    'resetState',
    'setPointsContents',
    'setLeaderboardContents',
    'setRewardsImage'
]),
updatePointsContents(e) {
    this.setPointsContents(e);
},
updateLeaderboardContent(e){
    this.setLeaderboardContents(e);
},
updaterewardImage(info, data) {
    const extension = data.data.key.split('.').pop()
    const key = _.get(this.allContent.rewards_image, 'key', null);
    const size = _.get(info, 'file.size', 0)
    this.setRewardsImage({
        old_key: data.data.key,
        temp_key: info.id,
        key: data.data.key,
        size: size.toString(),
        extension: extension,
        uploadedBy:  `${info.user.first_name} ${info.user.last_name}`,
        uploadedAt: '2023.01.01',
    });
    this.$forceUpdate();
},
removeRewardImage(e) {
    this.setRewardsImage(null);
},
rewardImageErrCb(e){
    this.$flashMessage.show({
            group   : 'leaderboard_content',
            type    : 'error',
            title   : 'Failed',
            time    : 1200,
            html    : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
        });
},
cancelClick() {
    this.resetState();
    this.fetchAllData(this.$route.params.event_id);
       
},
async  submitForm() {
    this.updateContentData(this.$route.params.event_id)
        .then(async (resp) => {
            this.$flashMessage
                .show({
                        group   : 'leader_board_index',
                        type    : 'success',
                        title   : 'Success',
                        time    : 1000,
                        html    : '<ul><li> Content has been updated successfully.</li></ul>',
                    });
            await  this.fetchAllData(this.$route.params.event_id);
            this.resetState();
        }) 
        .catch(error => {
            console.log("Error",error);
            let html =  gettingErrorMessage(error);
            if (error.response.data.data.error) {
                this.$flashMessage.show({
                    group: 'leaderboard_content',
                    type: 'error',
                    title: 'Failed',
                    time:1000,
                    text: html || 'Something went wrong',
                    html: html
                });
            }
        });
},





},


}
</script>

<style lang="scss" scoped>
.event_detail_wrapper {
border-bottom: 0px solid #ccc;
padding: 10px 0px;

.event_ls_label {
font-weight: bold;
}
}

.setting_wrapper {
float: right;

a {
color: #201e1eba;
padding: 9px;
margin-left: 5px;
background: #c0cdd345;
border-radius: 4px;
border: 1px solid #80808054;
}
}

.toggle label {
color: #000 !important;
}

.drop-files {
margin-top: 10px;
}

.newspeaker {
margin-top: 20px;
}

.newspeaker button {
width: 100%;
}

.add_spk_btn {
background-color: #0088CC;
color: #fff;
}

.mini_txt {
font-size: 0.8em;
color: #585252;
font-style: italic;
margin-bottom: 10px;
}

.v-dialog>.v-overlay__content>.v-card {
border-radius: 15px;
padding-top: 20px;
}

.tabs-left .nav-tabs>li .active {
border-left-color: #0088CC;
}

.orangebtn {
background-color: orange;
}

.wrapp {
display: block;
text-align: center;
margin-bottom: 10px;
}
.flash-content {
z-index: 10;
}

.dark{
--popper-theme-text-color: #ffffff;
--popper-theme-padding: 15px;
--popper-theme-background-color: #333333;
--popper-theme-border-radius: 6px;
--popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.tooltip_img img{    
position: absolute;
top: -53px;
left: 50px;
width: 300px;
height: auto;
}
.ck-editor .ck-editor__main .ck-content {
min-height: 500px!important;
}
.ck .ck-content .ck-editor__editable .ck-rounded-corners .ck-editor__editable_inline .ck-blurred{
min-height: 500px !important;
}

</style>
<style scoped> /* don't add "scoped"; note that this will also globalize the CSS for all editors in your project */
.ck-editor__editable {
min-height: 5000px;
}
</style>
