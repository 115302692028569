<template>
    <div class="tabs">
    <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
            <a class="nav-link active" :data-bs-target="`#language-settings`" :href="`#language-settings`" data-bs-toggle="tab">Language</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" :data-bs-target="`#translation-settings`" :href="`#translation-settings`" data-bs-toggle="tab">Language Translation</a>
        </li>
     </ul>
    <div class="tab-content">
        <div :id="`language-settings`" class="tab-pane active">

                <div class="roles-form-create">
                <form autocomplete="off" novalidate @submit.prevent="submitForm">
                    <div class="header_edit">
                    
                    </div>
                    <div class="form-group">
                    <div class="col-lg-12">
                        <label for="permission">Language *</label>
                        <input type="text" name="langauge" id="language" class="form-control" @change="updateLangauge" :value="item.language">
                    </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-6">
                            <label for="permission">Language Logo</label>
                            <DropFiles 
                                    :file="validate_file(item.language_logo)" accept="image/jpeg" 
                                    :module_id="this.moduleId" 
                                    module_type="events" 
                                    dimmention="20X15" 
                                    @onUploaded="updateLanguageLogo" 
                                    @onRemoved="removeLanguageLogo"
                                    asset_type="langauge_logo"
                                    @onError="showCbError">
                                        <template #header>
                                            <label>Language Flag (Size should be 20x15 pixel and format JPG.)</label>
                                        </template>
                                        <template #tooltip>
                                            <VTooltip style="display:inline-block">
                                                <i class="fa fa-info-circle" style="font-size:16px"></i>
                                                <template #popper>
                                                    <img src="/admin_assets/img/event_garden_smallbanner_5.png" />
                                                </template>
                                            </VTooltip>
                                        </template>
                                    </DropFiles>
                                
                                </div>
                    </div>
                    <div class="form-group mt-4">
                    <div class="text-right mt-3 mr-5">
                        <button type="submit" class="mb-1 mt-1 me-1 btn btn-lg btn-primary">Save</button>
                        <button type="button" class="mb-1 mt-1 me-1 btn btn-lg btn-secondary" @click="cancelClick">Cancel</button>
                
                    </div>
                    </div>
                </form>
                <FlashMessage group="main_language__edit" />
                </div>

        </div>
        <div :id="`translation-settings`" class="tab-pane">
            <LanguagesTranslateEditForm :item="language" :id="id"/>
        </div>
    </div>
    </div>


  </template>
  
  <script>
  import { gettingErrorMessage } from "@/services/GetValidationMessage";
  import LanguagesTranslateEditForm from "@/components/cruds/Languages/Translate/Edit";

  import GeneralDashboardLayout from "@/components/layouts/GeneralDashboardLayout";
  import BackButton from "@/components/Commons/BackButton";
  import {mapGetters, mapActions} from "vuex";
  import $ from 'jquery';
  import {
      upload
  } from "@/services/FileUploader";
  import {
      v4 as uuidv4
  } from 'uuid';
  import ObjectID from 'bson-objectid';
  import _ from "lodash";
    
    export default {
      name: "EventLanguagesEdit",
      components: {LanguagesTranslateEditForm},
      data(){
          return {
               moduleId: ObjectID()
              }

            
      },
      props: {
        //item: null,
        id: null
    },
      created() {
        this.fetchData(this.id);
      },
      beforeUnmount() {
        this.resetState();
        delete this.$flashMessage.groups.main_language__edit;
      },
      computed: {
        ...mapGetters('LanguagesSingle', {item: "item", loading: "loading"}),
      },
      methods: {
          ...mapActions('LanguagesIndex', {fetchAll:"fetchData"}),
  
        ...mapActions('LanguagesSingle',[
            "resetState",
            'fetchData',
            'setLanguageLogo',
            'setLanguage',
            'updateData'
          ]),
          validate_file(fileInfo) {
              if (_.has(fileInfo, 'key'))
                  return fileInfo;
              return null;
          },
          
          removeLanguageLogo(e) {
              this.setLanguageLogo(null);
          },
          updateLanguageLogo(info, data){
            try{
              this.err_screen_logo = null;
              const extension = data.data.key.split('.').pop()
              const key = _.get(this.item.language_logo, 'key', null);
              const size = _.get(info, 'file.size', 0)
              this.setLanguageLogo({
                  old_key   : key,
                  key       : data.data.key,
                  temp_key  : info.id,
                  size      : size.toString(),
                  extension : extension,
                  uploadedBy: info.user.first_name + ' ' + info.user.last_name,
                  uploadedAt: '2026.01.01',
              });
  
              this.$forceUpdate();
            }catch(ex){
              console.log("ERror",ex);
            }
          },
          showCbError(){
              this.$flashMessage.show({
                  group   : 'main_language__edit',
                  type    : 'error',
                  title   : 'Failed',
                  time    : 1000,
                  html    : "<ul><li>Invalid File/Doc format.Please use described valid format</li></ul>"
                  });
          }, 
          updateLangauge(e) {
              this.setLanguage(e.target.value)
          },
          cancelClick() {
            this.resetState()
        },
        async submitForm() {
            this.updateData(this.id).then(() => {
                this.fetchAll();
                this.$flashMessage.show({
                group : 'mainlanguages_index',
                type  : 'success',
                title : 'Success',
                time  : 1000,
                html  : '<ul><li>Langauge updated successfully.</li></ul>'
                });
            }).catch((error) => {
                let html =  gettingErrorMessage(error);
                this.$flashMessage.show({
                    group   : 'main_language__edit',
                    type    : 'error',
                    title   : 'Error',
                    html    : html
                    });
            })
        },
      }
    }
    </script>
    
    <style lang="scss">
    .header-template {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    
      .title {
        font-size: 1.6rem;
      }
    }
    
    
    .translates-container {
      .v-center {
        display: flex;
        align-self: center;
      }
      /*--------------- Tabs --------------- */
      .tabs-component {
        margin: 0;
      }
    
      .tabs-component-tabs {
        border: solid 1px #ddd;
        border-radius: 6px;
        margin-bottom: 5px;
      }
    
      @media (min-width: 700px) {
        .tabs-component-tabs {
          border: 0;
          align-items: stretch;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 1px;
        }
      }
    
      .tabs-component-tab {
        color: #999;
        font-size: 14px;
        font-weight: 600;
        margin-right: 0;
        list-style: none;
    
        a:hover {
          color: #000;
          text-decoration: none;
        }
    
        &:not(:last-child) {
          border-bottom: dotted 1px #ddd;
        }
    
        &:hover {
          color: #666;
        }
        &.is-active {
          margin-bottom: -2px;
          display:flex;
    
          a:hover{
            color: #fff;
            text-decoration: none;
          }
    
          .tabs-component-tab-a{
            background:#3b8be4;
            color:#fff;
          }
        }
    
        &.is-disabled * {
          color: #cdcdcd;
          cursor: not-allowed !important;
        }
      }
    
      .tabs-component-tab-a {
        align-items: center;
        color: inherit;
        display: flex;
        padding: .75em 1em;
        text-decoration: none;
      }
    
      .tabs-component-panels {
        padding: 2em;
      }
    
      @media (min-width: 700px) {
        .tabs-component-tab {
          background-color: #fff;
          border: solid 1px #ddd;
          border-radius: 3px 3px 0 0;
          margin-right: .5em;
          transform: translateY(2px);
          transition: transform .3s ease;
    
          &.is-active {
            border-bottom: solid 1px #fff;
            z-index: 2;
            transform: translateY(0);
          }
        }
      }
    
      @media (min-width: 700px) {
        .tabs-component-panels {
          background-color: #fff;
          border: solid 1px #ddd;
          border-radius: 0 6px 6px 6px;
          box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        }
      }
    }
    </style>